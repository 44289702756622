import { Component, OnInit, ViewChild } from '@angular/core';
import { EventDialogComponent } from '../event-dialog/event-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Moment } from 'moment';
import { MatCalendar } from '@angular/material/datepicker';
import { NavbarService } from '../services/navbar.service';
import { DashboardService } from '../services/dashboard.service';
import { CustomerServiceService } from '../services/customer-service.service';
import { MortageService } from '../services/mortage.service';
import { RealstateService } from '../services/realstate.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  isLoading = false;

  @ViewChild('calendar') calendar: MatCalendar<Moment>;
  selectedDate: Moment;
  totalCount: any = 0;
  notSureStatus: 'not_sure';
  customerStatus: any = {
    notSure: '',
    yetTobeConfirmed: '',
    partiallyConirmed: '',
  };
  upCommingEvents: any[] = null;
  activeCount: number;
  activeClientCount: any;
  closedCustomerCount: any;
  mortgageTotalCount: any;
  realTotal: any;

  constructor(
    public dialog: MatDialog,
    public nav: NavbarService,
    public dashBoardService: DashboardService,
    private customerService: CustomerServiceService,
    private mortageService: MortageService,
    private realstateService: RealstateService
  ) { }

  ngOnInit(): void {
    this.nav.show();
    this.getNotSureCustomerStatus();
    this.getPartiallyConirmedStatus();
    this.getActiveClientCount();
    this.getYetToConfirmed();
    this.calendarList();
    this.getClosedCustomer();
    this.getMortgageData();
    this.realEstate();
  }

  realEstate(): void {
    const object = {
      page: 1,
      pageLimit: 10,
      keyword: '',
      status: '',
    };
    this.realstateService.list(object).subscribe((response) => {
      this.realTotal = response.data.total;
    });
  }

  getMortgageData(): void {
    const object = {
      page: 1,
      pageLimit: 10,
      keyword: '',
      status: '',
      start_date: '',
      end_date: ''
    };
    this.mortageService.getMortageData(object).subscribe((response) => {
      this.mortgageTotalCount = response.body.data.total;
    });
  }

  getActiveClientCount(): void {
    const params = {
      page: 1,
      pageLimit: 10,
      keyword: '',
      agencyFilter: '',
      customerType: 'customer',
      agency: '',
      start_date: '',
      end_date: ''
    };
    this.customerService.getCustomerByStatus(params).subscribe((normalCustomer) => {
      if (normalCustomer) {
        const vipParams = {
          page: 1,
          pageLimit: 10,
          keyword: '',
          agencyFilter: '',
          customerType: 'vip_customer',
          agency: '',
          start_date: '',
          end_date: ''
        };
        this.customerService.getCustomerByStatus(vipParams).subscribe((vipCustomer) => {
          this.activeClientCount =
            Number(normalCustomer.body.data.data.length) +
            Number(vipCustomer.body.data.data.length);
        });
      }
    });
  }

  calendarList(): void {
    this.isLoading = true;
    this.customerService.getCalendarList().subscribe((data) => {
      if (data.redirect) {
        window.location.href = `${data.redirect}`;
      } else {
        this.upCommingEvents = data.data;
        if (this.upCommingEvents) {
          this.upCommingEvents = this.upCommingEvents.filter((date) => {
            return new Date(date.start.dateTime).getTime() >= Date.now();
          });
          this.upCommingEvents.sort((a, b) => {
            return (
              (new Date(b.start.dateTime) as any) - (new Date(a.start.dateTime) as any)
            );
          });
        }
      }
      this.upCommingEvents.reverse();
      this.isLoading = false;
    });
  }

  getNotSureCustomerStatus(): void {
    this.isLoading = true;
    this.dashBoardService.getLeadsByCustomerStatus().subscribe((data) => {
      this.customerStatus.notSure = data.data.total;
      this.totalCount = this.totalCount + data.data.total;
      this.isLoading = false;
    });
  }

  getPartiallyConirmedStatus(): void {
    this.isLoading = true;
    this.dashBoardService.getPartiallyStatus().subscribe((data) => {
      this.customerStatus.partiallyConirmed = data.data.total;
      this.totalCount = this.totalCount + data.data.total;
      this.isLoading = false;
    });
  }

  getYetToConfirmed(): void {
    this.isLoading = true;
    this.dashBoardService.getYetStatus().subscribe((data) => {
      this.customerStatus.yetTobeConfirmed = data.data.total;
      this.totalCount = this.totalCount + data.data.total;
      this.isLoading = false;
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(EventDialogComponent, {
      width: '34%',
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  getClosedCustomer(): void {
    const param = {
      pageLimit: 10,
      page: 1,
      agency: '',
      description: ''
    };
    this.customerService.getClosedCustomer(param).subscribe((response) => {
      if (response) {
        this.totalCount = this.totalCount + response.data.total;
        this.closedCustomerCount = response.data.total;
      }
    });
  }
}
