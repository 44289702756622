import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AgencyList } from '../models/customer-model';
import { CustomerServiceService } from '../services/customer-service.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { debounceTime } from 'rxjs/operators';
import { FileUploaderComponent } from '../shared/file-uploader/file-uploader.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-closed-customer',
  templateUrl: './closed-customer.component.html',
  styleUrls: ['./closed-customer.component.css']
})
export class ClosedCustomerComponent implements OnInit {
  closedCustomerForm: FormGroup;
  agencyList: AgencyList[] = [
    { value: 'agency_one', viewValue: 'HARRIS & PARTNERS INC' },
    { value: 'agency_two', viewValue: 'KUNJAR SHARMA & ASSOCIATES INC' },
    { value: 'agency_three', viewValue: 'CHANDE & COMPANY INC' }
  ];
  dataSource: any = null;
  displayedColumns: string[] = [
    'surname',
    'email_address',
    'telephone_cell',
    'SignUp_Date',
    'Estate No',
    'Notes',
    'upload',
    'Download_DOC',
    'Action'
  ];
  isLoading = false;
  paginationData: any;
  pageEvent: PageEvent;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    private customerService: CustomerServiceService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.closedCustomerFormBuild();
    this.getClosedCustomer();
    this.filter();
  }

  closedCustomerFormBuild(): void {
    this.closedCustomerForm = this.formBuilder.group({
      page: 1,
      pageLimit: 10,
      agency: '',
      start_date: '',
      end_date: '',
      customerType: 'close_customer',
      keyword: '',
    });
  }

  getClosedCustomer(): void {
    this.isLoading = true;
    const closedCustomer = [];
    this.customerService.getCustomerByStatus(this.closedCustomerForm.value).subscribe((response) => {
      response.body.data.data.forEach(element => {
        closedCustomer.push(element.customer);
      });
      this.paginationData = response.body.data;
      !!closedCustomer ? this.dataSource = closedCustomer : this.dataSource = [];
      this.isLoading = false;
    });
  }

  filter(): void {
    this.closedCustomerForm.valueChanges.pipe(debounceTime(300)).subscribe((response) => {
      this.getClosedCustomer();
    });
  }

  paginationHandler(event: PageEvent): void {
    this.closedCustomerForm.get('page').setValue(event.pageIndex + 1);
    this.closedCustomerForm.get('pageLimit').setValue(event.pageSize);
  }

  pageIndexHandler(): void {
    this.paginator.pageIndex = 0;
    this.closedCustomerForm.get('page').setValue(1);
  }

  downloadPdf(customerId): void {
    const pdf = `${environment.BASE_URL}/customers/pdf/${customerId}`;
    window.location.assign(pdf);
  }

  clearField(controller: string): void {
    this.closedCustomerForm.get(controller).setValue('');
  }

  fileUpload(id: any): void {
    const dialogRef = this.dialog.open(FileUploaderComponent, {
      width: '34%',
      data: {
        customerId: id,
        customer_type: 'closed_customer'
      },
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  downloadFile(id: any): void {
    if (id) {
      window.location.href = `${environment.BASE_URL}/downloaddocument/${id}`;
    }
  }

}
