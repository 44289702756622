import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CustomerServiceService } from '../services/customer-service.service';
import { DashboardService } from '../services/dashboard.service';
import { SnackBarService } from '../services/snack-bar.service';
import { ConfirmationModalComponent } from '../shared/confirmation-modal/confirmation-modal.component';
import { ViewEventModelComponent } from '../view-event-model/view-event-model.component';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.css']
})
export class EventsListComponent implements OnInit, AfterViewInit {

  isShow = true;
  isLoading = false;

  displayedColumns: string[] = [
    'summary',
    'startDate',
    'EndDate',
    'description',
    'Action',
  ];

  dataSource: MatTableDataSource<any>;

  // @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private customerService: CustomerServiceService,
    public dialog: MatDialog,
    private eventService: DashboardService,
    private snackBarService: SnackBarService) { }

  ngOnInit(): void {
    this.calendarList();
  }

  calendarList() {
    this.isLoading = true;
    this.customerService.getCalendarList().subscribe((data) => {
      this.dataSource = new MatTableDataSource(data.data);
      this.isLoading = false;
    })
  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
  }

  openDialog(id) {
    this.eventService.getEventById(id).subscribe((response) => {
      const dialogRef = this.dialog.open(ViewEventModelComponent, {
        width: '34%',
        // height: '68%',
        data: {
          eventData: response,
          eventId: id,
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.calendarList();
        }
      });
    });
  }

  deleteEvent(id) {
    this.dialog.open(ConfirmationModalComponent, {
      data: {
        title: 'Confirm',
        message: 'Are you Sure You want to delete this Event'
      },
    }).afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.eventService.deleteEvent(id).subscribe((response) => {
          if (response) {
            this.snackBarService.openSnackBar(response.message, 'success-class');
            this.calendarList();
          }
        });
      }
    })
  }

}
