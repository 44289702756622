<div style="height: 5px; width: 100%;">
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
</div>
<form class="container" [formGroup]="closedCustomerForm">
    <div>
        <div fxLayout="row wrap" fxLayoutGap="10px">
            <h2 fxFlex class="pageTitle">Closed Customer ({{paginationData?.total}})</h2>
            <mat-form-field fxFlex>
                <mat-label>Agency Filter</mat-label>
                <mat-select formControlName="agency">
                    <mat-option *ngFor="let agency of agencyList" [value]="agency.value">
                        {{agency.viewValue}}
                    </mat-option>
                </mat-select>
                <button matSuffix mat-icon-button aria-label="clear"
                    (click)="clearField('agency');$event.stopPropagation();">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field fxFlex>
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate formControlName="start_date" (focus)="picker.open()"
                        (dateChange)="pageIndexHandler()" placeholder="Start date">
                    <input matEndDate formControlName="end_date" (focus)="picker.open()" placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <button matSuffix mat-icon-button aria-label="clear"
                    (click)="clearField('start_date');clearField('end_date');$event.stopPropagation();">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field fxFlex>
                <mat-icon matSuffix inline="true">search</mat-icon>
                <mat-label>Search</mat-label>
                <input matInput placeholder="Search" formControlName="keyword" #input>
                <button matSuffix mat-icon-button aria-label="clear" (click)="clearField('keyword')">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource">
                <tr mat-header-row class="headerCustom" *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

                <ng-container matColumnDef="surname">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let row" matTooltip="{{row.surname?.length > 12 ? row.surname:'' }}">
                        {{row.surname
                        != null ? row.surname : '' |titlecase}} </td>
                </ng-container>

                <ng-container matColumnDef="email_address">
                    <th mat-header-cell *matHeaderCellDef> Email </th>
                    <td mat-cell *matCellDef="let row"
                        matTooltip="{{row.email_address?.length > 15 ? row.email_address:'' }}">
                        {{row.email_address !=null ? row.email_address :'' |titlecase}} </td>
                </ng-container>

                <ng-container matColumnDef="telephone_cell">
                    <th mat-header-cell *matHeaderCellDef> Phone </th>
                    <td mat-cell *matCellDef="let row"> {{row.telephone_cell != null ? row.telephone_cell:''
                        |titlecase}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="SignUp_Date">
                    <th mat-header-cell *matHeaderCellDef> SignUp Date </th>
                    <td mat-cell *matCellDef="let row"> {{row.signup_date | date}} </td>
                </ng-container>

                <ng-container matColumnDef="Estate No">
                    <th mat-header-cell *matHeaderCellDef> Estate No. </th>
                    <td mat-cell *matCellDef="let row"> {{row.estate_number}} </td>
                </ng-container>

                <ng-container matColumnDef="Notes">
                    <th mat-header-cell *matHeaderCellDef> Note </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-icon class="matNoteIcon" matTooltip="{{row.note}}" matTooltipClass="tooltip"
                            matTooltipPosition="below" aria-hidden="false" aria-label="Example home icon">insert_comment
                        </mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="upload">
                    <th mat-header-cell *matHeaderCellDef>Closed Document</th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-fab color="primary" (click)="fileUpload(row.id)" class="editIconButton">
                            <mat-icon class="matEditIcon" aria-hidden="false" aria-label="Example home icon">attachment
                            </mat-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Download_DOC">
                    <th mat-header-cell *matHeaderCellDef>Download DOC</th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-fab color="primary" (click)="downloadFile(row.id)" class="editIconButton">
                            <mat-icon class="matEditIcon" aria-hidden="false" aria-label="Example home icon">
                                cloud_download</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef> Download PDF </th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-fab color="primary" class="editIconButton" (click)="downloadPdf(row.customer_id)">
                            <mat-icon class="matEditIcon" aria-hidden="false" aria-label="Example home icon">
                                picture_as_pdf</mat-icon>
                        </button>
                    </td>
                </ng-container>

            </table>
            <mat-paginator [length]="paginationData?.total" [pageSize]="paginationData?.data.per_page"
                [pageSizeOptions]="[10,25,100]" (page)="pageEvent = $event; paginationHandler($event)"
                showFirstLastButtons>
            </mat-paginator>
        </div>
    </div>
</form>