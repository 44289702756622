<form [formGroup]="forgotPasswordForm">
    <h2 mat-dialog-title>Request to Change Password</h2>
    <mat-dialog-content>
        <div fxLayout="column">
            <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput type="email" formControlName="email">
            </mat-form-field>
            <mat-form-field *ngIf="!!forgotPasswordForm.value.token">
                <mat-label>New Password</mat-label>
                <input matInput type="password" formControlName="password">
            </mat-form-field>
            <mat-form-field *ngIf="!!forgotPasswordForm.value.token">
                <mat-label>Confirm Password</mat-label>
                <input matInput type="password" formControlName="password_confirmation">
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-button (click)="forgotPasswordForm.value.token ? resetPassword(): requestPasswordChange()">
            {{forgotPasswordForm.value.token ? 'Reset': 'Request'}}
        </button>
    </mat-dialog-actions>
</form>