import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { RealstateService } from '../services/realstate.service';
import { SnackBarService } from '../services/snack-bar.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-realestate',
  templateUrl: './create-realestate.component.html',
  styleUrls: ['./create-realestate.component.css'],
})
export class CreateRealestateComponent implements OnInit {
  createRealForm: FormGroup;
  statusColumn: any[] = [
    { value: 'in_progress', viewValue: 'In Progress' },
    { value: 'pending', viewValue: 'Pending' },
    { value: 'completed', viewValue: 'Completed' },
  ];

  constructor(
    private fb: FormBuilder,
    private realstateService: RealstateService,
    private snackBarService: SnackBarService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.realStateFormBuild();
  }

  realStateFormBuild(): void {
    this.createRealForm = this.fb.group({
      name: '',
      phone: '',
      referral_source: '',
      area: '',
      notes: '',
      my_commission: '',
      closing_date: '',
      update: '',
      status: 'in_progress',
    });
  }

  realPostData(): void {
    const object = {
      name: this.createRealForm.value.name,
      phone: this.createRealForm.value.phone,
      referral_source: this.createRealForm.value.referral_source,
      area: this.createRealForm.value.area,
      my_commission: this.createRealForm.value.my_commission,
      closing_date: this.createRealForm.value.closing_date
        ? new Date(
          moment(this.createRealForm.value.closing_date).format(
            'YYYY-MM-DD[T]HH:mm:ss'
          )
        ).getFullYear() +
        '-' +
        (new Date(
          moment(this.createRealForm.value.closing_date).format(
            'YYYY-MM-DD[T]HH:mm:ss'
          )
        ).getMonth() +
          1) +
        '-' +
        new Date(
          moment(this.createRealForm.value.closing_date).format(
            'YYYY-MM-DD[T]HH:mm:ss'
          )
        ).getDate()
        : '',
      update: this.createRealForm.value.update,
      notes: this.createRealForm.value.notes,
      status: this.createRealForm.value.status,
    };
    this.realstateService.create(object).subscribe((response) => {
      if (response) {
        this.snackBarService.openSnackBar(response.message, 'success-class');
        this.createRealForm.reset();
        this.router.navigate(['/dashboard/real-list']);
      }
    });
  }
}
