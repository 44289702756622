<div style="height: 5px; width: 100%;"><mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar></div>
<div class="container">
    <h2 class="pageTitle">Event List</h2>
<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="summary">
        <th mat-header-cell *matHeaderCellDef> Summary</th>
        <td mat-cell *matCellDef="let element"> {{element.summary |titlecase}} </td>
      </ng-container>
  
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef> Start Time </th>
        <td mat-cell *matCellDef="let element"> {{element.start.dateTime | date:'medium'}} </td>
      </ng-container>
  
      <ng-container matColumnDef="EndDate">
        <th mat-header-cell *matHeaderCellDef> End Time </th>
        <td mat-cell *matCellDef="let element"> {{element.end.dateTime | date:'medium' }} </td>
      </ng-container>
  
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Description </th>
        <td mat-cell *matCellDef="let element"> {{element.description |titlecase}} </td>
      </ng-container>

      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let element">
          <button mat-fab color="primary" class="editIconButton">
            <mat-icon class="matEditIcon" aria-hidden="false" aria-label="Example home icon"
              (click)="openDialog(element.id)">edit</mat-icon>
          </button>&nbsp;
          <button mat-fab color="primary" class="editIconButton">
            <mat-icon class="matEditIcon" aria-hidden="false" (click)="deleteEvent(element.id)" aria-label="Example home icon">delete</mat-icon>
          </button>
        </td>
      </ng-container>
  
      <tr mat-header-row class="headerCustom" *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <!-- <mat-paginator [pageSizeOptions]="[10, 20, 25]" showFirstLastButtons></mat-paginator> -->
  </div>

</div>
  
