import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { environment } from 'src/environments/environment';
import { ClosedCustomerModelComponent } from '../closed-customer-model/closed-customer-model.component';
import { CustomerAddeventModelComponent } from '../customer-addevent-model/customer-addevent-model.component';
import { AgencyList } from '../models/customer-model';
import { CustomerServiceService } from '../services/customer-service.service';
import { SnackBarService } from '../services/snack-bar.service';
import { ConfirmationModalComponent } from '../shared/confirmation-modal/confirmation-modal.component';
import { FileUploaderComponent } from '../shared/file-uploader/file-uploader.component';

@Component({
  selector: 'app-customers-list',
  templateUrl: './customers-list.component.html',
  styleUrls: ['./customers-list.component.css'],
})
export class CustomersListComponent implements OnInit {
  dataSource: any = null;
  agencyList: AgencyList[] = [
    { value: 'agency_one', viewValue: 'HARRIS & PARTNERS INC' },
    { value: 'agency_two', viewValue: 'KUNJAR SHARMA & ASSOCIATES INC' },
    { value: 'agency_three', viewValue: 'CHANDE & COMPANY INC' }
  ];
  displayedColumns: string[] = [
    'surname',
    'email_address',
    'telephone_cell',
    'SignUp_Date',
    'schedule_a_meeting',
    'upload',
    'First_Counselling_Date',
    'Second_Counselling_Date',
    'Notes',
    'Download_DOC',
    'PDF_Dowload',
    'Estate_Number',
    'Closed_Customer',
    'star',
    'Action',
  ];
  isLoading = false;
  paginationData: any;
  pageEvent: PageEvent;
  vipCustomerForm: FormGroup;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    private customerService: CustomerServiceService,
    private route: Router,
    public dialog: MatDialog,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.normalCutomerFormBuild();
    this.getCutomerList();
    this.filter();
  }

  normalCutomerFormBuild(): void {
    this.vipCustomerForm = this.formBuilder.group({
      page: 1,
      pageLimit: 10,
      keyword: '',
      agencyFilter: '',
      customerType: 'vip_customer',
      agency: '',
      start_date: '',
      end_date: ''
    });
  }

  getCutomerList(): void {
    const customerArr = [];
    this.isLoading = true;
    this.customerService.getCustomerByStatus(this.vipCustomerForm.value).subscribe((response) => {
      this.paginationData = response.body.data;
      response.body.data.data.map((customer: { customer: any; }) => {
        customerArr.push(customer.customer);
      });
      if (customerArr) {
        this.dataSource = customerArr;
      }
      this.isLoading = false;
    });
  }

  filter(): void {
    this.vipCustomerForm.valueChanges.pipe(debounceTime(300)).subscribe((response) => {
      this.getCutomerList();
    });
  }

  paginationHandler(event: PageEvent): void {
    this.vipCustomerForm.get('page').setValue(event.pageIndex + 1);
    this.vipCustomerForm.get('pageLimit').setValue(event.pageSize);
  }

  pageIndexHandler(): void {
    this.paginator.pageIndex = 0;
    this.vipCustomerForm.get('page').setValue(1);
  }

  editCustomer(id: any): void {
    this.route.navigate(['view-edit-customer', id]);
  }

  deleteCustomerById(id: number): void {
    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          title: 'Confirm',
          message: 'Are you Sure You want to delete this Customer',
        },
      })
      .afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.customerService.deleteCustomer(id).subscribe((res) => {
            this.snackBarService.openSnackBar(res.message, 'success-class');
            if (res) {
              this.getCutomerList();
            }
          });
        }
      });
  }

  getConfirm(id: any): void {
    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          title: 'Confirm',
          message: 'Are you Sure You want to Confirm ?',
          confirmStatus: true,
        },
      })
      .afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          const object = {
            customerId: id,
            is_complete: 1,
            second_completed_date: '',
          };
          this.customerService.updateConfirmStatus(object).subscribe((res) => {
            this.snackBarService.openSnackBar(res.message, 'success-class');
            if (res) {
              this.getCutomerList();
            }
          });
        }
      });
  }

  getSeconddateConfirm(id: any): void {
    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          title: 'Confirm',
          message: 'Are you Sure You want to Confirm ?',
          confirmStatus: true,
        },
      })
      .afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          const object = {
            customerId: id,
            is_complete: 1,
            second_completed_date: 1,
          };
          this.customerService
            .updateSecondDateConfirmStatus(object)
            .subscribe((res) => {
              this.snackBarService.openSnackBar(res.message, 'success-class');
              if (res) {
                this.getCutomerList();
              }
            });
        }
      });
  }

  openDialog(data: any): void {
    const dialogRef = this.dialog.open(CustomerAddeventModelComponent, {
      width: '34%',
      // height: '68%',
      data: {
        custoMmerData: data,
      },
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  fileUpload(id: any): void {
    const dialogRef = this.dialog.open(FileUploaderComponent, {
      width: '34%',
      data: {
        customerId: id,
      },
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  downloadFile(id: any): void {
    if (id) {
      window.location.href = `${environment.BASE_URL}/downloaddocument/${id}`;
    }
  }

  downloadPdf(customerId: any): void {
    window.location.href = `${environment.BASE_URL}/customers/pdf/${customerId}`;
  }

  closedcustomer(row: any): void {
    console.log(row);
    this.dialog
      .open(ClosedCustomerModelComponent, {
        data: {
          estateNumber: !!row.estate_number,
          title: 'Confirm',
          message: 'Are you Sure You want to Close This Customer',
        },
      })
      .afterClosed()
      .subscribe((estateNumber: number) => {
        const param = {
          estate_number: row.estate_number || estateNumber,
          id: row.id
        };
        this.customerService.closedCustomer(param).subscribe((res) => {
          if (res) {
            this.getCutomerList();
            this.snackBarService.openSnackBar(res.message, 'success-class');
          }
        });
      });
  }

  goToCreate(): void {
    this.route.navigate(['/create-customer']);
  }

  clearField(controller: string): void {
    this.vipCustomerForm.get(controller).setValue('');
  }

  dateFormat(date: string | number | Date): Date {
    if (date !== null) {
      return new Date(date);
    }
  }

  dateUpdate(id: number, event: { target: { value: string | number | Date; }; }): void {
    const updatedData = {
      customer_id: id,
      signup_date: new Date(event.target.value)
    };
    this.customerService.editCustomerDetails(updatedData).subscribe((response) => {
    }, (error) => {
      if (error.status === 200) {
        this.snackBarService.openSnackBar('updated successfully.', 'success-class');
        this.getCutomerList();
      }
    });
  }

  addEstateNumber(customerId, event): void {
    if (customerId && event.target.value) {
      const param = {
        customer_id: customerId,
        estate_number: event.target.value
      };
      this.customerService.editCustomerDetails(param).subscribe((response) => {
        this.snackBarService.openSnackBar(response.message, 'success-class');
        this.getCutomerList();
      }, (error) => {
        console.log(error);
      });
    }
  }

}
