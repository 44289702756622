<div style="height: 5px; width: 100%;"><mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar></div>
<div class="container" *ngIf="dataSource">
  <div fxLayout="row wrap">
    <h2 fxFlex class="pageTitle">Not Sure</h2>
    <mat-form-field fxFlex="25">
      <mat-icon matSuffix>search</mat-icon>
    <mat-label>Search</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
  </mat-form-field>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">
      <tr mat-header-row class="headerCustom" *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" [hidden]="!row.leads_status"></tr>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let row"> {{row.name |titlecase}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let row"> {{row.email}} </td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef> Phone </th>
        <td mat-cell *matCellDef="let row"> {{row.phone}} </td>
      </ng-container>

      <ng-container matColumnDef="signup_date">
        <th mat-header-cell *matHeaderCellDef> Signup Date </th>
        <td mat-cell *matCellDef="let row"> {{row.lead_date | date}} </td>
      </ng-container>

      <ng-container matColumnDef="leads_status">
        <th mat-header-cell *matHeaderCellDef> Leads Status </th>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="row.leads_status=='first_meting'">First Meeting</span>
          <span *ngIf="row.leads_status=='second_meeting'">Second Meeting</span>
          <span *ngIf="row.leads_status=='follow_up'">Follow Up</span>
          <span *ngIf="row.leads_status=='dead_lead'">Dead Lead</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="lead_summary">
        <th mat-header-cell *matHeaderCellDef> Lead Summary </th>
        <td mat-cell *matCellDef="let row">
          <mat-icon class="matNoteIcon" matTooltip="{{row.lead_summary}}" matTooltipClass="tooltip"
            matTooltipPosition="below" aria-hidden="false" aria-label="Example home icon">insert_comment</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="schedule_a_meeting">
        <th mat-header-cell *matHeaderCellDef>Schedule a Meeting</th>
        <td mat-cell *matCellDef="let row">
          <button mat-fab color="primary" class="editIconButton">
            <mat-icon class="matEditIcon" aria-hidden="false" aria-label="Example home icon"
              (click)="openCreateEventDialog(row.name)">today</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let row">
          <button mat-fab color="primary" class="editIconButton" (click)="editLeadStatus(row.id)">
            <mat-icon class="matEditIcon" aria-hidden="false" aria-label="Example home icon">edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="8">No data matching the filter "{{input.value}}"</td>
      </tr>

    </table>
    <mat-paginator [length]="paginationCountData.total" [pageSize]="paginationCountData.data.per_page"
      [pageSizeOptions]="[5,10,25,100]" (page)="pageEvent = $event; onPaginateChange($event)" showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
