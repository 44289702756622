import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { RealstateService } from '../services/realstate.service';
import { SnackBarService } from '../services/snack-bar.service';
import { ConfirmationModalComponent } from '../shared/confirmation-modal/confirmation-modal.component';
import { debounceTime } from 'rxjs/operators';
import { FileclosedConfirmComponent } from '../shared/fileclosed-confirm/fileclosed-confirm.component';

@Component({
  selector: 'app-real-list',
  templateUrl: './real-list.component.html',
  styleUrls: ['./real-list.component.css'],
})
export class RealListComponent implements OnInit {
  statusColumn: any[] = [
    { value: '', viewValue: 'All' },
    { value: 'in_progress', viewValue: 'In Progress' },
    { value: 'pending', viewValue: 'Pending' },
    { value: 'completed', viewValue: 'Completed' },
  ];
  realEstateForm: FormGroup;
  pageEvent: PageEvent;
  isLoading = false;
  displayedColumns: string[] = [
    'name',
    'phone',
    'ReferralSource',
    'area',
    'notes',
    'MyCommission',
    'closingDate',
    'update',
    'Status',
    'close_File',
    'Action',
  ];
  paginationCountData: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource: any = null;
  constructor(
    private realEstateService: RealstateService,
    public dialog: MatDialog,
    private snackBarService: SnackBarService,
    private route: Router,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.realEstateFormBuild();
    this.getRealEstateList();
    this.filters();
  }

  realEstateFormBuild(): void {
    this.realEstateForm = this.formBuilder.group({
      page: 1,
      pageLimit: 10,
      keyword: '',
      status: '',
      start_date: '',
      end_date: ''
    });
  }

  getRealEstateList(): void {
    this.isLoading = true;
    this.realEstateService.list(this.realEstateForm.value).subscribe((response) => {
      this.paginationCountData = response.data;
      this.dataSource = response.data.data;
      this.isLoading = false;
    });
  }

  filters(): void {
    this.realEstateForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe((response) => {
        this.getRealEstateList();
      });
  }

  paginationHandler(event: PageEvent): void {
    this.realEstateForm.get('page').setValue(event.pageIndex + 1);
    this.realEstateForm.get('pageLimit').setValue(event.pageSize);
  }

  pageIndexHandler(): void {
    this.paginator.pageIndex = 0;
    this.realEstateForm.get('page').setValue(1);
  }

  edit(id): void {
    this.route.navigate(['real-estate', id]);
  }

  delete(id): void {
    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          title: 'Confirm',
          message: 'Are you Sure You want to delete this Data',
        },
      })
      .afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.realEstateService.delete(id).subscribe((response) => {
            if (response) {
              this.snackBarService.openSnackBar(
                response.message,
                'error-class'
              );
              this.realEstateFormBuild();
              this.realEstateForm.get('page').setValue(1);
              this.realEstateForm.get('keyword').setValue('');
              this.realEstateForm.get('status').setValue('');
              this.getRealEstateList();
            }
          });
        }
      });
  }

  closedRealEstate(mortageId: number): void {
    this.dialog
      .open(FileclosedConfirmComponent, {
        data: {
          title: 'Confirm',
          message: 'Are you Sure You want to Close This file',
          confirmStatus: true,
        },
      })
      .afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          const colsedPram = {
            id: mortageId
          };
          this.realEstateService.closeRealEstate(colsedPram).subscribe((res) => {
            if (res) {
              this.getRealEstateList();
              this.snackBarService.openSnackBar(res.message, 'success-class');
            }
          });
        }
      });
  }

  goToCreate(): void {
    this.route.navigate(['real-estate']);
  }

  clearField(controller: string): void {
    this.realEstateForm.get(controller).setValue('');
  }

}
