import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NavbarService } from './navbar.service';
import {
  createLeads,
  existingCustomer,
  LeadsParam,
  paginationData,
} from '../models/leads-models';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LeadService {
  constructor(
    private http: HttpClient,
    public nav: NavbarService,
    private router: Router,
    private datePipe: DatePipe
  ) { }

  addLead(leadData: createLeads): Observable<any> {
    const obj = {
      name: leadData.customerName,
      phone: leadData.phoneNumber,
      email: leadData.emailAddress,
      leads_status: leadData.leadStatus,
      lead_reference: leadData.refrence,
      lead_summary: leadData.summary,
      meeting_type: leadData.meetingType,
      make_customer: leadData.makeCustomer,
      reference_customer_id: leadData.reference_customer_id,
      reference_text: leadData.reference_text,
    };
    return this.http.post<createLeads>(
      `${environment.BASE_URL}/lead/createlead`,
      obj
    );
  }

  getLeadsByStatus(param: LeadsParam): Observable<any> {
    return this.queryBuilder({
      lead_status: param.lead_status,
      page: param.page,
      per_page: param.per_page,
      keyword: param.keyword,
      start_date: this.datePipe.transform(param.start_date, 'yyy-MM-dd'),
      end_date: this.datePipe.transform(param.end_date, 'yyy-MM-dd')
    });
  }

  queryBuilder(params?: {}): Observable<HttpResponse<any>> {
    let httpParams = new HttpParams();
    if (params) { Object.keys(params).forEach(key => { httpParams = httpParams.set(key, params[key]); }); }
    return this.http.get<any>(
      `${environment.BASE_URL}/lead/list`,
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Content-Type': 'application.json' }),
        params: httpParams
      }
    );
  }




  getLeadlistByLeadstatus(leadStatusName): Observable<any> {
    return this.http.get<createLeads>(
      `${environment.BASE_URL}/lead/list?lead_status=${leadStatusName.meetingStatusname}&per_page=${10}&end_date=${leadStatusName.end_date}&start_date=${leadStatusName.start_date}`
    );
  }

  paginationCall(paginationData: paginationData): Observable<any> {
    return this.http
      .get<createLeads>(`${environment.BASE_URL}/lead/list?page=${paginationData.page}&per_page=${paginationData.pageLimit}
      &lead_status=${paginationData.meetingStaus}`);
  }

  getLeadByID(id: Number): Observable<any> {
    return this.http.get<createLeads>(
      `${environment.BASE_URL}/lead/readlead/${id}`
    );
  }

  deleteLeadById(id: Number): Observable<any> {
    return this.http.delete(`${environment.BASE_URL}/lead/deletelead/${id}`);
  }

  leadUpdate(obj): Observable<any> {
    let leadStatus = {
      leads_status: obj.leads_status,
      customer_status: obj.customer_status,
      lead_summary: obj.lead_summary,
    };
    return this.http.post<createLeads>(
      `${environment.BASE_URL}/lead/updatelead/${obj.id}`,
      leadStatus
    );
  }

  getAllLeads(): Observable<any> {
    return this.http.get<createLeads>(
      `${environment.BASE_URL}/lead/list?page=1&per_page=10`
    );
  }

  getAllLeadPagination(paginationData): Observable<any> {
    return this.http.get<createLeads>(
      `${environment.BASE_URL}/lead/list?page=${paginationData.page}&per_page=${paginationData.pageLimit}`
    );
  }

  leadsFilter(filterValue: string): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}/lead/list?page=1&keyword=${filterValue}`
    );
  }

  firstmeetingFilter(object): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}/lead/list?lead_status=${object.leadStatus}&per_page=10&keyword=${object.filterValue}`
    );
  }

  getExistingCustomer(): Observable<existingCustomer> {
    return this.http.get<existingCustomer>(
      `${environment.BASE_URL}/customer/existingcustomer`
    );
  }
}
