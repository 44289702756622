import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data, Router } from '@angular/router';
import { EventEmitterService } from '../services/event-emitter.service';
import { LeadService } from '../services/lead.service';
import { SnackBarService } from '../services/snack-bar.service';

@Component({
  selector: 'app-partially-confirmed-model',
  templateUrl: './partially-confirmed-model.component.html',
  styleUrls: ['./partially-confirmed-model.component.css'],
})
export class PartiallyConfirmedModelComponent implements OnInit {
  editedForm: FormGroup;
  editFlag = false;
  makeCustomerClick: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: Data,
    private formBuilder: FormBuilder,
    private leadService: LeadService,
    private snackBarService: SnackBarService,
    private eventEmitterService: EventEmitterService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.editFormBuild();
  }

  editFormBuild() {
    this.editedForm = this.formBuilder.group({
      leadStatus: new FormControl(),
      customerStatus: new FormControl(),
      leadSummay: new FormControl(),
    });
  }

  onClickSave(id) {
    const obj = {
      leads_status:
        this.editedForm.value.leadStatus == null
          ? this.data.customerData.leadData.data.leads_status
          : this.editedForm.value.leadStatus,
      customer_status:
        this.editedForm.value.customerStatus === null
          ? this.data.customerData.leadData.data.customer_status
          : this.editedForm.value.customerStatus,
      lead_summary: this.editedForm.value.leadSummay,
      id: id,
    };
    this.leadService.leadUpdate(obj).subscribe((data) => {
      this.snackBarService.openSnackBar(data.message, 'success-class');
      this.eventEmitterService.filter('Edit Clicked');
      if (data.data.leads_status == 'make_customer') {
        this.route.navigate(['create-customer']);
      }
    });
  }
}
