import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { CustomerAddeventModelComponent } from '../customer-addevent-model/customer-addevent-model.component';
import { PartiallyConfirmedModelComponent } from '../customer-status-model/partially-confirmed-model.component';
import { DashboardService } from '../services/dashboard.service';
import { EventEmitterService } from '../services/event-emitter.service';
import { LeadService } from '../services/lead.service';

@Component({
  selector: 'app-partially-confirmed',
  templateUrl: './partially-confirmed.component.html',
  styleUrls: ['./partially-confirmed.component.css']
})
export class PartiallyConfirmedComponent implements OnInit {

  isLoading = false;
  dataSource: any = null;
  isShow:boolean = true;
  leadId: Number = null;
  displayedColumns: string[] = [
    'name',
    'email',
    'phone',
    'signup_date',
    'leads_status',
    'lead_summary',
    'schedule_a_meeting',
    'Action'
  ];
  pageEvent: PageEvent;
  paginationCountData: any;


  constructor(
    private ds: DashboardService,
    private leadService: LeadService,
    public dialog: MatDialog,
    private eventEmitterService: EventEmitterService
    ) {
      this.eventEmitterService.listen().subscribe((item) => {
        this.getPartiallyConfirmdData();
      });
     }

  ngOnInit(): void {
    this.getPartiallyConfirmdData();
  }

  getPartiallyConfirmdData() {
    this.isLoading = true;
    this.ds.getPartiallyStatus().subscribe((data)=> {
      this.dataSource = []
      this.paginationCountData = data.data;
      this.dataSource = data.data.data;
      this.isLoading = false;
    })
  };

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if(filterValue){
      this.isLoading = true;
      let object = {
        filterValue: filterValue,
        customerStatus: 'partially_confirmed'
      }
      this.ds.getCustomerStatusFilterStatus(object).subscribe((response)=>{
        this.dataSource = response.data.data;
        this.isLoading = false;
      });
    }
    if(filterValue === ''){
      this.getPartiallyConfirmdData();
    }
  }

  onPaginateChange(event: PageEvent){
    const paginationData = {
      page: event.pageIndex + 1,
      pageLimit: event.pageSize,
      CustomerStaus: 'partially_confirmed',
    };
    this.isLoading = true;
    this.ds.getPaginationData(paginationData).subscribe((data)=> {
      this.dataSource = data.data.data;
      this.isLoading = false;
    })
  }

  editLeadStatus(id) {
    this.leadService.getLeadByID(id).subscribe((data) => {
      this.leadId = id
      let editLeadData = {
        ishowFlag: this.isShow = true,
        leadData: data,
      };
      this.openDialog(editLeadData);
    });
  }

  openDialog(data) {
    const dialogRef = this.dialog.open(PartiallyConfirmedModelComponent, {
      width: '60%',
      height: '60%',
      data: {
        customerData: data,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  openCreateEventDialog(data) {
    const dialogRef = this.dialog.open(CustomerAddeventModelComponent, {
      width: '34%',
      // height: '68%',
      data: {
        custoMmerData: data,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

}
