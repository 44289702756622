import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClosedCustomerParam, CustomerInterface } from '../models/customer-model';

@Injectable({
  providedIn: 'root',
})
export class CustomerServiceService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private datePipe: DatePipe
  ) { }

  getCustomerById(customerId): Observable<any> {
    return this.http.get<any>(
      `${environment.BASE_URL}/agency/readagency/${customerId}`
    );
  }

  progressBar(progressBarData): Observable<any> {
    return this.http.post(
      `${environment.BASE_URL}/agency/updatepercentage/${progressBarData.customerId}`,
      progressBarData
    );
  }

  createAgency(agencyData): Observable<any> {
    return this.http.post(`${environment.BASE_URL}/agency/create`, agencyData);
  }

  personalData(object): Observable<any> {
    return this.http.post(
      `${environment.BASE_URL}/customer/createcustomer`,
      object
    );
  }

  monthlyIncomePost(object): Observable<any> {
    return this.http.post(
      `${environment.BASE_URL}/monthly/createmonthly`,
      object
    );
  }

  monthlyNdPost(object): Observable<any> {
    return this.http.post(
      `${environment.BASE_URL}/monthlynd/createmonthlynd`,
      object
    );
  }

  deptPost(object): Observable<any> {
    return this.http.post(
      `${environment.BASE_URL}/generalinfo/creategeneralinfo`,
      object
    );
  }

  getCustomerByStatus(cutomerParams: CustomerInterface): Observable<any> {
    return this.queryBuilder({
      customer_type: cutomerParams.customerType,
      page: cutomerParams.page,
      per_page: cutomerParams.pageLimit,
      keyword: cutomerParams.keyword,
      start_date: this.datePipe.transform(cutomerParams.start_date, 'yyy-MM-dd'),
      end_date: this.datePipe.transform(cutomerParams.end_date, 'yyy-MM-dd'),
      agency: cutomerParams.agency
    }, 'agency');
  }

  getClosedCustomers(cutomerParams: ClosedCustomerParam): Observable<any> {
    return this.queryBuilder({
      customer_type: cutomerParams.customerType,
      page: cutomerParams.page,
      per_page: cutomerParams.pageLimit,
      description: cutomerParams.description,
      start_date: this.datePipe.transform(cutomerParams.start_date, 'yyy-MM-dd'),
      end_date: this.datePipe.transform(cutomerParams.end_date, 'yyy-MM-dd'),
      agency: cutomerParams.agency
    }, 'customer');
  }

  queryBuilder(params?: {}, middleString?: string): Observable<HttpResponse<any>> {
    let httpParams = new HttpParams();
    if (params) { Object.keys(params).forEach(key => { httpParams = httpParams.set(key, params[key]); }); }
    return this.http.get<any>(
      `${environment.BASE_URL}/${middleString}/list`,
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Content-Type': 'application.json' }),
        params: httpParams
      }
    );
  }

  getNormalCustomer(cutomerParams?: CustomerInterface): Observable<any> {
    return this.http.get<any>(
      `${environment.BASE_URL}/agency/list?$end_date=${cutomerParams.end_date}&start_date=${cutomerParams.start_date}&page=${cutomerParams.page}&per_page=${cutomerParams.pageLimit}&customer_type=${cutomerParams.customerType}&keyword=${cutomerParams.keyword}&agency=${cutomerParams.agency}`
    );
  }

  editAgency(object): Observable<any> {
    return this.http.post(
      `${environment.BASE_URL}/agency/update/${object.customer_id}`,
      object
    );
  }

  editCustomerDetails(personalData): Observable<any> {
    return this.http.put(
      `${environment.BASE_URL}/customer/updatecustomer/${personalData.customer_id}`,
      personalData
    );
  }

  editMonthlyIncome(o): Observable<any> {
    return this.http.post(
      `${environment.BASE_URL}/monthly/updatemonthly/${o.customer_id}`,
      o
    );
  }

  editMonthlyNonEx(o): Observable<any> {
    return this.http.post(
      `${environment.BASE_URL}/monthlynd/updatemonthlynd/${o.customer_id}`,
      o
    );
  }

  editDebt(o): Observable<any> {
    return this.http.post(
      `${environment.BASE_URL}/generalinfo/updategeneralinfo/${o.customer_id}`,
      o
    );
  }

  deleteCustomer(id: number): Observable<any> {
    return this.http.delete(
      `${environment.BASE_URL}/agency/deleteagency/${id}`
    );
  }

  getCalendarList(): Observable<any> {
    return this.http.get(`${environment.BASE_URL}/cal`);
  }

  vipCustomerFilter(filterValue: string): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}/agency/list?customer_type=vip_customer&keyword=${filterValue}`
    );
  }

  updateConfirmStatus(object): Observable<any> {
    return this.http.put(
      `${environment.BASE_URL}/customer/updatecustomer/${object.customerId}`,
      object
    );
  }

  updateSecondDateConfirmStatus(object): Observable<any> {
    return this.http.put(
      `${environment.BASE_URL}/customer/updatecustomer/${object.customerId}`,
      object
    );
  }

  uploadFile(o): Observable<any> {
    return this.http.post(
      `${environment.BASE_URL}/customer/uploaddocument`,
      o,
      { reportProgress: true, observe: 'events' }
    );
  }

  generalPost(object): Observable<any> {
    return this.http.post(
      `${environment.BASE_URL}/customer/general/create`,
      object
    );
  }

  getGeneralDataById(customerId): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}/customer/general/read/${customerId}`
    );
  }

  updateGeneral(o): Observable<any> {
    return this.http.put(
      `${environment.BASE_URL}/customer/general/update/${o.customerId}`,
      o
    );
  }

  sendMail(customerId): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}/customers/send-pdf/${customerId}`
    );
  }

  closedCustomer(payLoad): Observable<any> {
    return this.http.post(
      `${environment.BASE_URL}/customer/closecustomer`,
      payLoad
    );
  }

  getClosedCustomer(closedCustomerParam): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}/customer/list?customer_type=close_customer&per_page=${closedCustomerParam.pageLimit}&page=${closedCustomerParam.page}&agency=${closedCustomerParam.agency}&description=${closedCustomerParam.description}`
    );
  }
}
