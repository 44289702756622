import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MortageGetData, MortagePost } from '../models/mortage';

@Injectable({
  providedIn: 'root',
})
export class MortageService {
  constructor(
    private http: HttpClient,
    private datePipe: DatePipe
  ) { }

  mortagePost(mortageData: MortagePost): Observable<any> {
    return this.http.post<MortagePost>(
      `${environment.BASE_URL}/mortgage/create`,
      mortageData
    );
  }

  getMortageData(params): Observable<any> {
    return this.queryBuilder({
      page: params.page,
      per_page: params.pageLimit,
      keyword: params.keyword,
      start_date: this.datePipe.transform(params.start_date, 'yyy-MM-dd'),
      end_date: this.datePipe.transform(params.end_date, 'yyy-MM-dd'),
      status: params.status
    });
  }

  queryBuilder(params?: {}): Observable<HttpResponse<MortageGetData>> {
    let httpParams = new HttpParams();
    if (params) { Object.keys(params).forEach(key => { httpParams = httpParams.set(key, params[key]); }); }
    return this.http.get<MortageGetData>(
      `${environment.BASE_URL}/mortgage/list`,
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Content-Type': 'application.json' }),
        params: httpParams
      }
    );
  }

  deleteMortage(mortageId: number): Observable<any> {
    return this.http.delete(
      `${environment.BASE_URL}/mortgage/delete/${mortageId}`
    );
  }

  getMortageById(mortageId: number): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}/mortgage/readmortgage/${mortageId}`
    );
  }

  editMortageData(object): Observable<any> {
    const values = {
      moragteId: object.moragteId,
      name: object.name,
      property_address: object.property_address,
      agent: object.agent,
      vale: object.vale,
      mtg_amount: object.mtg_amount,
      mine: object.mine,
      amount: object.amount,
      notes: object.notes,
      closed_date:
        object.closed_date === 'NaN-NaN-NaN' ? null : object.closed_date,
      additional_notes: object.additional_notes,
      status: object.status,
    };
    return this.http.put(
      `${environment.BASE_URL}/mortgage/update/${values.moragteId}`,
      values
    );
  }

  getPaginationData(paginationData): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}/mortgage/list?page=${paginationData.page}&per_page=${paginationData.pageLimit}`
    );
  }

  closedMortgage(object): Observable<any> {
    return this.http.post(`${environment.BASE_URL}/mortgage/closemortgage`, object);
  }

  getClosedMortgageList(object): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}/mortgage/list?&record_status=99&end_date=${this.datePipe.transform(object.end_date, 'yyy-MM-dd')}&start_date=${this.datePipe.transform(object.start_date, 'yyy-MM-dd')}&keyword=${object.keyword}&page=${object.page}&per_page=${object.pageLimit}`
    );
  }
}
