import { NavbarService } from './../services/navbar.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import * as moment from 'moment';
import { LeadService } from '../services/lead.service';
import { SnackBarService } from '../services/snack-bar.service';
import { Router } from '@angular/router';
import { DashboardService } from '../services/dashboard.service';
import { CustomerAddeventModelComponent } from '../customer-addevent-model/customer-addevent-model.component';

@Component({
  selector: 'app-create-leads',
  templateUrl: './create-leads.component.html',
  styleUrls: ['./create-leads.component.css'],
})
export class CreateLeadsComponent implements OnInit {
  leadStatus: string;
  seasons: string[] = ['first_meting', 'dead_lead', 'make_customer'];
  isloading = false;

  @ViewChild('picker') picker: any;

  public showSeconds = true;
  public disableMinute = false;
  public hideTime = false;
  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public touchUi = true;
  public enableMeridian = true;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  createLeadForm: FormGroup;

  public refrence: string;
  existingCustomerName: [
    {
      customer_id: number;
      surname: string;
      given_names: string;
      other_names: string;
    }
  ];
  constructor(
    private leadService: LeadService,
    public dialog: MatDialog,
    public nav: NavbarService,
    private fb: FormBuilder,
    private snackBarService: SnackBarService,
    private route: Router,
    private eventService: DashboardService
  ) { }

  ngOnInit(): void {
    this.nav.show();
    this.createLeadFormBuild();
    this.getExistingCustomer();
  }

  createLeadFormBuild(): void {
    this.createLeadForm = this.fb.group({
      customerName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      emailAddress: [''],
      summary: '',
      leadStatus: ['', Validators.required],
      refrence: '',
      meetingType: ['', Validators.required],
      makeCustomer: '',
      reference_text: null,
      createEventSummary: [''],
      description: '',
      start: [''],
      end: [''],
      reference_customer_id: ''
    });
  }

  onClickSubmit(): void {
    this.isloading = true;
    this.leadService.addLead(this.createLeadForm.value).subscribe((res) => {
      this.snackBarService.openSnackBar(res.message, 'success-class');
      if (res.status) {
        this.route.navigate(['leads']);
      }
    }, (error) => {
      this.snackBarService.openSnackBar(error.error.error.name[0], 'error-class');
    });
    // create Event
    const eventObject = {
      summary: this.createLeadForm.value.createEventSummary,
      start: this.createLeadForm.value.start ? new Date(moment(this.createLeadForm.value.start).format('YYYY-MM-DD[T]HH:mm:ss')) : '',
      end: this.createLeadForm.value.end ? new Date(moment(this.createLeadForm.value.end).format('YYYY-MM-DD[T]HH:mm:ss')) : '',
      description: this.createLeadForm.value.description
    };
    this.eventService.createCalanderEvent(eventObject).subscribe((response) => {
      if (response) {
        this.snackBarService.openSnackBar(response.message, 'success-class');
        if (response.status) {
          this.route.navigate(['leads']);
        }
      }
    });
    this.isloading = false;
    this.createLeadForm.reset();
  }

  getExistingCustomer(): void {
    this.leadService.getExistingCustomer().subscribe((response) => {
      this.existingCustomerName = response.data;
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CustomerAddeventModelComponent, {
      width: '34%',
      // height: '68%',
      data: {
        createLead: true,
        custoMmerData: this.createLeadForm.value.customerName,
      },
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }
}
