<!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->
<div class="container" fxLayout fxLayoutAlign="center center" fxLayoutGap="25px" fxLayout.xs="column wrap"
    fxLayoutGap.xs="20px">
    <mat-card fxFlex="60" class="cardPadding">
        <mat-card-content>
            <h2 class="createLeadTitle">Real Estate</h2>
            <form [formGroup]="createRealForm" fxLayoutAlign="stretch" fxLayout="column">
                <mat-form-field class="full-width">
                    <mat-label>Name</mat-label>
                    <input type="text" required formControlName="name" matInput autocomplete="off">
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>Phone</mat-label>
                    <input formControlName="phone" type="tel" matInput autocomplete="off">
                    <mat-icon matSuffix>phone</mat-icon>
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>Referral Source</mat-label>
                    <input formControlName="referral_source" matInput autocomplete="off">
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>Area</mat-label>
                    <input formControlName="area" matInput autocomplete="off">
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>Notes</mat-label>
                    <input formControlName="notes" matInput autocomplete="off">
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>My Commission</mat-label>
                    <input formControlName="my_commission" type="number" matInput autocomplete="off">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Closing Date</mat-label>
                    <input matInput formControlName="closing_date" autocomplete="off" (focus)="picker.open()"
                        [matDatepicker]="picker">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status" requried>
                        <mat-option *ngFor="let statusColumn of statusColumn" [value]="statusColumn.value">
                            {{statusColumn.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="full-width">
                    <mat-label>Update</mat-label>
                    <input formControlName="update" autocomplete="off" matInput>
                </mat-form-field>
                <div>
                    <button mat-flat-button [disabled]="!createRealForm.valid" type="submit" style="color: white;"
                        (click)="realPostData()" color="primary">Submit</button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>