<mat-card-content fxFlex>
    <mat-dialog-content class="mat-typography">
        <h2 class="createLeadTitle">MORTGAGE</h2>
        <form [formGroup]="editMortageForm" fxLayoutAlign="stretch" fxLayout="column">
            <mat-form-field class="full-width">
                <mat-label>Name</mat-label>
                <input type="text" formControlName="name" matInput autocomplete="off">
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>Property Address</mat-label>
                <input formControlName="property_address" matInput>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>Agent</mat-label>
                <input formControlName="agent" matInput>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>P.VALE</mat-label>
                <input formControlName="vale" matInput>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>MTG Amount</mat-label>
                <input formControlName="mtg_amount" type="number" matInput>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>Mine</mat-label>
                <input formControlName="mine" type="number" matInput>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>Amount</mat-label>
                <input formControlName="amount" type="number" matInput>
            </mat-form-field>

            <mat-form-field class="full-width">
                <mat-label>Notes</mat-label>
                <input matInput formControlName="notes">
            </mat-form-field>

            <div *ngFor="let item of mortgageData">
                <mat-form-field *ngIf="item.notes !== null" class="half-width" appearance="outline">
                    <mat-label>Additonal Note</mat-label>
                    <textarea [value]="item.notes" matInput matTextareaAutosize></textarea>
                </mat-form-field>&nbsp;&nbsp;
                <span *ngIf="item.notes !== null" class="fieldLabel inputField-margin">{{item.updated_at | date}},
                    {{item.updated_at | date:'shortTime'}}</span>
            </div>

            <div formArrayName="notesArray"
                *ngFor="let note of editMortageForm.get('notesArray')['controls'] let i = index">
                <mat-form-field class="full-width" [formGroupName]="i">
                    <mat-label>Add Additional Note</mat-label>
                    <input matInput formControlName="notes">
                </mat-form-field>
            </div>

            <mat-form-field fxFlex="25">
                <mat-label>Status</mat-label>
                <mat-select formControlName="status">
                    <mat-option *ngFor="let statusColumn of statusColumn" [value]="statusColumn.value">
                        {{statusColumn.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div fxFlexAlign="end">
                <button mat-raised-button (click)="addNotesRow()">Add Note</button>
            </div>

            <mat-form-field>
                <mat-label>Close Mortgage</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="closed_date" (focus)="picker.open()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button (click)="dialogClose()">Cancel</button>&nbsp;&nbsp;
        <button mat-raised-button type="submit" style="color: white;" (click)="submitData()"
            color="primary">Submit</button>
    </mat-dialog-actions>
</mat-card-content>