import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data } from '@angular/router';
import { DashboardService } from '../services/dashboard.service';
import { SnackBarService } from '../services/snack-bar.service';

@Component({
  selector: 'app-view-event-model',
  templateUrl: './view-event-model.component.html',
  styleUrls: ['./view-event-model.component.css']
})
export class ViewEventModelComponent implements OnInit {

  editEventForm: FormGroup;

  public showSeconds = false;
  public disableMinute = false;
  public hideTime = false;
  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: Data,
    private dialogRef: MatDialogRef<ViewEventModelComponent>,
    private fb: FormBuilder,
    private eventService: DashboardService,
    private snackBarService : SnackBarService
  ) { }

  ngOnInit(): void {
    this.editFormBuild();
  }

  editFormBuild() {
    this.editEventForm = this.fb.group({
      summary: new FormControl(),
      description: new FormControl(),
      start: new FormControl(),
      end: new FormControl()
    });
  }

  saveEvent() {
    let Object = {
      id: this.data.eventId,
      summary: this.editEventForm.value.summary,
      description: this.editEventForm.value.description,
      start: this.editEventForm.value.start,
      end: this.editEventForm.value.end,
    }
    this.eventService.editEventPost(Object).subscribe((response)=>{
      if(response){
        this.snackBarService.openSnackBar(response.status, 'success-class');
        this.onConfirm();
      }
    })
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

}
