import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Data } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-closed-customer-model',
  templateUrl: './closed-customer-model.component.html',
  styleUrls: ['./closed-customer-model.component.css']
})
export class ClosedCustomerModelComponent implements OnInit {

  estateNumber = new FormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: Data,
    private dialogRef: MatDialogRef<ClosedCustomerModelComponent>
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  onCancel(): void {
    this.dialogRef.close('closePop');
  }

  onConfirm(): void {
    this.dialogRef.close(this.estateNumber.value);
  }

}
