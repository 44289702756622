import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NavbarService } from './navbar.service';
import { Router } from '@angular/router';
import { SnackBarService } from './snack-bar.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient,
    public nav: NavbarService,
  ) { }

  registrationCall(data: any): Observable<any> {
    const obj = data;
    return this.http.post(`${environment.BASE_URL}/register`, obj)
  }

  loginCall(data: { email: any; password: any; }): Observable<any> {
    const userData = {
      email: data.email.toLowerCase(),
      password: data.password
    };
    return this.http.post(`${environment.BASE_URL}/login`, userData);
  }

  requestPasswordChange(object: any): Observable<any> {
    return this.http.post(`${environment.BASE_URL}/createpassword`, object);
  }

  resetPassword(object): Observable<any> {
    return this.http.post(`${environment.BASE_URL}/setpassword`, object);
  }

}
