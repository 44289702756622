<div style="height: 5px; width: 100%;">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
</div>
<div class="container" *ngIf="paginationData">
  <h2 class="pageTitle">Reports ({{paginationData.total}})</h2>
  <mat-card class="mat-elevation-z8">
    <div fxLayout="row" fxLayoutGap="20px">
      <div fxFlex="20">
        <mat-form-field style="width: 100%;">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date" (focus)="picker.open()">
            <input matEndDate formControlName="end" (focus)="picker.open()" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <button matSuffix mat-icon-button aria-label="clear" (click)="clearField();$event.stopPropagation();">
            <mat-icon>close</mat-icon>
          </button>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="10">
        <button mat-raised-button color="primary" style="color: white;" (click)="reportFilterByDate()">Search</button>
      </div>
      <div fxFlex="20">
        <mat-form-field style="width: 100%;">
          <mat-label>Search</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
        </mat-form-field>
      </div>
      <div fxFlex="50">
        <mat-paginator [length]="paginationData.total" [pageSize]="paginationData.data.per_page"
          [pageSizeOptions]="[10,25,100]" (page)="pageEvent = $event; onPaginateChange($event)" showFirstLastButtons>
        </mat-paginator>
      </div>
      <div fxFlex xLayoutAlign="end">
        <button (click)="downloadFile()" style="color: white;" mat-raised-button color="primary">Export</button>
      </div>
    </div>
    <div class="table-container">
      <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="clientname" sticky>
          <th mat-header-cell *matHeaderCellDef> Client Name </th>
          <td mat-cell matTooltip="{{element.client_name.length >= 15 ? element.client_name: '' }}"
            *matCellDef="let element"> {{element.client_name}} </td>
        </ng-container>

        <ng-container matColumnDef="emailId">
          <th mat-header-cell *matHeaderCellDef> Email ID </th>
          <td mat-cell matTooltip="{{element.email_id.length >=15 ?element.email_id: ''}}" *matCellDef="let element">
            {{element.email_id}} </td>
        </ng-container>

        <ng-container matColumnDef="phoneNumber">
          <th mat-header-cell *matHeaderCellDef> Phone Number </th>
          <td mat-cell *matCellDef="let element"> {{element.phone_num}} </td>
        </ng-container>

        <ng-container matColumnDef="hm">
          <th mat-header-cell *matHeaderCellDef> H/M </th>
          <td mat-cell *matCellDef="let element"> {{element.h_m}} </td>
        </ng-container>

        <ng-container matColumnDef="cpbk">
          <th mat-header-cell *matHeaderCellDef> CP/BK </th>
          <td mat-cell *matCellDef="let element"> {{element.cp_bk}} </td>
        </ng-container>

        <ng-container matColumnDef="ESTATENUM">
          <th mat-header-cell *matHeaderCellDef> ESTATE NUMBER</th>
          <td mat-cell *matCellDef="let element"> {{element.estate_num}} </td>
        </ng-container>

        <ng-container matColumnDef="REFSOURCE">
          <th mat-header-cell *matHeaderCellDef> Reference Source </th>
          <td mat-cell *matCellDef="let element"> {{element.ref_source}} </td>
        </ng-container>

        <ng-container matColumnDef="firstPaymentDate">
          <th mat-header-cell *matHeaderCellDef> First Payment Date </th>
          <td mat-cell *matCellDef="let element"> {{element.first_payment_date | date}} </td>
        </ng-container>

        <ng-container matColumnDef="cpAmt">
          <th mat-header-cell *matHeaderCellDef> CP Amount </th>
          <td mat-cell *matCellDef="let element"> {{element.cp_amt}} </td>
        </ng-container>

        <ng-container matColumnDef="signUpDate">
          <th mat-header-cell *matHeaderCellDef> SignUp Date </th>
          <td mat-cell *matCellDef="let element"> {{element.signup_date | date}} </td>
        </ng-container>

        <ng-container matColumnDef="firstCounselling">
          <th mat-header-cell *matHeaderCellDef> First Counselling </th>
          <td mat-cell *matCellDef="let element">{{element.first_coung | date}}</td>
        </ng-container>

        <ng-container matColumnDef="completed">
          <th mat-header-cell *matHeaderCellDef> Completed </th>
          <td mat-cell *matCellDef="let element"> {{element.first_coung_completed | date}} </td>
        </ng-container>

        <ng-container matColumnDef="secondCounselling">
          <th mat-header-cell *matHeaderCellDef> Second Counselling </th>
          <td mat-cell *matCellDef="let element">{{element.second_coung | date}}</td>
        </ng-container>

        <ng-container matColumnDef="completedII">
          <th mat-header-cell *matHeaderCellDef> Completed </th>
          <td mat-cell *matCellDef="let element"> {{element.second_coung_completed | date}} </td>
        </ng-container>

        <ng-container matColumnDef="Note">
          <th mat-header-cell *matHeaderCellDef> Note </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon class="matNoteIcon" matTooltip="{{element.note}}" matTooltipClass="tooltip"
              matTooltipPosition="below" aria-hidden="false" aria-label="Example home icon">insert_comment</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="star" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <mat-icon>more_vert</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="Action" stickyEnd>
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let row">
            <button mat-fab color="primary" class="editIconButton">
              <mat-icon class="matEditIcon" (click)="editReport(row.id)" aria-hidden="false"
                aria-label="Example home icon">edit</mat-icon>
            </button>&nbsp;
            <button mat-fab color="primary" class="editIconButton">
              <mat-icon class="matEditIcon" aria-hidden="false" (click)="deleteEvent(row.id)"
                aria-label="Example home icon">delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row class="headerCustom" *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>

      </table>
    </div>
  </mat-card>
</div>