<h2 mat-dialog-title>Shedule a Meeting with {{data.custoMmerData ? data.custoMmerData:''}}</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="createEventForm">
<div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="20px" fxLayoutGap.xs="20px">
    
    <mat-form-field>
        <mat-label>Subject</mat-label>
        <input matInput formControlName="summary" autocomplete="off">
    </mat-form-field>
    <mat-form-field class="full-width">
        <input matInput [ngxMatDatetimePicker]="picker1" (focus)="picker1.open()" formControlName="start"
          placeholder="Start Date & Time">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker1 [showSpinners]="showSpinners" [showSeconds]="showSeconds"
          [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
          [color]="color" [enableMeridian]="enableMeridian" [disableMinute]="disableMinute" [hideTime]="hideTime">
        </ngx-mat-datetime-picker>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput [ngxMatDatetimePicker]="picker" (focus)="picker.open()" formControlName="end"
          placeholder="End Date & Time">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
          [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
          [color]="color" [enableMeridian]="enableMeridian" [disableMinute]="disableMinute" [hideTime]="hideTime">
        </ngx-mat-datetime-picker>
      </mat-form-field>
    <mat-form-field >
        <mat-label>Message</mat-label>
        <textarea formControlName="description" [matAutosizeMinRows]="4" matTextareaAutosize [matAutosizeMaxRows]="5" matInput placeholder="Message..."></textarea>
    </mat-form-field>

</div>
</form>
</mat-dialog-content>
<mat-dialog-actions align="end">
<button mat-button mat-dialog-close>Cancel</button>
<button mat-button [mat-dialog-close]="true" [disabled]="!data.custoMmerData" style="background-color: #03A9F4;" (click)="createEvent()" class="buttonWidth" cdkFocusInitial>Add</button>
</mat-dialog-actions>
