import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CustomerServiceService } from '../services/customer-service.service';
import { SnackBarService } from '../services/snack-bar.service';

@Component({
  selector: 'app-download-model',
  templateUrl: './download-model.component.html',
  styleUrls: ['./download-model.component.css']
})
export class DownloadModelComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: Data,
    private customerService: CustomerServiceService,
    private snackBarService: SnackBarService) { }

  ngOnInit(): void {

  }

  sendViaEmail() {
    this.customerService.sendMail(this.data.customerId).subscribe((response) => {
      this.snackBarService.openSnackBar(response.data, 'success-class');
    });
  }

  goToList(): void {
    window.location.href = (`/dashboard/customer-list`)
  }

  downloadPdf() {
    window.location.href = (`${environment.BASE_URL}/customers/pdf/${this.data.customerId}`);
  }

}
