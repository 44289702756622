import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../Components/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(public snackBar: MatSnackBar) { }

  openSnackBar(message: string, panelClassName: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: message,
      duration: 5000,
      panelClass: panelClassName,
      verticalPosition: 'top'
    });
  }

}
