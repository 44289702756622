import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data } from '@angular/router';
import { CustomerServiceService } from 'src/app/services/customer-service.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css']
})
export class FileUploaderComponent implements OnInit {

  object = new FormData();
  percentDone: number;
  uploadSuccess: boolean;
  disbled: boolean;
  fileName: any;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: Data,
    private customerService: CustomerServiceService,
    private dialogRef: MatDialogRef<FileUploaderComponent>,
    private snackBarService: SnackBarService
  ) { }

  ngOnInit(): void { }

  upload(event: { target: { files: (string | Blob)[]; }; }): void {
    this.object.append('file', event.target.files[0]);
    this.fileName = event.target.files[0];
    this.object.append('customer_id', this.data.customerId);
    this.data.customer_type ? this.object.append('customer_type', this.data.customer_type) : null;
    this.disbled = true;
  }

  onCLickUpload(): void {
    this.customerService.uploadFile(this.object).subscribe((response) => {
      if (response.type === HttpEventType.UploadProgress) {
        this.percentDone = Math.round(100 * response.loaded / response.total);
      } else if (response instanceof HttpResponse) {
        this.uploadSuccess = true;
        this.snackBarService.openSnackBar(response.body.message, 'success-class');
        this.dialogRef.close();
      }
    });
  }

}
