import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }

  getReportData(): Observable<any> {
    return this.http.get(`${environment.BASE_URL}/report/list?page=1&per_page=10`);
  }

  getReportPaginationData(paginationData):Observable<any>{
    return this.http.get(`${environment.BASE_URL}/report/list?page=${paginationData.page}&per_page=${paginationData.pageLimit}`)
  } 

  getReportById(reportId): Observable<any>{
    return this.http.get(`${environment.BASE_URL}/report/readreport/${reportId}`)
  }

  deleteReport(id): Observable<any> {
    return this.http.delete(`${environment.BASE_URL}/report/deletereport/${id}`);
  }

  editReport(object): Observable<any> {
    return this.http.put(`${environment.BASE_URL}/report/update/${object.id}`, object.editedValue);
  }

  getFilterdata(keyWord): Observable<any> {
    return this.http.get(`${environment.BASE_URL}/report/list?keyword=${keyWord}`);
  }

  getReportFilterByDate(reportFilterByDate): Observable<any> {
    return this.http.get(`${environment.BASE_URL}/report/list?start_date=${reportFilterByDate.start}&end_date=${reportFilterByDate.end}`);
  }

}
