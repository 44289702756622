import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MortageService } from '../services/mortage.service';
import { SnackBarService } from '../services/snack-bar.service';
import { Router } from '@angular/router';
import { StatusModel } from '../models/mortage';

@Component({
  selector: 'app-mortage',
  templateUrl: './mortage.component.html',
  styleUrls: ['./mortage.component.css'],
})
export class MortageComponent implements OnInit {
  createMortageForm: FormGroup;
  statusColumn: StatusModel[] = [
    { value: 'in_progress', viewValue: 'In Progress' },
    { value: 'pending', viewValue: 'Pending' },
    { value: 'completed', viewValue: 'Completed' },
  ];

  constructor(
    private fb: FormBuilder,
    private snackBarService: SnackBarService,
    private mortageService: MortageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.mortGageFormBuild();
  }

  mortGageFormBuild(): void {
    this.createMortageForm = this.fb.group({
      name: ['', Validators.required],
      property_address: '',
      agent: '',
      vale: '',
      mtg_amount: '',
      mine: '',
      amount: '',
      notes: '',
      status: 'in_progress',
    });
  }

  moratgePostData(): void {
    this.mortageService.mortagePost(this.createMortageForm.value).subscribe((response) => {
      if (response) {
        this.snackBarService.openSnackBar(response.message, 'success-class');
        this.createMortageForm.reset();
        this.router.navigate(['dashboard/mortage-list']);
      }
    });
  }
}
