<div class="container">
  <div>
    <h2 class="web-title">Insolvency Application</h2>
    <p class="pragraphPadding">
      Please answer to the best of your knowledge. if you have any questions,
      please call us. when you have filled out the information call us to
      arrange a coifidential complimentary interview
    </p>
  </div>
  <progress-bar [progress]="progressBar" [color-degraded]="{'0': '#03a9f4',  '15': '#03a9f4', '25': '#03a9f4'}">
  </progress-bar>
  <mat-horizontal-stepper linear #stepper>

    <!-- Choose Agency -->

    <mat-step>
      <form [formGroup]="createAgencyForm">
        <ng-template matStepLabel>CHOOSE AGENCY</ng-template>
        <mat-card class="createCustomersCard" fxLayout.xs="column" fxLayoutGap="25px">
          <mat-card-header class="mainTitle">CHOOSE AGENCY</mat-card-header>
          <mat-radio-group color="primary" required fxLayoutGap="10px" aria-label="Select an option"
            formControlName="choose_agency">
            <mat-card-content fxLayout.xs="column wrap" fxLayoutGap="40px" fxLayoutGap.xs="10px">
              <mat-card fxFlex="50" class="stepCard">
                <mat-radio-button value="agency_one">HARRIS & PARTNERS INC</mat-radio-button>
              </mat-card>
              <mat-card fxFlex="50" class="stepCard">
                <mat-radio-button value="agency_two">KUNJAR SHARMA & ASSOCIATES INC</mat-radio-button>
              </mat-card>
              <mat-card fxFlex="50" class="stepCard">
                <mat-radio-button value="agency_three">CHANDE & COMPANY INC</mat-radio-button>
              </mat-card>
            </mat-card-content>
          </mat-radio-group>
          <div fxLayout="column" fxLayoutGap="20px">
            <div fxFlex fxLayoutGap="50px">
              <mat-checkbox color="primary" formControlName="agency_extra1">Consumer Proposal</mat-checkbox>
            </div>
            <div fxFlex>
              <mat-checkbox color="primary" formControlName="agency_extra">Bankruptcy</mat-checkbox>
            </div>
          </div>
          <mat-card-actions class="cardAction" align="end">
            <button mat-flat-button color="primary" [disabled]="!createAgencyForm.valid"
              (click)="createAgencyPostCall()" class="buttonWidth" matStepperNext>
              Next
            </button>
          </mat-card-actions>
        </mat-card>
      </form>
    </mat-step>

    <!-- Personal Data -->

    <mat-step>
      <form [formGroup]="personalDatafForm">
        <ng-template matStepLabel>PERSONAL DATA</ng-template>
        <mat-card class="createCustomersCard">
          <mat-card-header class="mainTitle">PERSONAL DATA</mat-card-header>
          <div fxLayout="row" fxLayout.xs="column wrap">
            <div fxLayout="column" fxFlex="50">
              <mat-form-field class="example-full-width">
                <mat-label>Surname</mat-label>
                <input matInput required formControlName="surname" />
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-label>Given Names</mat-label>
                <input matInput formControlName="given_names" />
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-label>Are you known by any other name(s)</mat-label>
                <input matInput type="text" formControlName="other_names" />
              </mat-form-field>
              <mat-form-field color="primary" class="example-full-width">
                <mat-label>SignUp Date</mat-label>
                <input matInput required (focus)="signUpPicker.open()" formControlName="signup_date"
                  [matDatepicker]="signUpPicker" />
                <mat-datepicker-toggle matSuffix [for]="signUpPicker"></mat-datepicker-toggle>
                <mat-datepicker #signUpPicker color="primary"></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-label>Street Address</mat-label>
                <textarea matInput [matAutosizeMinRows]="4" matTextareaAutosize [matAutosizeMaxRows]="4"
                  formControlName="street_address"></textarea>
              </mat-form-field>

              <span class="fieldLabel" style="margin-bottom: 10px;">Gender</span>
              <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
                fxLayout="row" formControlName="customerGender" aria-labelledby="example-radio-group-label"
                class="example-radio-group">
                <mat-radio-button class="example-radio-button" *ngFor="let gender of genders" [value]="gender.key">
                  <span>{{gender.view }}</span>
                </mat-radio-button>
              </mat-radio-group>&nbsp;

              <mat-form-field class="example-full-width">
                <mat-label>Town/City</mat-label>
                <input matInput type="text" formControlName="town_city" />
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-label>Province</mat-label>
                <input matInput formControlName="province" />
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-label>PostalCode</mat-label>
                <input matInput formControlName="postal_code" />
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-label>S.I.N</mat-label>
                <input matInput formControlName="s_i_n" />
              </mat-form-field>
              <mat-form-field color="primary" class="dateForm">
                <mat-label>Date of Birth</mat-label>
                <input matInput formControlName="birth_date" [matDatepicker]="picker2" />
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2 color="primary"></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-label>Notes</mat-label>
                <textarea formControlName="note" [matAutosizeMinRows]="3" matTextareaAutosize [matAutosizeMaxRows]="3"
                  matInput>
                </textarea>
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-label>Number of dependents that rely on you for financial support</mat-label>
                <input matInput autocomplete="off" formControlName="number_of_dependents">
              </mat-form-field>
            </div>

            <div fxLayout="column" fxFlex="50">
              <span class="fieldLabel" style="margin-bottom: 10px;">Customer Type *</span>
              <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
                fxLayout="row" formControlName="customer_type" requried aria-labelledby="example-radio-group-label"
                class="example-radio-group">
                <mat-radio-button class="example-radio-button" *ngFor="let customerType of customer_type"
                  [value]="customerType">
                  <span>{{customerType === 'vip_customer' ? 'VIP Customer':'Customer' }}</span>
                </mat-radio-button>
              </mat-radio-group>

              <mat-form-field class="example-full-width" style="margin-top: 10px;">
                <mat-label>Esate Number</mat-label>
                <input matInput autocomplete="off" type="number" formControlName="estate_number">
              </mat-form-field>

              <span class="fieldLabel" style="margin-bottom: 10px;">Please Check One</span>
              <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
                fxLayout="row" formControlName="please_check_one" aria-labelledby="example-radio-group-label"
                class="example-radio-group">
                <mat-radio-button class="example-radio-button" *ngFor="let pleaseCheckOne of please_check_one"
                  [value]="pleaseCheckOne">
                  <span>{{pleaseCheckOne === 'mr'? 'Mr': ''}}</span>
                  <span>{{pleaseCheckOne === 'ms'? 'Ms': ''}}</span>
                  <span>{{pleaseCheckOne === 'mrs'? 'Mrs': ''}}</span>
                  <span>{{pleaseCheckOne === 'miss'? 'Miss': ''}}</span>
                  <span>{{pleaseCheckOne === 'zero_to_eight'? 'Zero to Eight': ''}}</span>
                  <span>{{pleaseCheckOne === 'some_high_school'? 'Some High School': ''}}</span>
                </mat-radio-button>
              </mat-radio-group>
              <mat-form-field class="example-full-width">
                <mat-label>Telephone: (Home)</mat-label>
                <input matInput type="tel" formControlName="telephone_honre" />
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-label>Telephone: (Cell )</mat-label>
                <input matInput type="tel" required formControlName="telephone_cell" />
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-label>Email Address</mat-label>
                <input matInput required formControlName="email_address" />
              </mat-form-field>
              <span class="fieldLabel" style="margin-bottom: 10px;">Marrital Status</span>
              <mat-radio-group color="primary" fxLayoutGap="20px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
                fxLayout="row" formControlName="marital_status" aria-labelledby="radio-group-label">
                <mat-radio-button class="example-radio-button" *ngFor="let status of marritalStatus"
                  [value]="status.value">
                  <span>{{status.view }}</span>
                </mat-radio-button>
              </mat-radio-group>&nbsp;
              <mat-form-field class="example-full-width">
                <mat-label>Present Occupation</mat-label>
                <input matInput formControlName="present_occupation" />
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-label>Full name and Address of present Employer (including
                  postal code)</mat-label>
                <input matInput formControlName="full_name_address" autocomplete="off">
              </mat-form-field>
              <span class="fieldLabel" style="margin-bottom: 10px;">Highest level of Education Achieved.</span>
              <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
                fxLayout="row wrap" formControlName="highest_level_education"
                aria-labelledby="example-radio-group-label">
                <mat-radio-button class="example-radio-button" *ngFor="let education of highest_level_education"
                  [value]="education">
                  <span>{{education === 'high_school' ? 'High School': ''}}</span>
                  <span>{{education === 'some_post_sec' ? 'Some Post Sec': ''}}</span>
                  <span>{{education === 'diploma' ? 'Diploma': ''}}</span>
                  <span>{{education === 'university_degree' ? 'University Degree': ''}}</span>
                </mat-radio-button>
              </mat-radio-group>&nbsp;
              <mat-form-field class="example-full-width">
                <mat-label>Spouse Occupation</mat-label>
                <input matInput formControlName="spouse_occupation" />
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-label>Spouse Employer</mat-label>
                <input matInput formControlName="spouse_employer" />
              </mat-form-field>

              <mat-form-field class="example-full-width">
                <mat-label>Payment Followup</mat-label>
                <input matInput formControlName="payment_followup" />
              </mat-form-field>

              <mat-form-field class="example-full-width">
                <mat-label>Status</mat-label>
                <input matInput formControlName="status" />
              </mat-form-field>

            </div>
          </div>
          <div fxLayout="row wrap" fxLayout.xs="wrap">
            <table class="financialTable">
              <thead>
                <th class="tableHead">Name</th>
                <th>Relationship</th>
                <th>Birth Date</th>
                <th>Address</th>
                <th>Action</th>
              </thead>
              <tbody formArrayName="formData" *ngFor="let date of rows.controls; let i = index;">
                <tr [formGroupName]="i">
                  <td><input type="text" formControlName="name"></td>
                  <td><input formControlName="relationship" type="text">
                  <td><input type="date" formControlName="birth_date"></td>
                  <td><input formControlName="address" type="text"></td>
                  <td style="text-align: center;"><button mat-fab color="primary" class="editIconButton"
                      (click)="removeRow(i)">
                      <mat-icon class="matEditIcon" aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
                    </button></td>
                </tr>
              </tbody>
            </table>
            <div fxLayout="row" fxFlex="100">
              <div fxFlex="90"></div>
              <button style="float: right;" type="button" mat-raised-button (click)="addRow()" class="addRow"
                color="primary">
                <mat-icon>add_circle</mat-icon>&nbsp;Add row
              </button>
            </div>
          </div>
          <mat-card-actions class="cardAction">
            <button mat-flat-button class="buttonWidth backButton" (click)="progressBarBack()" color="primary"
              matStepperPrevious>
              Back
            </button>
            <button mat-flat-button color="primary" class="buttonWidth" [disabled]="!personalDatafForm.valid"
              matStepperNext (click)="personalDataPost()">
              Next
            </button>
          </mat-card-actions>
        </mat-card>
      </form>
    </mat-step>

    <!-- Monthly Income -->

    <mat-step [stepControl]="monthlyIncomeForm">
      <form [formGroup]="monthlyIncomeForm">
        <ng-template matStepLabel>MONTHLY INCOME</ng-template>
        <mat-card class="createCustomersCard" fxLayout.xs="column" fxLayoutGap="25px">
          <mat-card-header class="mainTitle">MONTHLY INCOME</mat-card-header>
          <div fxLayout="row" fxLayout.xs="column wrap">
            <div fxLayout="column" fxFlex="50">
              <mat-form-field class="example-full-width">
                <mat-label>Net Employment Income</mat-label>
                <input type="number" placeholder="$0.00" matInput autocomplete="off"
                  formControlName="new_employment_income" />
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-label>Net Earnings of Spouse</mat-label>
                <input matInput type="number" placeholder="$0.00" autocomplete="off"
                  formControlName="net_earning_of_spouse" />
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-label>Net Pensions/Annuities</mat-label>
                <input matInput type="number" placeholder="$0.00" autocomplete="off"
                  formControlName="net_pension_annuties" />
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-label>Net Child Support</mat-label>
                <input matInput type="number" placeholder="$0.00" autocomplete="off"
                  formControlName="net_child_support" />
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-label>Other net income</mat-label>
                <input matInput type="number" placeholder="$0.00" autocomplete="off"
                  formControlName="other_net_income" />
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-label>Child Tax Benefit</mat-label>
                <input matInput type="number" placeholder="$0.00" autocomplete="off"
                  formControlName="child_tax_benefit" />
              </mat-form-field>
            </div>
            <div fxLayout="column" fxFlex="50">
              <mat-form-field class="example-full-width">
                <mat-label>Net Spousal Support</mat-label>
                <input matInput type="number" placeholder="$0.00" autocomplete="off"
                  formControlName="net_spousal_support" />
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-label>Net Employment lnsurance Benefits</mat-label>
                <input matInput type="number" placeholder="$0.00" autocomplete="off"
                  formControlName="net_employment_insurance_benefit" />
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-label>Net Social Assistance</mat-label>
                <input matInput type="number" placeholder="$0.00" autocomplete="off"
                  formControlName="net_social_assistance" />
              </mat-form-field>
              <span class="fieldLabel">Self-Employed</span>
              <div fxLayout="row" fxLayout.xs="column wrap">
                <div fxFlex="50" fxLayoutGap="40px">
                  <mat-form-field class="example-full-width">
                    <mat-label>Gross</mat-label>
                    <input matInput type="number" placeholder="$0.00" autocomplete="off" formControlName="gross" />
                  </mat-form-field>
                </div>
                <div fxFlex="50" fxLayoutGap="5px">
                  <mat-form-field class="example-full-width">
                    <mat-label>Net</mat-label>
                    <input matInput type="number" placeholder="$0.00" autocomplete="off" formControlName="net" />
                  </mat-form-field>
                </div>
              </div>
              <mat-form-field class="example-full-width">
                <mat-label>TOTAL MONTHLY TNCOME (A)</mat-label>
                <input matInput type="number" autocomplete="off" placeholder="$0.00"
                  formControlName="total_monthly_income" />
              </mat-form-field>
            </div>
          </div>

          <mat-card-actions class="cardAction">
            <button mat-flat-button class="buttonWidth backButton" (click)="progressBarBack()" color="primary"
              matStepperPrevious>
              Back
            </button>
            <button mat-flat-button color="primary" [disabled]="!monthlyIncomeForm.valid" class="buttonWidth"
              matStepperNext (click)="monthlyIncomePost()">
              Next
            </button>
          </mat-card-actions>
        </mat-card>
      </form>
    </mat-step>

    <!-- MONTHLY NON-DISCRETIONARY EXPENSES -->

    <mat-step [stepControl]='monthlyNonDisExpence'>
      <form [formGroup]="monthlyNonDisExpence">
        <ng-template matStepLabel>MONTHLY NON-DISCRETIONARY EXPENSES</ng-template>
        <mat-card class="createCustomersCard" fxLayout.xs="column" fxLayoutGap="25px">
          <mat-card-header class="mainTitle">MONTHLY NON-DISCRETIONARY EXPENSES</mat-card-header>
          <mat-card-content>
            <div fxLayout="row" fxLayout.xs="column wrap">
              <div fxLayout="column" fxFlex="50">
                <mat-form-field class="example-full-width">
                  <mat-label>Child Support payments</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="child_support_payment" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Spousal Support payments</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="spousal_support_payment" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Child Care</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="child_care" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Medical Condition Expenses</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="medical_condition_expenses" />
                </mat-form-field>
              </div>
              <div fxLayout="column" fxFlex="50">
                <mat-form-field class="example-full-width">
                  <mat-label>Fines/Penalties lmposed by Court</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="fines_penalities_by_court" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Expenses as a Condition of Employment</mat-label>
                  <input matInput autocomplete="off" type="number"
                    formControlName="expenses_as_a_condition_for_payment" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Debts Where Stay Has Been Lifted</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="debts_as_stay_has_lifted" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Other</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="other" />
                </mat-form-field>
              </div>
            </div>
            <div fxLayout="row">
              <mat-form-field class="example-full-width" style="width: 95%;">
                <mat-label>TOTAL MONTHLY NON-DISCRETIONARY EXPENSES (B)</mat-label>
                <input matInput autocomplete="off" type="number"
                  formControlName="total_monthly_non_discrentionary_expense" />
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.xs="wrap">
              <mat-form-field class="example-full-width" style="width: 95%;">
                <mat-label>AVAILABLE MONTHLY INCOME (A - B) = (c)</mat-label>
                <input matInput autocomplete="off" formControlName="available_monthly_net_income" />
              </mat-form-field>
            </div>
            <mat-card-header class="mainTitle">MONTHLY NON-DISCRETIONARY EXPENSES</mat-card-header>
            <div fxLayout="row" fxLayout.xs="column wrap">
              <div fxLayout="column" fxFlex="50">
                <span class="fieldLabel">Housing Expenses</span>
                <mat-form-field class="example-full-width">
                  <mat-label>Rent/Mortgage</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="rent_mortgage" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Property taxes/condo fees</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="property_taxes_condo_fee" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Heating/gas/oil</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="heating_gas_oil" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Telephone</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="telephone" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Cable</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="cable" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Hydro</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="hydro" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Water</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="water" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Furniture</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="furniture" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Other</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="housing_other" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Total</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="house_expense_total" />
                </mat-form-field>
                <span class="fieldLabel">Personal Expenses</span>
                <mat-form-field class="example-full-width">
                  <mat-label>Smoking</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="expenses_smoking" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Alcohol</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="expenses_alcohol" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Dining/lunches/restaurants</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="expenses_dining" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Entertainment/sports</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="expenses_entertainment" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Gifts/charitable donations</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="expenses_gifts" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Allowances</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="expenses_allowances" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Other</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="expenses_others" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Total</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="personal_expense_total" />
                </mat-form-field>
                <span class="fieldLabel">Non-recoverable Medical Expenses</span>
                <mat-form-field class="example-full-width">
                  <mat-label>Prescriptions</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="prescriptions" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Dental</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="dental" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Other</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="other_non_recoverable" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Total</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="medical_expense_total" />
                </mat-form-field>
              </div>

              <div fxLayout="column" fxFlex="50">
                <span class="fieldLabel">Living Expenses</span>
                <mat-form-field class="example-full-width">
                  <mat-label>Food/grocery</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="food_grocery" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Laundry/dry cleaning</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="laundry_dry" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Grooming/toiletries</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="grooming_toileteries" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Clothing</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="clothing" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Other</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="others" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Total</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="living_expense_total" />
                </mat-form-field>
                <span class="fieldLabel">Transportation Expenses</span>
                <mat-form-field class="example-full-width">
                  <mat-label>Car lease/payments</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="car_lease_payment" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Repairs/maintenance/gas</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="repair_maintainence_cost" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Public transportation</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="public_transportation_cost" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Other</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="transportation_other" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Total</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="transportation_expense_total" />
                </mat-form-field>
                <span class="fieldLabel">lnsurance Expenses</span>
                <mat-form-field class="example-full-width">
                  <mat-label>Vehicle</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="vehicle_insurance" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>House</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="house_insurance" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Furniture/contents</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="furniture_contents_insurance" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Life insurance</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="life_insurance" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Other</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="insurance_other" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Total</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="insurance_expense_total" />
                </mat-form-field>
                <span class="fieldLabel">Payments</span>
                <mat-form-field class="example-full-width">
                  <mat-label>To Trustee</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="payments_to_trustees" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>To secured creditor (Other than mortgage and vehicte)</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="to_secured_creditor" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Other</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="payments_others" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Total</mat-label>
                  <input matInput autocomplete="off" type="number" formControlName="payments_total" />
                </mat-form-field>
              </div>
            </div>

            <div fxLayout="row" fxLayout.xs="wrap">
              <mat-form-field style="width: 95%;">
                <mat-label>TOTAL MONTHLY DISCRETIONARY,EXPENSES (D)</mat-label>
                <input matInput autocomplete="off" type="number" formControlName="total_monthly_discretionary" />
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.xs="wrap">
              <mat-form-field style="width: 95%;">
                <mat-label>TOTAL - SURPLUS/(SHORTFALL) (C)_(D)</mat-label>
                <input matInput autocomplete="off" formControlName="total_surplus" />
              </mat-form-field>
            </div>
          </mat-card-content>
          <mat-card-actions class="cardAction">
            <button mat-flat-button class="buttonWidth backButton" (click)="progressBarBack()" color="primary"
              matStepperPrevious>
              Back
            </button>
            <button mat-flat-button color="primary" class="buttonWidth" [disabled]="!monthlyNonDisExpence.valid"
              matStepperNext (click)="monthlyNdExPost()">
              Next
            </button>
          </mat-card-actions>
        </mat-card>
      </form>
    </mat-step>

    <!-- DEBTS -->

    <mat-step [stepControl]="deptForm">
      <form [formGroup]="deptForm">
        <ng-template matStepLabel>DEBTS</ng-template>
        <mat-card class="createCustomersCard">
          <mat-card-header class="mainTitle">DEBTS</mat-card-header>
          <div style="margin-bottom: 10px;"><span class="fieldLabel" style="margin-bottom: 10px;">
              List all debts, including secured debts and utilities.</span></div>
          <table class="financialTable">
            <thead>
              <th class="tableHead">Creditor's Name</th>
              <th>Account #</th>
              <th>Best Estimate Of Amount Owing</th>
              <th>Action</th>
            </thead>
            <tbody formArrayName="deptTableArray" *ngFor="let date of deptTableRows.controls; let i = index;">
              <tr [formGroupName]="i">
                <td><input type="text" formControlName="debt_creditor_name"></td>
                <td><input type="text" formControlName="account"></td>
                <td><input formControlName="best_estimate" type="text"></td>
                <td style="text-align: center;"><button mat-fab color="primary" class="editIconButton"
                    (click)="deptRemoveRow(i)">
                    <mat-icon class="matEditIcon" aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
                  </button></td>
              </tr>
            </tbody>
          </table>
          <button style="float: right;" type="button" mat-raised-button (click)="addDeptAddRow()" class="addRow"
            color="primary">
            <mat-icon>add_circle</mat-icon>&nbsp;Add row
          </button>
          <div fxLayout="row" fxLayout.xs="column">
            <span class="fieldLabel" style="margin-bottom: 10px; margin-top: 10px;">Within the last five (5) years,
              while
              you knew yourself to be insolvent, have you sold, disposed of, or transferred any real estate?</span>
          </div>
          <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
            fxLayout="row" formControlName="last_five_years" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
          <div style="margin-top: 10px;">
            <table class="financialTable">
              <thead>
                <th class="tableHead">Description of Asset</th>
                <th>Date Disposed</th>
                <th>To Whom</th>
                <th>Proceeds</th>
                <th>Disposition of Proceeds</th>
                <th>Action</th>
              </thead>
              <tbody formArrayName="decsTableArray" *ngFor="let date of DescTableRows.controls; let i = index;">
                <tr [formGroupName]="i">
                  <td><input type="text" formControlName="description_of_asset"></td>
                  <td><input type="text" formControlName="date_disposed"></td>
                  <td><input formControlName="to_whom" type="text"></td>
                  <td><input formControlName="proceeds" type="text"></td>
                  <td><input formControlName="disposition_of_proceeds" type="text"></td>
                  <td style="text-align: center;">
                    <button mat-fab color="primary" class="editIconButton" (click)="descRemoveRow(i)">
                      <mat-icon class="matEditIcon" aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <button style="float: right;" type="button" mat-raised-button (click)="addDesAddRow()" class="addRow"
              color="primary">
              <mat-icon>add_circle</mat-icon>&nbsp;Add row
            </button>
          </div>
          <div fxLayout="row" fxLayout.xs="column">
            <span class="fieldLabel" style="margin-bottom: 10px; margin-top: 10px;">within the last five (5)years, whire
              you knew yourself to be insolvent, have you made any gifts to relatives or others in excess of
              $5OO.OO?</span>
          </div>
          <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
            fxLayout="row" formControlName="gifts_to_relatives" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" fxLayout.xs="column">
            <span class="fieldLabel" style="margin-bottom: 10px; margin-top: 10px;">Do you have a safety deposit
              box?</span>
          </div>
          <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
            fxLayout="row" formControlName="is_safty_deposit" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" fxLayout.xs="column">
            <span class="fieldLabel" style="margin-bottom: 10px; margin-top: 10px;">lf Yes, which bank? &nbsp;&nbsp;
              Please provide details of the contents:</span>
          </div>
          <mat-form-field class="full-width">
            <input style="font-size: 14px;" formControlName="safety_deposit" type="text" matInput autocomplete="off">
          </mat-form-field>
          <div fxLayout="row" fxLayout.xs="column">
            <span class="fieldLabel" style="margin-bottom: 10px; margin-top: 10px;">Does anyone owe you any money?
              provide
              details</span>
          </div>
          <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
            fxLayout="row" formControlName="owe_you_money" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button value="personal_loans">(a) Personal Loans</mat-radio-button>
            <mat-radio-button value="account_receivable">(b) Accounts receivable</mat-radio-button>
            <mat-radio-button value="agreement_for_sale">(b) (c) Agreement for sale</mat-radio-button>
            <mat-radio-button value="other">Other</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" fxLayout.xs="column">
            <span class="fieldLabel" style="margin-bottom: 10px; margin-top: 10px;">Please describe briefly, the
              circumstances, which caused your financialdifficulties.</span>
          </div>
          <mat-form-field appearance="outline" class="full-width">
            <textarea formControlName="circumstances" [matAutosizeMinRows]="3" matTextareaAutosize
              [matAutosizeMaxRows]="3" matInput>
            </textarea>
          </mat-form-field>
          <div fxLayout="row" fxLayout.xs="column">
            <span class="fieldLabel" style="margin-bottom: 5px; margin-top: 10px;">Does anyone owe you any money?
              provide
              details</span>
          </div>
          <div fxLayout="row" fxLayout.xs="column">
            <span style="margin-bottom: 10px;">(a) Collectibles (stamps, coins, art, antiques, etc )</span>
          </div>
          <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
            fxLayout="row" formControlName="collectibles" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" fxLayout.xs="column">
            <span style="margin-bottom: 10px; margin-top: 5px;">(b) savings bonds (owned presently or being purchased on
              a
              Payroll savings Plan)</span>
          </div>
          <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
            fxLayout="row" formControlName="savings_bond" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" fxLayout.xs="column">
            <span style="margin-bottom: 10px; margin-top: 5px;">(c) R.R.S.P.'s</span>
          </div>
          <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
            fxLayout="row" formControlName="rrsp" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" fxLayout.xs="column">
            <span style="margin-bottom: 10px; margin-top: 5px;">(d) Shares (owned presently or being purchased on a
              Payroll savings Plan).</span>
          </div>
          <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
            fxLayout="row" formControlName="shares" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" fxLayout.xs="column">
            <span class="fieldLabel" style="margin-bottom: 5px; margin-top: 10px;">Please provide details</span>
          </div>
          <div fxLayout="row" fxLayout.xs="column">
            <span style="margin-bottom: 10px;">(e) Personal life insurance policies (please include a copy of your life
              insurance policy)</span>
          </div>
          <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
            fxLayout="row" formControlName="pesonal_life_insuarnce_policy" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" style="margin-top: 10px;">
            <table class="financialTable">
              <thead>
                <th></th>
                <th class="tableHead">Policy No.1</th>
                <th>Policy No.2</th>
              </thead>
              <tr>
                <td><span class="fieldLabel">Life lnsurance ComPanY</span></td>
                <td><input type="text" formControlName="life_insurance_policy_one"></td>
                <td><input type="text" formControlName="life_insurance_policy_two"></td>
              </tr>
              <tr>
                <td><span class="fieldLabel">Beneficiary</span></td>
                <td><input type="text" formControlName="beneficiary_policy_one"></td>
                <td><input type="text" formControlName="beneficiary_policy_two"></td>
              </tr>
              <tbody>
                <tr>
                  <td><span class="fieldLabel">Cash Surrender Value</span></td>
                  <td><input type="text" formControlName="cash_surrender_policy_one"></td>
                  <td><input type="text" formControlName="cash_surrender_policy_two"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div fxLayout="row" fxLayout.xs="column">
            <span class="fieldLabel" style="margin-bottom: 10px; margin-top: 10px;">Are you a beneficiary of a will or
              will you receive an inheritance?</span>
          </div>
          <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
            fxLayout="row" formControlName="beneficiary_of_a_will" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" fxLayout.xs="column">
            <span class="fieldLabel" style="margin-bottom: 10px; margin-top: 10px;">Has anyone started legal proceedings
              against you? lf yes, give details.</span>
          </div>
          <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
            fxLayout="row" formControlName="legal_proceedings" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" fxLayout.xs="column">
            <span class="fieldLabel" style="margin-bottom: 5px; margin-top: 10px;">Do any of your debts arise
              from?</span>
          </div>
          <div fxLayout="row" fxLayout.xs="column">
            <span style="margin-bottom: 10px;">A fine or Penalty imposed bY court</span>
          </div>
          <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
            fxLayout="row" formControlName="court_penalties" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" fxLayout.xs="column">
            <span style="margin-bottom: 10px; margin-top: 5px;">A recognizance or bail bond</span>
          </div>
          <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
            fxLayout="row" formControlName="bail_bond" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" fxLayout.xs="column">
            <span style="margin-bottom: 10px; margin-top: 5px;">Alimony or maintenance Payments</span>
          </div>
          <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
            fxLayout="row" formControlName="alimony" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" fxLayout.xs="column">
            <span style="margin-bottom: 10px; margin-top: 5px;">Fraud, embezzlement, misappropriation</span>
          </div>
          <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
            fxLayout="row" formControlName="fraud_other" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" fxLayout.xs="column">
            <span style="margin-bottom: 10px; margin-top: 5px;">Defalcation while acting in a fiduciary capacity</span>
          </div>
          <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
            fxLayout="row" formControlName="defamation_other" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" fxLayout.xs="column">
            <span style="margin-bottom: 10px; margin-top: 5px;">Obtaining property by false pretences/ fraud ulent
              misrePresentation</span>
          </div>
          <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
            fxLayout="row" formControlName="false_pretences" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" fxLayout.xs="column">
            <span class="fieldLabel" style="margin-bottom: 5px; margin-top: 10px;">For which year did you file your last
              income tax return?</span>
          </div>
          <div fxLayout="row" fxLayout.xs="column">
            <span style="margin-bottom: 10px;">Did you receive a refund?</span>
          </div>
          <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
            fxLayout="row" formControlName="receive_a_refund" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" fxLayout.xs="column">
            <span style="margin-bottom: 10px; margin-top: 5px;">Are there arrears owing?</span>
          </div>
          <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
            fxLayout="row" formControlName="arrears_owing" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" fxLayout.xs="column">
            <span style="margin-bottom: 10px; margin-top: 5px;">ls there a copy available?</span>
          </div>
          <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
            fxLayout="row" formControlName="copy_available" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" fxLayout.xs="column">
            <span style="margin-bottom: 10px; margin-top: 5px;">Are you paying/receiving any alimony or
              maintenance?</span>
          </div>
          <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
            fxLayout="row" formControlName="alimony_or_maintenance" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" fxLayout.xs="column">
            <span style="margin-bottom: 10px; margin-top: 5px;">lf yes, to/from whom Amount since January 1st $ Pllase
              provide a copy of the Court Order or separation
              agreement'ls borrower bankrupt?</span>
          </div>
          <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
            fxLayout="row" formControlName="separation_agreement" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
          <mat-card-actions style="margin-top: 20px;">
            <button mat-flat-button class="buttonWidth backButton" (click)="progressBarBack()" color="primary"
              matStepperPrevious>
              Back
            </button>
            <button mat-flat-button color="primary" [disabled]="!deptForm.valid" (click)="deptCreate()"
              class="buttonWidth" matStepperNext>
              Next
            </button>
          </mat-card-actions>
        </mat-card>
      </form>
    </mat-step>

    <!-- General -->

    <mat-step [stepControl]="generalForm">
      <ng-template matStepLabel>GENERAL</ng-template>
      <mat-card>
        <form [formGroup]="generalForm">
          <mat-card-header class="mainTitle">GENERAL</mat-card-header>
          <div fxLayout="row" fxLayout.xs="column">
            <span class="fieldLabel" style="margin-bottom: 10px; margin-top: 10px;">Within the last twelve (12) months,
              have you sold, disposed of or transferred any of your assets,
              either in Canada or elsewhere? (e.g. vehicles, RRSP's, stocks/bonds, furniture)</span>
          </div>
          <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap"
            fxLayout="row" formControlName="is_previous_sale_done" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button [value]="1">Yes</mat-radio-button>
            <mat-radio-button [value]="0">No</mat-radio-button>
          </mat-radio-group>
          <div style="margin-top: 10px;">
            <table class="financialTable">
              <thead>
                <th class="tableHead">Description of Asset</th>
                <th>Date Disposed</th>
                <th>To Whom</th>
                <th>Proceeds</th>
                <th>Disposition of Proceeds</th>
                <th>Action</th>
              </thead>
              <tbody formArrayName="generalFormDetails"
                *ngFor="let date of generalDescTableRows.controls; let i = index;">
                <tr [formGroupName]="i">
                  <td><input type="text" formControlName="assets_description"></td>
                  <td><input type="text" formControlName="disposed_date"></td>
                  <td><input formControlName="to_whom" type="text"></td>
                  <td><input formControlName="proceeds" type="text"></td>
                  <td><input formControlName="disposition" type="text"></td>
                  <td style="text-align: center;">
                    <button mat-fab color="primary" class="editIconButton" (click)="generalDescRemoveRow(i)">
                      <mat-icon class="matEditIcon" aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <button style="float: right;" type="button" mat-raised-button (click)="addGeneralDesAddRow()" class="addRow"
              color="primary">
              <mat-icon>add_circle</mat-icon>&nbsp;Add row
            </button>
          </div>
          <div fxLayout="row" fxLayout.xs="column">
            <span class="fieldLabel" style="margin-bottom: 10px; margin-top: 10px;">Within the last twelve (12)
              months, have you made payments in
              excess of regular payments to any creditor, either in Canada or elsewhere?</span>
          </div>
          <mat-radio-group formControlName="is_payment_done" color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px"
            fxLayout.xs="column wrap" fxLayout="row" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button [value]="1">Yes</mat-radio-button>
            <mat-radio-button [value]="0">No</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" fxLayout.xs="column">
            <span class="fieldLabel" style="margin-bottom: 10px; margin-top: 10px;">Asset seized, Date seized, Name of
              party
              seized by, Was party who made seizure a secured creditor?, Form of security?</span>
          </div>
          <mat-radio-group formControlName="is_asset_seized" color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px"
            fxLayout.xs="column wrap" fxLayout="row" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button [value]="1">Yes</mat-radio-button>
            <mat-radio-button [value]="0">No</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" fxLayout.xs="column">
            <span class="fieldLabel" style="margin-bottom: 10px; margin-top: 10px;">Do you expect to receive any sums
              of money, or any other property within the next 12 months,
              which are not reiated to your normal income</span>
          </div>
          <mat-radio-group formControlName="is_expect_to_receive_money" color="primary" fxLayoutGap="10px"
            fxLayoutGap.xs="10px" fxLayout.xs="column wrap" fxLayout="row" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button [value]="1">Yes</mat-radio-button>
            <mat-radio-button [value]="0">No</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" style="margin-top: 10px;">
            <table class="financialTable">
              <thead>
                <th>ASSETS DESCRIPTION</th>
                <th class="tableHead">LOCATION</th>
                <th>BEST ESTIMATE OF PRESENT VALUE</th>
              </thead>
              <tr>
                <td><span class="fieldLabel">Cash on Hand /ln Bank</span></td>
                <td><input type="text" formControlName="cash_mode_location"></td>
                <td><input type="text" formControlName="cash_mode_present_value"></td>
              </tr>
              <tr>
                <td><span class="fieldLabel">Household Furntture (Fully/Partially Pledged/Exempt)</span></td>
                <td><input type="text" formControlName="house_hold_location"></td>
                <td><input type="text" formControlName="house_hold_present_value"></td>
              </tr>
              <tbody>
                <tr>
                  <td><span class="fieldLabel">Retirement Savings Plans (RRSP)</span></td>
                  <td><input type="text" formControlName="retirement_location"></td>
                  <td><input type="text" formControlName="retirement_present_value"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div fxLayout="row" fxLayout.xs="column">
            <span class="fieldLabel" style="margin-bottom: 10px; margin-top: 10px;">Have you ever been bankrupt,
              either in Canada or elsewfere, or filed a proposal under the Bankruptcy and Insolvency Act?</span>
          </div>
          <mat-radio-group formControlName="bankruptcy" color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px"
            fxLayout.xs="column wrap" fxLayout="row" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button [value]="1">Yes</mat-radio-button>
            <mat-radio-button [value]="0">No</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" fxLayout.xs="column">
            <span class="fieldLabel" style="margin-bottom: 10px; margin-top: 10px;">Have you been self-employed in the
              last five (5) years?</span>
          </div>
          <mat-radio-group formControlName="self_employed" color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px"
            fxLayout.xs="column wrap" fxLayout="row" aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <mat-radio-button [value]="1">Yes</mat-radio-button>
            <mat-radio-button [value]="0">No</mat-radio-button>
          </mat-radio-group>
          <div fxLayout="row" style="margin-top: 10px;">
            <table class="financialTable">
              <thead>
                <th>#</th>
                <th class="tableHead">Business #1</th>
                <th>Business #2</th>
              </thead>
              <tr>
                <td><span class="fieldLabel">Name</span></td>
                <td><input type="text" formControlName="business1_name"></td>
                <td><input type="text" formControlName="business2_name"></td>
              </tr>
              <tr>
                <td><span class="fieldLabel">Proprietorship, Partnership or Limited Company</span></td>
                <td><input type="text" formControlName="business1_type"></td>
                <td><input type="text" formControlName="business2_type"></td>
              </tr>
              <tr>
                <td><span class="fieldLabel">Period of Operation</span></td>
                <td><input type="text" formControlName="business1_period"></td>
                <td><input type="text" formControlName="business2_period"></td>
              </tr>
              <tr>
                <td><span class="fieldLabel">What happened to business</span></td>
                <td><input type="text" formControlName="business1_status"></td>
                <td><input type="text" formControlName="business2_status"></td>
              </tr>
              <tr>
                <td><span class="fieldLabel">Where are books and records of Company</span></td>
                <td><input type="text" formControlName="business1_evidence"></td>
                <td><input type="text" formControlName="business2_evidence"></td>
              </tr>
              <tr>
                <td><span class="fieldLabel">Number of Employees (past 12 months)</span></td>
                <td><input type="text" formControlName="business1_resource"></td>
                <td><input type="text" formControlName="business2_resource"></td>
              </tr>
            </table>
          </div>
        </form>
        <mat-card-actions style="margin-top: 20px;">
          <button mat-flat-button class="buttonWidth backButton" color="primary" (click)="progressBarBack()"
            matStepperPrevious>
            Back
          </button>
          <button mat-flat-button color="primary" [disabled]="!deptForm.valid" (click)="generalSubmit()"
            class="buttonWidth" matStepperNext>
            Submit
          </button>
        </mat-card-actions>
      </mat-card>
    </mat-step>
  </mat-horizontal-stepper>
</div>