import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavbarService } from './navbar.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(
    private http: HttpClient,
    public nav: NavbarService,
    private router: Router
  ) { }

  getLeadsByCustomerStatus(): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}/lead/list?customer_status=not_sure&page=1&per_page=5`
    );
  }

  getPartiallyStatus(): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}/lead/list?customer_status=partially_confirmed&page=1&per_page=5`
    );
  }

  getYetStatus(): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}/lead/list?customer_status=yet_to_be_filtered&page=1&per_page=5`
    );
  }

  getPaginationData(paginationData: any): Observable<any> {
    return this.http.get<any>(
      `${environment.BASE_URL}/lead/list?customer_status=${paginationData.CustomerStaus}&page=${paginationData.page}&per_page=${paginationData.pageLimit}`
    );
  }

  getCustomerStatusFilterStatus(object): Observable<any> {
    return this.http.get(`${environment.BASE_URL}/lead/list?customer_status=${object.customerStatus}&page=1&per_page=10&keyword=${object.filterValue}`);
  }

  createCalanderEvent(eventObject): Observable<any> {
    return this.http.post(`${environment.BASE_URL}/calender/create`, eventObject);
  }

  getEventById(id): Observable<any> {
    return this.http.get(`${environment.BASE_URL}/calender/show/${id}`);
  }

  deleteEvent(id): Observable<any> {
    return this.http.delete(`${environment.BASE_URL}/calender/delete/${id}`);
  }

  editEventPost(eventEditedData): Observable<any> {
    return this.http.post(`${environment.BASE_URL}/calender/update/${eventEditedData.id}`, eventEditedData);
  }

  getActiveClient(): Observable<any> {
    return this.http.get(`${environment.BASE_URL}/customer/count`);
  }

}
