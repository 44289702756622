import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { CustomerAddeventModelComponent } from '../customer-addevent-model/customer-addevent-model.component';
import { DeadleadPopupComponent } from '../deadlead-popup/deadlead-popup.component';
import { LeadService } from '../services/lead.service';
import { SnackBarService } from '../services/snack-bar.service';
import { ConfirmationModalComponent } from '../shared/confirmation-modal/confirmation-modal.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-deadlead',
  templateUrl: './deadlead.component.html',
  styleUrls: ['./deadlead.component.css'],
})
export class DeadleadComponent implements OnInit {
  deadLeadList: any = null;
  displayedColumns: string[] = [
    'name',
    'email',
    'phone',
    'signup_date',
    'leads_status',
    'lead_summary',
    'schedule_a_meeting',
    'Action',
  ];
  isLoading = false;
  isShow = true;
  pageEvent: PageEvent;
  paginationData: any;
  deadLeadForm: FormGroup;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    private leadService: LeadService,
    public dialog: MatDialog,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.deadLeadFormBuild();
    this.getDeadLeads();
    this.filters();
  }


  deadLeadFormBuild(): void {
    this.deadLeadForm = this.formBuilder.group({
      lead_status: 'dead_lead',
      per_page: 10,
      page: 1,
      start_date: '',
      end_date: '',
      keyword: ''
    });
  }


  getDeadLeads(): void {
    this.isLoading = true;
    this.leadService.getLeadsByStatus(this.deadLeadForm.value).subscribe((response: any) => {
      this.paginationData = response.body.data;
      this.deadLeadList = response.body.data.data;
      this.isLoading = false;
    });
  }

  filters(): void {
    this.deadLeadForm.valueChanges.pipe(debounceTime(300)).subscribe((response) => {
      this.getDeadLeads();
    });
  }

  paginationHandler(event: PageEvent): void {
    this.deadLeadForm.get('page').setValue(event.pageIndex + 1);
    this.deadLeadForm.get('pageLimit').setValue(event.pageSize);
  }

  pageIndexHandler(): void {
    this.paginator.pageIndex = 0;
    this.deadLeadForm.get('page').setValue(1);
  }

  clearField(controller: string): void {
    this.deadLeadForm.controls[controller].setValue('');
  }


  editLeadStatus(id: number): void {
    this.leadService.getLeadByID(id).subscribe((data) => {
      const editLeadData = {
        ishowFlag: this.isShow = true,
        leadData: data,
      };
      this.openDialog(editLeadData);
    });
  }

  deleteLead(id: number): void {
    this.leadService.getLeadByID(id).subscribe((data) => {
      this.dialog.open(ConfirmationModalComponent, {
        data: {
          title: 'Confirm',
          message: 'Are you Sure You want to delete this Lead'
        },
      }).afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.leadService.deleteLeadById(id).subscribe((response) => {
            this.snackBarService.openSnackBar(response.message, 'success-class');
            if (response) {
              this.getDeadLeads();
            }
          });
        }
      });
    });
  }

  openDialog(data: { ishowFlag: boolean; leadData: any; }): void {
    const dialogRef = this.dialog.open(DeadleadPopupComponent, {
      width: '60%',
      height: '68%',
      data: {
        custoMmerData: data,
      },
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  openCreateEventDialog(data: any): void {
    const dialogRef = this.dialog.open(CustomerAddeventModelComponent, {
      width: '34%',
      // height: '68%',
      data: {
        custoMmerData: data,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

}
