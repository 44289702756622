import { ChangeDetectorRef, Component } from '@angular/core';
import {
  BreakpointObserver,
  Breakpoints,
  MediaMatcher,
} from '@angular/cdk/layout';
import { NavbarService } from '../services/navbar.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css'],
})
export class MainNavComponent {
  mobileQuery: MediaQueryList;
  opened: boolean;

  showSubmenu: boolean = false;
  isShowing = false;
  isExpanded = true;
  isSaidMenu = true;

  customerShowSubmenu: boolean = false;
  customerisShowing = false;
  customerisExpanded = true;

  moratgeShowSUbmenu: boolean = false
  mortageIsShowing = false;
  mortageIsExpanded = true

  realEstateShowSubmenu: boolean = false
  realEstateIsExpanded = true
  realEstateIsShowing = false

  private _mobileQueryListener: () => void;
  constructor(
    public nav: NavbarService,
    changeDetectorRef: ChangeDetectorRef,
    public route: Router,
    media: MediaMatcher
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.nav.show();
  }

  logOut() {
    sessionStorage.clear();
    this.route.navigate(['/']);
    this.opened = false;
  }

  styleObject(): Object {
    if (this.showSubmenu == false) {
      return { display: 'none' };
    }
    return {};
  }

  customerSubmenuStyles(): Object {
    if (this.customerShowSubmenu == false) {
      return { display: 'none' };
    }
  }

  mortageSubmenuStyles(): Object {
    if (this.moratgeShowSUbmenu == false) {
      return { display: 'none' };
    }
  }

  realEstateSubmenuStyles(): Object {
    if (this.realEstateShowSubmenu == false) {
      return { display: 'none' };
    }
  }

}
