<h1 mat-dialog-title>Upload File</h1>
<div mat-dialog-content>
    <p>
        <button mat-raised-button color="primary" style="color: #fafafa;" (click)="fileInput.click()">
            <mat-icon>cloud_upload</mat-icon>&nbsp;
            <span>Choose File</span>
            <input #fileInput type="file" (change)="upload($event)" style="display:none;" />
        </button>
    </p>
    <p>{{fileName.name}}</p>

    <p>
        <span *ngIf="percentDone">Uploading: {{percentDone}}%</span>
    </p>
    <p>
        <mat-progress-bar *ngIf="percentDone" mode="determinate" value="{{percentDone}}"></mat-progress-bar>
    </p>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Close</button>
    <button color="primary" style="color: white;" [disabled]="!disbled" mat-raised-button
        (click)="onCLickUpload()">Upload</button>
</div>