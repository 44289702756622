import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Data } from '@angular/router';

@Component({
  selector: 'app-fileclosed-confirm',
  templateUrl: './fileclosed-confirm.component.html',
  styleUrls: ['./fileclosed-confirm.component.css']
})
export class FileclosedConfirmComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: Data,
    private dialogRef: MatDialogRef<FileclosedConfirmComponent>
  ) { }

  ngOnInit(): void {
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

}
