
<mat-card-content fxFlex>
    <h2 class="createLeadTitle">Edit Report</h2>
    <form [formGroup]="editReportForm" fxLayoutAlign="stretch" fxLayout="column">
        <mat-form-field class="full-width">
            <mat-label>H/M</mat-label>
            <input type="text" formControlName="h_m" matInput autocomplete="off">
        </mat-form-field>
        <mat-form-field class="full-width">
            <mat-label>CP/BK</mat-label>
            <input formControlName="cp_bk" matInput>
        </mat-form-field>
        <mat-form-field class="full-width">
            <mat-label>Estate Num</mat-label>
            <input formControlName="estate_num" matInput>
        </mat-form-field>
        <mat-form-field class="full-width">
            <input matInput [ngxMatDatetimePicker]="picker" formControlName="first_payment_date"
              placeholder="First Payment Date">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
              [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
              [color]="color" [enableMeridian]="enableMeridian" [disableMinute]="disableMinute" [hideTime]="hideTime">
            </ngx-mat-datetime-picker>
          </mat-form-field>
        <mat-form-field class="full-width">
            <mat-label>CP Amount</mat-label>
            <input formControlName="cp_amt" matInput>
        </mat-form-field><mat-form-field class="full-width">
            <mat-label>Reference Source</mat-label>
            <input formControlName="ref_source" matInput>
        </mat-form-field>
        <div>
            <button mat-raised-button (click)="dialogClose()">Cancel</button>&nbsp;&nbsp;
            <button mat-raised-button type="submit" style="color: white;" (click)="submitData()" color="primary">Submit</button>
        </div>
    </form>
</mat-card-content>