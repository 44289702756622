import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CustomerAddeventModelComponent } from '../customer-addevent-model/customer-addevent-model.component';
import { ViewDialogComponent } from '../view-dialog/view-dialog.component';
import { CustomerServiceService } from '../services/customer-service.service';
import { SnackBarService } from '../services/snack-bar.service';
import { ConfirmationModalComponent } from '../shared/confirmation-modal/confirmation-modal.component';
import { FileUploaderComponent } from '../shared/file-uploader/file-uploader.component';
import { debounceTime } from 'rxjs/operators';
import { AgencyList } from '../models/customer-model';
import { ClosedCustomerModelComponent } from '../closed-customer-model/closed-customer-model.component';

@Component({
  selector: 'app-normal-customer-list',
  templateUrl: './normal-customer-list.component.html',
  styleUrls: ['./normal-customer-list.component.css'],
})
export class NormalCustomerListComponent implements OnInit {
  dataSource: any = null;
  displayedColumns: string[] = [
    'surname',
    'email_address',
    'telephone_cell',
    'payment_follow_up',
    'SignUp_Date',
    'Estate_Number',
    'schedule_a_meeting',
    'upload',
    'First_Counselling_Date',
    'Second_Counselling_Date',
    'Notes',
    'Download_DOC',
    'PDF_Dowload',
    'Closed_Customer',
    'status',
    // 'star',
    'Action',
  ];
  isLoading = false;
  paginationData: any;
  pageEvent: PageEvent;
  customerId: number;
  monthlyData: any;
  agencyList: AgencyList[] = [
    { value: 'agency_one', viewValue: 'HARRIS & PARTNERS INC' },
    { value: 'agency_two', viewValue: 'KUNJAR SHARMA & ASSOCIATES INC' },
    { value: 'agency_three', viewValue: 'CHANDE & COMPANY INC' }
  ];

  // Build
  normalCustomerForm: FormGroup;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    private customerService: CustomerServiceService,
    private route: Router,
    public dialog: MatDialog,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.normalCutomerFormBuild();
    this.getnormalCutomerList();
    this.filter();
  }

  normalCutomerFormBuild(): void {
    this.normalCustomerForm = this.formBuilder.group({
      page: 1,
      pageLimit: 10,
      keyword: '',
      agencyFilter: '',
      customerType: 'customer',
      agency: '',
      start_date: '',
      end_date: '',
      sign_date: new Date()
    });
  }

  getnormalCutomerList(): void {
    const customerArr = [];
    this.isLoading = true;
    this.customerService.getCustomerByStatus(this.normalCustomerForm.value).subscribe((response) => {
      this.isLoading = false;
      this.paginationData = response.body.data;
      response.body.data.data.map((customer: { customer: any; }) => {
        customerArr.push(customer.customer);
      });
      if (customerArr) {
        this.dataSource = customerArr;
        // customerArr.forEach(element => {
        //   this.normalCustomerForm.get('sign_date').setValue(element.signup_date);
        // });
      }
    });
  }

  transform(value: string): string {
    return value.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }

  filter(): void {
    this.normalCustomerForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe((response) => {
        this.getnormalCutomerList();
      });
  }

  paginationHandler(event: PageEvent): void {
    this.normalCustomerForm.get('page').setValue(event.pageIndex + 1);
    this.normalCustomerForm.get('pageLimit').setValue(event.pageSize);
  }

  pageIndexHandler(): void {
    this.paginator.pageIndex = 0;
    this.normalCustomerForm.get('page').setValue(1);
  }

  editCustomer(id: any): void {
    this.route.navigate(['view-edit-customer', id]);
  }

  openDialog1(data: any): void {
    const dialogRef = this.dialog.open(ViewDialogComponent, {
      width: '34%',
      data: {
        notesData: data,
      },
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  openDialog(data: any): void {
    const dialogRef = this.dialog.open(CustomerAddeventModelComponent, {
      width: '34%',
      data: {
        custoMmerData: data,
      },
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  deleteCustomerById(id: number): void {
    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          title: 'Confirm',
          message: 'Are you Sure You want to delete this Customer',
        },
      })
      .afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.customerService.deleteCustomer(id).subscribe((res) => {
            this.snackBarService.openSnackBar(res.message, 'success-class');
            if (res) {
              this.getnormalCutomerList();
            }
          });
        }
      });
  }

  getConfirm(id: any): void {
    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          title: 'Confirm',
          message: 'Are you Sure You want to Confirm ?',
          confirmStatus: true,
        },
      })
      .afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          const object = {
            customerId: id,
            is_complete: 1,
            second_completed_date: '',
          };
          this.customerService.updateConfirmStatus(object).subscribe((res) => {
            this.snackBarService.openSnackBar(res.message, 'success-class');
            if (res) {
              this.getnormalCutomerList();
            }
          });
        }
      });
  }

  getSeconddateConfirm(id: any): void {
    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          title: 'Confirm',
          message: 'Are you Sure You want to Confirm ?',
          confirmStatus: true,
        },
      })
      .afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          const object = {
            customerId: id,
            is_complete: 1,
            second_completed_date: 1,
          };
          this.customerService
            .updateSecondDateConfirmStatus(object)
            .subscribe((res) => {
              this.snackBarService.openSnackBar(res.message, 'success-class');
              if (res) {
                this.getnormalCutomerList();
              }
            });
        }
      });
  }

  fileUpload(id: any): void {
    const dialogRef = this.dialog.open(FileUploaderComponent, {
      width: '34%',
      data: {
        customerId: id,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getnormalCutomerList()
    });
  }

  downloadFile(id: number): void {
    // if (id) {
    window.location.href = `${environment.BASE_URL}/downloaddocument/${id}`;
    // }
  }

  downloadPdf(customerId: any): void {
    window.location.href = `${environment.BASE_URL}/customers/pdf/${customerId}`;
  }

  closedcustomer(row: any): void {
    this.dialog
      .open(ClosedCustomerModelComponent, {
        data: {
          estateNumber: !!row.estate_number,
          title: 'Confirm',
          message: row.estate_number ? 'Are you sure you want to close this Customer?' : 'Please Enter Estate Number To Close This Customer',
        },
      })
      .afterClosed()
      .subscribe((estateNumber: any) => {
        const param = {
          estate_number: row.estate_number || estateNumber,
          id: row.id
        };
        if (estateNumber != 'closePop') {
          this.customerService.closedCustomer(param).subscribe((res) => {
            if (res) {
              this.snackBarService.openSnackBar(res.message, 'success-class');
              this.getnormalCutomerList();
            }
          });
        }
      });
  }

  goToCreate(): void {
    this.route.navigate(['/create-customer']);
  }

  clearField(controller: string): void {
    this.normalCustomerForm.get(controller).setValue('');
  }

  dateFormat(date: string | number | Date): Date {
    if (date !== null) {
      return new Date(date);
    }
  }

  dateUpdate(id: number, event: { target: { value: string | number | Date; }; }): void {
    const updatedData = {
      customer_id: id,
      signup_date: new Date(event.target.value)
    };
    this.customerService.editCustomerDetails(updatedData).subscribe((response) => {
      this.snackBarService.openSnackBar('updated successfully.', 'success-class');
      this.getnormalCutomerList();
    }, (error) => {
      console.log(error)
    });
  }

  addEstateNumber(customerId, event): void {
    if (customerId && event.target.value) {
      const param = {
        customer_id: customerId,
        estate_number: event.target.value
      };
      this.customerService.editCustomerDetails(param).subscribe((response) => {
        this.snackBarService.openSnackBar(response.message, 'success-class');
        this.getnormalCutomerList();
      }, (error) => {
        console.log(error);
      });
    }
  }

}
