<div style="height: 5px; width: 100%;">
  <mat-progress-bar class="loader" *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
</div>
<div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="25px" fxLayoutGap.xs="20px">
  <mat-card class="card-item-1 colorbox" fxFlex>
    <a routerLink="customer-list" style="text-decoration: none">
      <h2 class="clientCount">
        {{activeClientCount}}<mat-icon style="font-family: Material Icons">expand_less</mat-icon>
      </h2>
      <img src="assets/SVG/user.svg" />
      <h2 style="color: white">Active Client</h2>
    </a>
  </mat-card>
  <mat-card class="card-item-2 colorbox" fxFlex>
    <a routerLink="mortage-list" style="text-decoration: none">
      <h2 class="clientCount">
        {{ mortgageTotalCount }}<mat-icon>expand_less</mat-icon>
      </h2>
      <img src="assets/SVG/user.svg" />
      <h2 style="color: white">Mortgage</h2>
    </a>
  </mat-card>
  <mat-card class="card-item-3 colorbox" fxFlex>
    <a routerLink="real-list" style="text-decoration: none">
      <h2 class="clientCount">
        {{ realTotal }}<mat-icon>expand_less</mat-icon>
      </h2>
      <img src="assets/SVG/user.svg" />
      <h2 style="color: white">Real Estate</h2>
    </a>
  </mat-card>
  <mat-card class="card-item-4 colorbox" fxFlex>
    <a routerLink="closed-customer" style="text-decoration: none">
      <h2 class="clientCount">
        {{ closedCustomerCount }}<mat-icon>expand_less</mat-icon>
      </h2>
      <img src="assets/SVG/user.svg" />
      <h2 style="color: white">Closed Customer</h2>
    </a>
  </mat-card>
</div>
<div *ngIf="upCommingEvents" class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="25px"
  fxLayoutGap.xs="25px">
  <mat-card class="card-item-5" fxFlex>
    <mat-toolbar style="height: 50px; background-color: #cee3ec">
      <span style="font-size: 16px">Upcoming Appointments</span>
    </mat-toolbar>
    <div style="padding: 20px" *ngIf="upCommingEvents[0]">
      <div class="date-div">
        <h4>{{upCommingEvents[0] === null ? '':upCommingEvents[0].start.dateTime| date}}</h4>
      </div>
      <div class="client-details">
        <h3>{{upCommingEvents[0].summary | titlecase}}</h3>
        <h4><img src="assets/SVG/time.svg" /> {{ upCommingEvents[0] === null ? '': upCommingEvents[0].start.dateTime|
          date:'shortTime'}}</h4>
      </div>
      <div class="oncall"><span class="phone">Phone</span></div>
    </div>
    <div>
      <div style="padding: 20px; padding-top: 0px" *ngIf="upCommingEvents[1]">
        <div class="date-div">
          <h4>{{ upCommingEvents[1] === null ? '': upCommingEvents[1].start.dateTime| date}}</h4>
        </div>
        <div class="client-details">
          <h3>{{ upCommingEvents[1] === null ? '': upCommingEvents[1].summary | titlecase}}</h3>
          <h4><img src="assets/SVG/time.svg" /> {{upCommingEvents[1].start.dateTime| date:'shortTime'}}</h4>
        </div>
        <div class="oncall"><span class="phone">Phone</span></div>
      </div>
    </div>
  </mat-card>
  <mat-card class="card-item-5" fxFlex>
    <mat-toolbar style="height: 50px; background-color: #cee3ec">
      <span style="font-size: 16px">Leads-Status</span>
    </mat-toolbar>
    <div>
      <div style="padding: 20px">
        <span>Partially confirmed</span>
        <mat-progress-bar mode="determinate" color="secountry"
          value="{{ customerStatus.partiallyConirmed*100/totalCount }}">
        </mat-progress-bar>
        <span style="position: absolute; top: 88px; color: black" [ngStyle]="{ left: 44 + '%' }">{{
          customerStatus.partiallyConirmed*100/totalCount | number: '1.0-0'}}%</span>
      </div>
    </div>
    <div>
      <div style="padding: 20px; padding-top: 0px">
        <span>Yet to be Confirmed</span>
        <mat-progress-bar color="accent" mode="determinate"
          value="{{ customerStatus.yetTobeConfirmed*100/totalCount }}">
        </mat-progress-bar>
        <span style="position: absolute; top: 144px; color: black" [ngStyle]="{ left: 44 + '%' }">{{
          customerStatus.yetTobeConfirmed*100/totalCount | number: '1.0-0'}}%</span>
      </div>
    </div>
    <div>
      <div style="padding: 20px; padding-top: 0px">
        <span>Closed Customer</span>
        <mat-progress-bar mode="determinate" color="warn" value="{{ closedCustomerCount*100/totalCount }}">
        </mat-progress-bar>
        <span style="position: absolute; top: 200px; color: black" [ngStyle]="{ left: 44 + '%' }">{{ closedCustomerCount
          *100/totalCount | number: '1.0-0'}}%</span>
      </div>
    </div>
  </mat-card>
</div>
<div fxLayoutAlign="center center" style="margin-top: 30px">
  <button class="eventButton" mat-flat-button (click)="openDialog()" color="primary">
    ADD EVENT
  </button>
</div> <br />

<mat-card class="container">
  <iframe src="https://calendar.google.com/calendar/embed?src=dfcs2021%40gmail.com&ctz=Asia%2FKolkata" style="border: 0"
    width="100%" height="600" frameborder="0" scrolling="no"></iframe>
</mat-card>