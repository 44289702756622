import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerServiceService } from 'src/app/services/customer-service.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { DownloadModelComponent } from 'src/app/download-model/download-model.component';
import * as moment from 'moment';

export interface TableData {
  name: String;
  relationship: String;
  birthDate: Date;
  address: String;
}

@Component({
  selector: 'app-create-customers',
  templateUrl: './create-customers.component.html',
  styleUrls: ['./create-customers.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class CreateCustomersComponent implements OnInit {
  progressBarlength = [];
  customerId: Number = null;
  educationSelected: string;
  please_check_one: string[] = [
    'mr',
    'ms',
    'mrs',
    'miss',
    'zero_to_eight',
    'some_high_school',
  ];
  customer_type: string[] = ['customer', 'vip_customer'];
  highest_level_education: string[] = [
    'high_school',
    'some_post_sec',
    'diploma',
    'university_degree',
  ];

  yesNo: string[] = ['Yes', 'No'];
  moneyProvideDetails: string[] = [
    '(a) Personal loans',
    '(b) Accounts receivable',
    '(c) Agreement for sale',
    '(d) Other',
  ];

  marritalStatus: any[] = [
    { value: 'married', view: 'Married' },
    { value: 'single', view: 'Single' },
    { value: 'widowed', view: 'Widowed' },
    { value: 'sep', view: 'Sep' },
    { value: 'divorced', view: 'Divorced' },
    { value: 'comlaw', view: 'Com. law' },
  ];

  genders: any[] = [
    { key: 'male', view: 'Male' },
    { key: 'female', view: 'Female' },
  ];

  data: any[] = [
    { name: '', relationship: '', birthDate: new Date(), address: '' },
  ];
  DeptTabledata: any[] = [
    { debt_creditor_name: '', account: '', best_estimate: '' },
  ];
  DescTabledata: any[] = [
    {
      description_of_asset: '',
      date_disposed: '',
      to_whom: '',
      proceeds: '',
      disposition_of_proceeds: '',
    },
  ];
  generalDescTabledata: any[] = [
    {
      assets_description: '',
      disposed_date: '',
      to_whom: '',
      proceeds: '',
      disposition: '',
    },
  ];
  rows: FormArray = this.fb.array([]);
  deptTableRows: FormArray = this.fb.array([]);
  DescTableRows: FormArray = this.fb.array([]);
  generalDescTableRows: FormArray = this.fb.array([]);

  createAgencyForm: FormGroup;
  personalDatafForm: FormGroup;
  depententData: FormGroup;
  monthlyIncomeForm: FormGroup;
  monthlyNonDisExpence: FormGroup;
  deptForm: FormGroup;
  generalForm: FormGroup;
  progressBar = 16.6;

  constructor(
    private fb: FormBuilder,
    private route: Router,
    private activeRoute: ActivatedRoute,
    private customerService: CustomerServiceService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.agencyFormBuild();
    this.personalDataFormBuild(this.customerId);
    this.monthlyIncomeFormBuild();
    this.monthlyNonDisExpenceBuild();
    this.deptFormBuild();
    this.generalFormBuild();
    this.data.forEach((d: any) => this.addRow(d, false));
    this.DeptTabledata.forEach((item: any) => this.addDeptAddRow(item, false));
    this.DescTabledata.forEach((item) => this.addDesAddRow(item, false));
    this.generalDescTabledata.forEach((item) =>
      this.addGeneralDesAddRow(item, false)
    );
    this.refreshTotalIncome();
    this.refreshTotalMonthlyNonEx();
  }

  agencyFormBuild() {
    this.createAgencyForm = this.fb.group({
      choose_agency: ['', Validators.required],
      agency_extra1: '',
      agency_extra: '',
    });
  }

  monthlyIncomeFormBuild() {
    this.monthlyIncomeForm = this.fb.group({
      new_employment_income: new FormControl(),
      net_spousal_support: new FormControl(),
      net_earning_of_spouse: new FormControl(),
      net_employment_insurance_benefit: new FormControl(),
      net_pension_annuties: new FormControl(),
      net_social_assistance: new FormControl(),
      net_child_support: new FormControl(),
      gross: new FormControl(),
      net: new FormControl(),
      other_net_income: new FormControl(),
      child_tax_benefit: new FormControl(),
      total_monthly_income: new FormControl(),
    });
  }

  monthlyNonDisExpenceBuild() {
    this.monthlyNonDisExpence = this.fb.group({
      child_support_payment: new FormControl(),
      fines_penalities_by_court: new FormControl(),
      spousal_support_payment: new FormControl(),
      expenses_as_a_condition_for_payment: new FormControl(),
      child_care: new FormControl(),
      debts_as_stay_has_lifted: new FormControl(),
      medical_condition_expenses: new FormControl(),
      other: new FormControl(),
      total_monthly_non_discrentionary_expense: new FormControl(),
      available_monthly_net_income: new FormControl(),
      rent_mortgage: new FormControl(),
      food_grocery: new FormControl(),
      property_taxes_condo_fee: new FormControl(),
      laundry_dry: new FormControl(),
      heating_gas_oil: new FormControl(),
      grooming_toileteries: new FormControl(),
      telephone: new FormControl(),
      clothing: new FormControl(),
      cable: new FormControl(),
      others: new FormControl(),
      hydro: new FormControl(),
      water: new FormControl(),
      car_lease_payment: new FormControl(),
      furniture: new FormControl(),
      repair_maintainence_cost: new FormControl(),
      housing_other: new FormControl(),
      public_transportation_cost: new FormControl(),
      transportation_other: new FormControl(),
      vehicle_insurance: new FormControl(),

      house_expense_total: new FormControl(),
      living_expense_total: new FormControl(),
      transportation_expense_total: new FormControl(),
      insurance_expense_total: new FormControl(),
      payments_total: new FormControl(),
      medical_expense_total: new FormControl(),
      personal_expense_total: new FormControl(),

      expenses_smoking: new FormControl(),
      expenses_alcohol: new FormControl(),
      expenses_dining: new FormControl(),
      expenses_entertainment: new FormControl(),
      expenses_gifts: new FormControl(),
      expenses_allowances: new FormControl(),
      expenses_others: new FormControl(),

      house_insurance: new FormControl(),
      furniture_contents_insurance: new FormControl(),
      life_insurance: new FormControl(),
      insurance_other: new FormControl(),
      prescriptions: new FormControl(),
      dental: new FormControl(),
      other_non_recoverable: new FormControl(),
      payments_to_trustees: new FormControl(),
      to_secured_creditor: new FormControl(),
      payments_others: new FormControl(),
      total_monthly_discretionary: new FormControl(),
      total_surplus: new FormControl(),
    });
  }

  deptFormBuild() {
    this.deptForm = this.fb.group({
      deptTableArray: this.deptTableRows,
      decsTableArray: this.DescTableRows,
      last_five_years: new FormControl(),
      gifts_to_relatives: new FormControl(),
      owe_you_money: new FormControl(),
      circumstances: new FormControl(),
      collectibles: new FormControl(),
      savings_bond: new FormControl(),
      rrsp: new FormControl(),
      shares: new FormControl(),
      pesonal_life_insuarnce_policy: new FormControl(),
      life_insurance_policy_one: new FormControl(),
      life_insurance_policy_two: new FormControl(),
      beneficiary_policy_one: new FormControl(),
      beneficiary_policy_two: new FormControl(),
      cash_surrender_policy_one: new FormControl(),
      cash_surrender_policy_two: new FormControl(),
      beneficiary_of_a_will: new FormControl(),
      legal_proceedings: new FormControl(),
      court_penalties: new FormControl(),
      bail_bond: new FormControl(),
      alimony: new FormControl(),
      fraud_other: new FormControl(),
      defamation_other: new FormControl(),
      false_pretences: new FormControl(),
      receive_a_refund: new FormControl(),
      arrears_owing: new FormControl(),
      copy_available: new FormControl(),
      safety_deposit: new FormControl(),
      is_safty_deposit: new FormControl(),
      alimony_or_maintenance: new FormControl(),
      separation_agreement: new FormControl(),
    });
  }

  refreshTotalMonthlyNonEx() {
    this.monthlyNonDisExpence.valueChanges.subscribe((response) => {
      const fistTotalNonDisEX =
        response.child_support_payment +
        response.fines_penalities_by_court +
        response.expenses_as_a_condition_for_payment +
        response.child_care +
        response.debts_as_stay_has_lifted +
        response.medical_condition_expenses +
        response.other +
        response.spousal_support_payment;
      this.monthlyNonDisExpence
        .get('total_monthly_non_discrentionary_expense')
        .setValue(fistTotalNonDisEX, { emitEvent: false });
      const housingTotal =
        response.rent_mortgage +
        response.property_taxes_condo_fee +
        response.heating_gas_oil +
        response.telephone +
        response.cable +
        response.hydro +
        response.water +
        response.furniture +
        response.housing_other;
      this.monthlyNonDisExpence
        .get('house_expense_total')
        .setValue(housingTotal > 0 ? housingTotal : null, { emitEvent: false });

      const livingTotal =
        response.food_grocery +
        response.laundry_dry +
        response.grooming_toileteries +
        response.clothing +
        response.others;
      this.monthlyNonDisExpence
        .get('living_expense_total')
        .setValue(livingTotal > 0 ? livingTotal : null, { emitEvent: false });

      const transportationTotal =
        response.car_lease_payment +
        response.repair_maintainence_cost +
        response.public_transportation_cost +
        response.transportation_other;
      this.monthlyNonDisExpence
        .get('transportation_expense_total')
        .setValue(transportationTotal > 0 ? transportationTotal : null, {
          emitEvent: false,
        });

      const lnsuranceTotal =
        response.vehicle_insurance +
        response.house_insurance +
        response.furniture_contents_insurance +
        response.life_insurance +
        response.insurance_other;
      this.monthlyNonDisExpence
        .get('insurance_expense_total')
        .setValue(lnsuranceTotal > 0 ? lnsuranceTotal : null, {
          emitEvent: false,
        });

      const paymentsTotal =
        response.payments_to_trustees +
        response.to_secured_creditor +
        response.payments_others;
      this.monthlyNonDisExpence
        .get('payments_total')
        .setValue(paymentsTotal > 0 ? paymentsTotal : null, {
          emitEvent: false,
        });

      const personalTotal =
        response.expenses_smoking +
        response.expenses_alcohol +
        response.expenses_dining +
        response.expenses_entertainment +
        response.expenses_gifts +
        response.expenses_allowances +
        response.expenses_others;
      this.monthlyNonDisExpence
        .get('personal_expense_total')
        .setValue(personalTotal > 0 ? personalTotal : null, {
          emitEvent: false,
        });

      const medicalTotal =
        response.prescriptions +
        response.dental +
        response.other_non_recoverable;
      this.monthlyNonDisExpence
        .get('medical_expense_total')
        .setValue(medicalTotal > 0 ? medicalTotal : null, { emitEvent: false });

      const totalExpance =
        fistTotalNonDisEX +
        housingTotal +
        livingTotal +
        transportationTotal +
        lnsuranceTotal +
        paymentsTotal +
        medicalTotal +
        personalTotal;
      this.monthlyNonDisExpence
        .get('total_monthly_discretionary')
        .setValue(totalExpance > 0 ? totalExpance : null, { emitEvent: false });
    });
  }

  personalDataFormBuild(id): void {
    this.personalDatafForm = this.fb.group({
      customer_id: this.customerId,
      customer_type: ['', Validators.required],
      customerGender: '',
      surname: ['', Validators.required],
      signup_date: ['', Validators.required],
      please_check_one: new FormControl(),
      given_names: new FormControl(),
      telephone_honre: new FormControl(),
      other_names: new FormControl(),
      telephone_cell: ['', Validators.required],
      street_address: new FormControl(),
      full_name_address: new FormControl(),
      email_address: ['', Validators.required],
      marital_status: new FormControl(),
      present_occupation: new FormControl(),
      town_city: new FormControl(),
      province: new FormControl(),
      postal_code: new FormControl(),
      highest_level_education: new FormControl(),
      s_i_n: new FormControl(),
      spouse_employer: new FormControl(),
      payment_followup: new FormControl(),
      status: new FormControl(),
      birth_date: new FormControl(),
      spouse_occupation: new FormControl(),
      note: new FormControl(),
      number_of_dependents: new FormControl(),
      estate_number: null,
      formData: this.rows,
    });
  }

  generalFormBuild(): void {
    this.generalForm = this.fb.group({
      is_previous_sale_done: '',
      is_payment_done: '',
      is_asset_seized: '',
      is_expect_to_receive_money: '',
      cash_mode_location: '',
      house_hold_location: '',
      retirement_location: '',
      cash_mode_present_value: '',
      house_hold_present_value: '',
      retirement_present_value: '',
      bankruptcy: '',
      self_employed: '',
      business1_name: '',
      business1_type: '',
      business1_period: '',
      business1_status: '',
      business1_evidence: '',
      business1_resource: '',
      business2_name: '',
      business2_type: '',
      business2_period: '',
      business2_status: '',
      business2_evidence: '',
      business2_resource: '',
      created_by: '',
      updated_by: '',
      generalFormDetails: this.generalDescTableRows,
    });
  }

  refreshTotalIncome() {
    this.monthlyIncomeForm.valueChanges.subscribe((response) => {
      const totalIncome =
        response.new_employment_income +
        response.net_child_support +
        response.net_spousal_support +
        response.net_earning_of_spouse +
        response.net_employment_insurance_benefit +
        response.net_pension_annuties +
        response.net_social_assistance +
        response.gross +
        response.net +
        response.other_net_income +
        response.child_tax_benefit; // calculate from response
      this.monthlyIncomeForm
        .get('total_monthly_income')
        .setValue(totalIncome, { emitEvent: false });
    });
  }

  createAgencyPostCall() {
    this.progressBar = this.progressBar + 16.6;
    let agencyData = {
      choose_agency: this.createAgencyForm.value.choose_agency,
      agency_extra1: this.createAgencyForm.value.agency_extra1,
      agency_extra: this.createAgencyForm.value.agency_extra,
    };
    this.customerService.createAgency(agencyData).subscribe((data) => {
      if (data) {
        this.customerId = data.data.id;
      }
    });
  }

  personalDataPost() {
    const object = {
      customer_id: this.customerId,
      customer_type: this.personalDatafForm.value.customer_type,
      gender: this.personalDatafForm.value.customerGender,
      marital_status: this.personalDatafForm.value.marital_status,
      surname: this.personalDatafForm.value.surname,
      signup_date:
        new Date(
          moment(this.personalDatafForm.value.signup_date).format(
            'YYYY-MM-DD[T]HH:mm:ss'
          )
        ).getFullYear() +
        '-' +
        Number(
          new Date(
            moment(this.personalDatafForm.value.signup_date).format(
              'YYYY-MM-DD[T]HH:mm:ss'
            )
          ).getMonth() + Number(1)
        ) +
        '-' +
        new Date(
          moment(this.personalDatafForm.value.signup_date).format(
            'YYYY-MM-DD[T]HH:mm:ss'
          )
        ).getDate(),
      given_names: this.personalDatafForm.value.given_names,
      other_names: this.personalDatafForm.value.other_names,
      street_address: this.personalDatafForm.value.street_address,
      town_city: this.personalDatafForm.value.town_city,
      province: this.personalDatafForm.value.province,
      postal_code: this.personalDatafForm.value.postal_code,
      s_i_n: this.personalDatafForm.value.s_i_n,
      birth_date: this.personalDatafForm.value.birth_date,
      please_check_one: this.personalDatafForm.value.please_check_one,
      telephone_honre: this.personalDatafForm.value.telephone_honre,
      telephone_cell: this.personalDatafForm.value.telephone_cell,
      email_address: this.personalDatafForm.value.email_address,
      present_occupation: this.personalDatafForm.value.present_occupation,
      full_name_address: this.personalDatafForm.value.full_name_address,
      highest_level_education:
        this.personalDatafForm.value.highest_level_education,
      spouse_occupation: this.personalDatafForm.value.spouse_occupation,
      spouse_employer: this.personalDatafForm.value.spouse_employer,
      payment_followup: this.personalDatafForm.value.payment_followup,
      status: this.personalDatafForm.value.status,
      note: this.personalDatafForm.value.note,
      number_of_dependents: this.personalDatafForm.value.number_of_dependents,
      estate_number: this.personalDatafForm.value.estate_number,
      dependent: this.personalDatafForm.value.formData,
    };
    this.customerService.personalData(object).subscribe((data) => {
      this.progressBar = this.progressBar + 16.6;
    });
  }

  monthlyIncomePost(): void {
    const object = {
      customer_id: this.customerId,
      new_employment_income: this.monthlyIncomeForm.value.new_employment_income,
      net_spousal_support: this.monthlyIncomeForm.value.net_spousal_support,
      net_earning_of_spouse: this.monthlyIncomeForm.value.net_earning_of_spouse,
      net_employment_insurance_benefit:
        this.monthlyIncomeForm.value.net_employment_insurance_benefit,
      net_pension_annuties: this.monthlyIncomeForm.value.net_pension_annuties,
      net_social_assistance: this.monthlyIncomeForm.value.net_social_assistance,
      net_child_support: this.monthlyIncomeForm.value.net_child_support,
      gross: this.monthlyIncomeForm.value.gross,
      net: this.monthlyIncomeForm.value.net,
      other_net_income: this.monthlyIncomeForm.value.other_net_income,
      child_tax_benefit: this.monthlyIncomeForm.value.child_tax_benefit,
      total_monthly_income: this.monthlyIncomeForm.value.total_monthly_income,
    };
    this.progressBar = this.progressBar + 16.6;
    this.customerService.monthlyIncomePost(object).subscribe((data) => { });
  }

  monthlyNdExPost(): void {
    const object = {
      customer_id: this.customerId,
      child_support_payment:
        this.monthlyNonDisExpence.value.child_support_payment,
      fines_penalities_by_court:
        this.monthlyNonDisExpence.value.fines_penalities_by_court,
      spousal_support_payment:
        this.monthlyNonDisExpence.value.spousal_support_payment,
      expenses_as_a_condition_for_payment:
        this.monthlyNonDisExpence.value.expenses_as_a_condition_for_payment,
      child_care: this.monthlyNonDisExpence.value.child_care,
      debts_as_stay_has_lifted:
        this.monthlyNonDisExpence.value.debts_as_stay_has_lifted,
      medical_condition_expenses:
        this.monthlyNonDisExpence.value.medical_condition_expenses,
      other: this.monthlyNonDisExpence.value.other,
      total_monthly_non_discrentionary_expense:
        this.monthlyNonDisExpence.value
          .total_monthly_non_discrentionary_expense,
      available_monthly_net_income:
        this.monthlyNonDisExpence.value.available_monthly_net_income,
      rent_mortgage: this.monthlyNonDisExpence.value.rent_mortgage,
      food_grocery: this.monthlyNonDisExpence.value.food_grocery,
      property_taxes_condo_fee:
        this.monthlyNonDisExpence.value.property_taxes_condo_fee,
      laundry_dry: this.monthlyNonDisExpence.value.laundry_dry,
      heating_gas_oil: this.monthlyNonDisExpence.value.heating_gas_oil,
      grooming_toileteries:
        this.monthlyNonDisExpence.value.grooming_toileteries,
      telephone: this.monthlyNonDisExpence.value.telephone,
      clothing: this.monthlyNonDisExpence.value.clothing,
      cable: this.monthlyNonDisExpence.value.cable,
      others: this.monthlyNonDisExpence.value.others,
      hydro: this.monthlyNonDisExpence.value.hydro,
      water: this.monthlyNonDisExpence.value.water,
      car_lease_payment: this.monthlyNonDisExpence.value.car_lease_payment,
      furniture: this.monthlyNonDisExpence.value.furniture,
      repair_maintainence_cost:
        this.monthlyNonDisExpence.value.repair_maintainence_cost,
      housing_other: this.monthlyNonDisExpence.value.housing_other,
      public_transportation_cost:
        this.monthlyNonDisExpence.value.public_transportation_cost,
      transportation_other:
        this.monthlyNonDisExpence.value.transportation_other,
      vehicle_insurance: this.monthlyNonDisExpence.value.vehicle_insurance,

      expenses_smoking: this.monthlyNonDisExpence.value.expenses_smoking,
      expenses_alcohol: this.monthlyNonDisExpence.value.expenses_alcohol,
      expenses_dining: this.monthlyNonDisExpence.value.expenses_dining,
      expenses_entertainment:
        this.monthlyNonDisExpence.value.expenses_entertainment,
      expenses_gifts: this.monthlyNonDisExpence.value.expenses_gifts,
      expenses_allowances: this.monthlyNonDisExpence.value.expenses_allowances,
      expenses_others: this.monthlyNonDisExpence.value.expenses_others,

      house_insurance: this.monthlyNonDisExpence.value.house_insurance,
      furniture_contents_insurance:
        this.monthlyNonDisExpence.value.furniture_contents_insurance,
      life_insurance: this.monthlyNonDisExpence.value.life_insurance,
      insurance_other: this.monthlyNonDisExpence.value.insurance_other,
      prescriptions: this.monthlyNonDisExpence.value.prescriptions,
      dental: this.monthlyNonDisExpence.value.dental,
      other_non_recoverable:
        this.monthlyNonDisExpence.value.other_non_recoverable,
      payments_to_trustees:
        this.monthlyNonDisExpence.value.payments_to_trustees,
      to_secured_creditor: this.monthlyNonDisExpence.value.to_secured_creditor,
      payments_others: this.monthlyNonDisExpence.value.payments_others,
      total_monthly_discretionary:
        this.monthlyNonDisExpence.value.total_monthly_discretionary,
      total_surplus: this.monthlyNonDisExpence.value.total_surplus,
      house_expense_total: this.monthlyNonDisExpence.value.house_expense_total,
      living_expense_total:
        this.monthlyNonDisExpence.value.living_expense_total,
      transportation_expense_total:
        this.monthlyNonDisExpence.value.transportation_expense_total,
      insurance_expense_total:
        this.monthlyNonDisExpence.value.insurance_expense_total,
      payments_total: this.monthlyNonDisExpence.value.payments_total,
      medical_expense_total: this.monthlyNonDisExpence.value.payments_total,
      personal_expense_total:
        this.monthlyNonDisExpence.value.personal_expense_total,
    };
    this.progressBar = this.progressBar + 16.6;
    this.customerService.monthlyNdPost(object).subscribe((data) => { });
  }

  deptCreate() {
    let object = {
      customer_id: this.customerId,
      last_five_years: this.deptForm.value.last_five_years,
      gifts_to_relatives: this.deptForm.value.gifts_to_relatives,
      owe_you_money: this.deptForm.value.owe_you_money,
      circumstances: this.deptForm.value.circumstances,
      collectibles: this.deptForm.value.collectibles,
      savings_bond: this.deptForm.value.savings_bond,
      rrsp: this.deptForm.value.rrsp,
      shares: this.deptForm.value.shares,
      pesonal_life_insuarnce_policy:
        this.deptForm.value.pesonal_life_insuarnce_policy,
      life_insurance_policy_one: this.deptForm.value.life_insurance_policy_one,
      life_insurance_policy_two: this.deptForm.value.life_insurance_policy_two,
      beneficiary_policy_one: this.deptForm.value.beneficiary_policy_one,
      beneficiary_policy_two: this.deptForm.value.beneficiary_policy_two,
      cash_surrender_policy_one: this.deptForm.value.cash_surrender_policy_one,
      cash_surrender_policy_two: this.deptForm.value.cash_surrender_policy_two,
      beneficiary_of_a_will: this.deptForm.value.beneficiary_of_a_will,
      legal_proceedings: this.deptForm.value.legal_proceedings,
      court_penalties: this.deptForm.value.court_penalties,
      bail_bond: this.deptForm.value.bail_bond,
      alimony: this.deptForm.value.alimony,
      fraud_other: this.deptForm.value.fraud_other,
      defamation_other: this.deptForm.value.defamation_other,
      false_pretences: this.deptForm.value.false_pretences,
      receive_a_refund: this.deptForm.value.receive_a_refund,
      arrears_owing: this.deptForm.value.arrears_owing,
      copy_available: this.deptForm.value.copy_available,
      alimony_or_maintenance: this.deptForm.value.alimony_or_maintenance,
      separation_agreement: this.deptForm.value.separation_agreement,
      debt: this.deptForm.value.deptTableArray,
      safety_deposit: this.deptForm.value.safety_deposit,
      is_safty_deposit: this.deptForm.value.is_safty_deposit,
      description: this.deptForm.value.decsTableArray,
    };
    this.customerService.deptPost(object).subscribe((data) => {
      this.progressBar = this.progressBar + 17;
    });
  }

  generalSubmit() {
    let object = {
      customer_id: this.customerId,
      is_previous_sale_done: this.generalForm.value.is_previous_sale_done,
      is_payment_done: this.generalForm.value.is_payment_done,
      is_asset_seized: this.generalForm.value.is_asset_seized,
      is_expect_to_receive_money:
        this.generalForm.value.is_expect_to_receive_money,
      cash_mode_location: this.generalForm.value.cash_mode_location,
      house_hold_location: this.generalForm.value.house_hold_location,
      retirement_location: this.generalForm.value.retirement_location,
      cash_mode_present_value: this.generalForm.value.cash_mode_present_value,
      house_hold_present_value: this.generalForm.value.house_hold_present_value,
      retirement_present_value: this.generalForm.value.retirement_present_value,
      bankruptcy: this.generalForm.value.bankruptcy,
      self_employed: this.generalForm.value.self_employed,
      business1_name: this.generalForm.value.business1_name,
      business1_type: this.generalForm.value.business1_type,
      business1_period: this.generalForm.value.business1_period,
      business1_status: this.generalForm.value.business1_status,
      business1_evidence: this.generalForm.value.business1_evidence,
      business1_resource: this.generalForm.value.business1_resource,
      business2_name: this.generalForm.value.business2_name,
      business2_type: this.generalForm.value.business2_type,
      business2_period: this.generalForm.value.business2_period,
      business2_status: this.generalForm.value.business2_status,
      business2_evidence: this.generalForm.value.business2_evidence,
      business2_resource: this.generalForm.value.business2_resource,
      previous_sale_details: this.generalForm.value.generalFormDetails,
    };
    this.customerService.generalPost(object).subscribe((response) => {
      if (response) {
        this.snackBarService.openSnackBar(response.message, 'success-class');
        const dialogRef = this.dialog.open(DownloadModelComponent, {
          data: {
            customerId: this.customerId,
          },
        });
        dialogRef.afterClosed().subscribe((result) => { });
      }
    });
  }

  progressBarBack() {
    this.progressBar = this.progressBar - 16.6;
  }

  addRow(d?: any, noUpdate?: boolean) {
    const row = this.fb.group({
      name: [d && d.name ? d.name : null, []],
      relationship: [d && d.relationship ? d.relationship : null, []],
      birth_date: [d && d.birth_date ? d.birth_date : null, []],
      address: [d && d.address ? d.address : null, []],
    });
    this.rows.push(row);
  }

  removeRow(index) {
    this.rows.removeAt(index);
  }

  addDeptAddRow(d?: any, noUpdate?: boolean) {
    const deptRow = this.fb.group({
      debt_creditor_name: [
        d && d.debt_creditor_name ? d.debt_creditor_name : null,
        [],
      ],
      account: [d && d.account ? d.account : null, []],
      best_estimate: [d && d.best_estimate ? d.best_estimate : null, []],
    });
    this.deptTableRows.push(deptRow);
  }

  addDesAddRow(d?: any, noUpdate?: boolean) {
    const descRow = this.fb.group({
      description_of_asset: [
        d && d.description_of_asset ? d.description_of_asset : null,
        [],
      ],
      date_disposed: [d && d.date_disposed ? d.date_disposed : null, []],
      to_whom: [d && d.to_whom ? d.to_whom : null, []],
      proceeds: [d && d.proceeds ? d.proceeds : null, []],
      disposition_of_proceeds: [
        d && d.disposition_of_proceeds ? d.disposition_of_proceeds : null,
        [],
      ],
    });
    this.DescTableRows.push(descRow);
  }

  addGeneralDesAddRow(d?: any, noUpdate?: boolean) {
    const generalDescRow = this.fb.group({
      assets_description: [
        d && d.description_of_asset ? d.description_of_asset : null,
        [],
      ],
      disposed_date: [d && d.date_disposed ? d.date_disposed : null, []],
      to_whom: [d && d.to_whom ? d.to_whom : null, []],
      proceeds: [d && d.proceeds ? d.proceeds : null, []],
      disposition: [
        d && d.disposition_of_proceeds ? d.disposition_of_proceeds : null,
        [],
      ],
    });
    this.generalDescTableRows.push(generalDescRow);
  }

  deptRemoveRow(index) {
    this.deptTableRows.removeAt(index);
  }

  descRemoveRow(index) {
    this.DescTableRows.removeAt(index);
  }

  generalDescRemoveRow(index) {
    this.generalDescTableRows.removeAt(index);
  }
}
