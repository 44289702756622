<div>
<h2 mat-dialog-title>Shedule a Meeting with {{data.custoMmerData ? data.custoMmerData:''}}</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="editEventForm">
<div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="20px" fxLayoutGap.xs="20px">
    
    <mat-form-field>
        <mat-label>Subject</mat-label>
        <input matInput formControlName="summary" [ngModel]="data.eventData.data.summary" autocomplete="off">
    </mat-form-field>
    <mat-form-field class="full-width">
        <input matInput [ngxMatDatetimePicker]="picker1" formControlName="start" [ngModel]="data.eventData.data.start.dateTime"
          placeholder="Choose a Date & Time">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker1 [showSpinners]="showSpinners" [showSeconds]="showSeconds"
          [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
          [color]="color" [enableMeridian]="enableMeridian" [disableMinute]="disableMinute" [hideTime]="hideTime">
        </ngx-mat-datetime-picker>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput [ngxMatDatetimePicker]="picker" formControlName="end" [ngModel]="data.eventData.data.end.dateTime"
          placeholder="Choose a Date & Time">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
          [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
          [color]="color" [enableMeridian]="enableMeridian" [disableMinute]="disableMinute" [hideTime]="hideTime">
        </ngx-mat-datetime-picker>
      </mat-form-field>
    <mat-form-field >
        <mat-label>Message</mat-label>
        <textarea formControlName="description" [ngModel]="data.eventData.data.description" [matAutosizeMinRows]="4" matTextareaAutosize [matAutosizeMaxRows]="5" matInput placeholder="Message..."></textarea>
    </mat-form-field>

</div>
</form>
</mat-dialog-content>
<mat-dialog-actions align="end">
<button mat-button (click)="onCancel()">Cancel</button>
<button mat-button style="background-color: #03A9F4;" (click)="saveEvent()" class="buttonWidth" cdkFocusInitial>Save</button>
</mat-dialog-actions>
</div>

