<div [formGroup]="editedForm" *ngIf="data.custoMmerData.ishowFlag">
  <h2 mat-dialog-title>
    {{
      data.custoMmerData.leadData.data.name + " " + "Lead Status" | titlecase
    }}
  </h2>
  <h3 class="fieldLabel" *ngIf="data.custoMmerData.ishowFlag">
    {{
      "Do You Want to Change Lead Status of " +
        data.custoMmerData.leadData.data.name +
        "?" | titlecase
    }}
  </h3>
  <div *ngIf="data.custoMmerData.ishowFlag" fxLayout="row" fxLayoutGap="10px">
    <mat-radio-group color="primary" fxLayoutGap="10px" fxLayoutGap.xs="10px" fxLayout.xs="column wrap" fxLayout="row"
      formControlName="leadStatus" value="second_meeting" aria-labelledby="example-radio-group-label"
      class="example-radio-group">
      <mat-radio-button value="dead_lead" (click)="leadStatusShow()">Dead Lead</mat-radio-button>
      <mat-radio-button value="make_customer" (click)="makeCustomerRadio()">Make Customer</mat-radio-button>
    </mat-radio-group>
  </div>
  <h3 class="fieldLabel" style="margin-top: 10px" *ngIf="data.custoMmerData.ishowFlag && makeCustomerClick">
    {{
      "Do You Want to Change Lead Summary of " +
        data.custoMmerData.leadData.data.name +
        "?" | titlecase
    }}
  </h3>
  <mat-form-field class="example-full-width" appearance="outline" *ngIf="makeCustomerClick">
    <mat-label>Lead Summary</mat-label>
    <textarea [matAutosizeMinRows]="7" matTextareaAutosize [matAutosizeMaxRows]="7" matInput
      [ngModel]="data.custoMmerData.leadData.data.lead_summary" formControlName="leadSummay"></textarea>
  </mat-form-field>
  <h3 class="fieldLabel" style="margin-top: 10px" *ngIf="data.custoMmerData.ishowFlag && makeCustomerClick">
    {{ "Customer Status" | titlecase }}
  </h3>
  <mat-radio-group color="primary" fxLayoutGap="10px" aria-label="Select an option" formControlName="customerStatus"
    *ngIf="makeCustomerClick">
    <mat-radio-button [checked]="
        this.data.custoMmerData.leadData.data.customer_status ===
        'partially_confirmed'
          ? true
          : false
      " value="partially_confirmed">Partially Confirmed</mat-radio-button>
    <mat-radio-button [checked]="
        this.data.custoMmerData.leadData.data.customer_status ===
        'yet_to_be_filtered'
          ? true
          : false
      " value="yet_to_be_filtered">Yet to be Confirmed</mat-radio-button>
    <mat-radio-button [checked]="
        this.data.custoMmerData.leadData.data.customer_status === 'not_sure'
          ? true
          : false
      " value="not_sure">Not Sure</mat-radio-button>
  </mat-radio-group>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button class="buttonWidth" [mat-dialog-close]="true" color="primary"
      (click)="onClickSave(data.custoMmerData.leadData.data.id)" cdkFocusInitial>
      Save
    </button>
  </mat-dialog-actions>
</div>