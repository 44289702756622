<div style="height: 5px; width: 100%;">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
</div>
<form [formGroup]="mortageForm" class="container">
  <div fxLayout="row wrap" fxLayoutGap="10px">
    <h2 fxFlex class="pageTitle">Mortage List ({{totalCount}})</h2>
    <mat-form-field fxFlex="25">
      <mat-label>Status</mat-label>
      <mat-select formControlName="status" (selectionChange)="pageIndexHandler()">
        <mat-option *ngFor="let statusColumn of statusColumn" [value]="statusColumn.value">
          {{statusColumn.viewValue}}
        </mat-option>
      </mat-select>
      <button matSuffix mat-icon-button aria-label="clear" (click)="clearField('status');$event.stopPropagation();">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field fxFlex="24">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate formControlName="start_date" (focus)="picker.open()" (dateChange)="pageIndexHandler()"
          placeholder="Start date">
        <input matEndDate formControlName="end_date" (focus)="picker.open()" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
      <button matSuffix mat-icon-button aria-label="clear"
        (click)="clearField('start_date');clearField('end_date');$event.stopPropagation();">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field fxFlex="25">
      <mat-icon matSuffix>search</mat-icon>
      <mat-label>Search</mat-label>
      <input matInput (keyup)="pageIndexHandler()" formControlName="keyword" placeholder="Search" #input>
      <button matSuffix mat-icon-button aria-label="clear" (click)="clearField('keyword');$event.stopPropagation();">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <div>
      <button mat-raised-button color="primary" class="newButtonWidth" (click)="goToCreate()">
        <mat-icon>add</mat-icon> Add New
      </button>
    </div>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">

      <mat-header-row class="headerCustom" *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let row" [matTooltip]="row.name && row.name.length > 20 ? row.name: ''">
          <span class="truncate-text">{{row.name |titlecase}} </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="PropertyAddress">
        <mat-header-cell *matHeaderCellDef> Property Address </mat-header-cell>
        <mat-cell *matCellDef="let row"
          [matTooltip]="row.property_address && row.property_address.length > 20 ? row.property_address: ''">
          <span class="truncate-text"> {{row.property_address |titlecase}} </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Agent">
        <mat-header-cell *matHeaderCellDef> Agent </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.agent |titlecase}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="MTG Amount">
        <mat-header-cell *matHeaderCellDef> MTG Amount </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.mtg_amount}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Mine">
        <mat-header-cell *matHeaderCellDef> Mine </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.mine}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Amount">
        <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.amount}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Vale">
        <mat-header-cell *matHeaderCellDef> Vale </mat-header-cell>
        <mat-cell *matCellDef="let row" [matTooltip]="row.vale && row.vale.length > 20 ? row.vale: ''">
          <span class="truncate-text"> {{row.vale}} </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Notes">
        <mat-header-cell *matHeaderCellDef> Notes </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-icon class="matNoteIcon" matTooltip="{{row.notes}}" matTooltipClass="tooltip" matTooltipPosition="below"
            aria-hidden="false" aria-label="Example home icon">insert_comment</mat-icon>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="closedData">
        <mat-header-cell *matHeaderCellDef> Closed Mortgage </mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.closed_date === null ? '': row.closed_date | date}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Status">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span *ngIf="row.status === 'in_progress' ">In progress</span>
          <span *ngIf="row.status === 'pending' ">Pending</span>
          <span *ngIf="row.status === 'completed' ">Completed</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="close File">
        <mat-header-cell *matHeaderCellDef> Closed File </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button mat-fab color="primary" class="editIconButton" (click)="closedMortage(row.id)">
            <mat-icon class="matEditIcon" aria-hidden="false" aria-label="Example home icon">close</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Action">
        <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button mat-fab color="primary" (click)="editMortage(row.id)" class="editIconButton">
            <mat-icon class="matEditIcon" aria-hidden="false" aria-label="Example home icon">edit</mat-icon>
          </button>&nbsp;
          <button mat-fab color="primary" (click)="deleteMortage(row.id)" class="editIconButton">
            <mat-icon class="matEditIcon" aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9">No data matching the filter "{{input.value}}"</td>
      </tr>

    </table>
    <mat-paginator [length]="totalCount" [pageSize]="10" [pageSizeOptions]="[10,25,100]"
      (page)="pageEvent = $event; paginationHandler($event)" showFirstLastButtons>
    </mat-paginator>
  </div>
</form>