<table style="padding: 30px;">
    <tbody>
        <tr *ngFor="let count of headerCount">
            <td *ngFor="let count of headerCount" width="500px">
                <ngx-skeleton-loader [theme]="{ 
                    'width': '200px'}" appearance="line" animation="pulse">
                </ngx-skeleton-loader>
            </td>
            <td>
                <ngx-skeleton-loader appearance="circle" animation="pulse">
                </ngx-skeleton-loader>
            </td>
            <td>
                <ngx-skeleton-loader appearance="circle" animation="pulse">
                </ngx-skeleton-loader>
            </td>
        </tr>
    </tbody>
</table>