import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateLeadsComponent } from './create-leads/create-leads.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './Components/login/login.component';
import { CreateCustomersComponent } from './Components/create-customers/create-customers.component';
import { LeadsComponent } from './leads/leads.component';
import { ReportComponent } from './Components/report/report.component';
import { FirstmeetingComponent } from './firstmeeting/firstmeeting.component';
import { SecondmeetingComponent } from './secondmeeting/secondmeeting.component';
import { FollowupComponent } from './followup/followup.component';
import { DeadleadComponent } from './deadlead/deadlead.component';
import { NotSureComponent } from './not-sure/not-sure.component';
import { YetToConfirmedComponent } from './yet-to-confirmed/yet-to-confirmed.component';
import { PartiallyConfirmedComponent } from './partially-confirmed/partially-confirmed.component';
import { CustomersListComponent } from './customers-list/customers-list.component';
import { ViewEditCustomerComponent } from './view-edit-customer/view-edit-customer.component';
import { NormalCustomerListComponent } from './normal-customer-list/normal-customer-list.component';
import { EventsListComponent } from './events-list/events-list.component';
import { MortageComponent } from './mortage/mortage.component';
import { MortageListComponent } from './mortage-list/mortage-list.component';
import { MortageEditComponent } from './mortage-edit/mortage-edit.component';
import { CreateRealestateComponent } from './create-realestate/create-realestate.component';
import { RealListComponent } from './real-list/real-list.component';
import { RealViewComponent } from './real-view/real-view.component';
import { ClosedCustomerComponent } from './closed-customer/closed-customer.component';
import { MortageClosedListComponent } from './mortage-closed-list/mortage-closed-list.component';
import { RealEstateClosedListComponent } from './real-estate-closed-list/real-estate-closed-list.component';

const routes: Routes = [
  { path: 'dashboard/cutomerStatus', pathMatch: 'full', component: NotSureComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/yet-to-be-confirmed', pathMatch: 'full', component: YetToConfirmedComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/partially-confirmed', pathMatch: 'full', component: PartiallyConfirmedComponent, canActivate: [AuthGuard] },
  { path: '', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'createleads', component: CreateLeadsComponent, canActivate: [AuthGuard] },
  { path: 'create-customer', component: CreateCustomersComponent, canActivate: [AuthGuard] },
  { path: 'vip-customer-list', component: CustomersListComponent, canActivate: [AuthGuard] },
  { path: 'leads', component: LeadsComponent, canActivate: [AuthGuard] },
  { path: 'report', component: ReportComponent, canActivate: [AuthGuard] },
  { path: 'first-meting', component: FirstmeetingComponent, canActivate: [AuthGuard] },
  { path: 'second-meeting', component: SecondmeetingComponent, canActivate: [AuthGuard] },
  { path: 'follow-up', component: FollowupComponent, canActivate: [AuthGuard] },
  { path: 'dead-lead', component: DeadleadComponent, canActivate: [AuthGuard] },
  { path: 'view-edit-customer/:customerId', component: ViewEditCustomerComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/customer-list', component: NormalCustomerListComponent, canActivate: [AuthGuard] },
  { path: 'event-list', component: EventsListComponent, canActivate: [AuthGuard] },
  { path: 'mortage', component: MortageComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/mortage-list', component: MortageListComponent, canActivate: [AuthGuard] },
  { path: 'mortage-list/morage-edit/:mortageId', component: MortageEditComponent, canActivate: [AuthGuard] },
  { path: 'real-estate', component: CreateRealestateComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/real-list', component: RealListComponent, canActivate: [AuthGuard] },
  { path: 'real-estate/:id', component: RealViewComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/closed-customer', component: ClosedCustomerComponent, canActivate: [AuthGuard] },
  { path: 'closed-mortage', component: MortageClosedListComponent, canActivate: [AuthGuard] },
  { path: 'closed-real-estate', component: RealEstateClosedListComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
