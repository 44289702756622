import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { DashboardService } from '../services/dashboard.service';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, ThemePalette} from '@angular/material/core';
import * as _moment from 'moment';
import * as moment from 'moment-timezone'
import { SnackBarService } from '../services/snack-bar.service';

@Component({
  selector: 'app-event-dialog',
  templateUrl: './event-dialog.component.html',
  styleUrls: ['./event-dialog.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class EventDialogComponent implements OnInit {
  createEventForm: FormGroup;

  public showSeconds = true;
  public disableMinute = false;
  public hideTime = false;
  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public touchUi = true;
  public enableMeridian = true;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  constructor(
    private eventService: DashboardService,
    private fb: FormBuilder,
    private snackBarService : SnackBarService) { }

  ngOnInit(): void {
    this.createEventForm = this.fb.group({
      summary: new FormControl(),
      description: new FormControl(),
      start: new FormControl(),
      end: new FormControl()
    })
  }

  createEvent() {
    const eventObject = {
      summary: this.createEventForm.value.summary,
      start: this.createEventForm.value.start ? new Date(moment(this.createEventForm.value.start).format('YYYY-MM-DD[T]HH:mm:ss')):"",
      end: this.createEventForm.value.end ? new Date(moment(this.createEventForm.value.end).format('YYYY-MM-DD[T]HH:mm:ss')):"",
      description: this.createEventForm.value.description
    }
    this.eventService.createCalanderEvent(eventObject).subscribe((response)=> {
      if(response) {
        this.snackBarService.openSnackBar(response.message, 'success-class');
        window.location.reload();
      }
    })
  }

}
