<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
    {{data.message}}
</div>
<br />
<mat-form-field class="full-width" *ngIf="!data.estateNumber">
    <mat-label>Estate Number</mat-label>
    <input matInput [formControl]="estateNumber" required>
</mat-form-field>
<div mat-dialog-actions align="end">
    <button mat-raised-button (click)="onCancel()">Close</button>
    <button mat-raised-button style="color: white;" [disabled]="!estateNumber.valid" (click)="onConfirm()"
        color="primary">Confirm</button>
</div>