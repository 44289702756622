<!-- Loader -->
<div style="height: 5px; width: 100%;">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
</div>

<!-- Content -->
<div fxLayout="row" class="sub-container" fxLayoutAlign="space-between end">
  <div>
    <h2 class="pageTitle">Customer List ({{paginationData?.total}})</h2>
  </div>
  <div>
    <button mat-raised-button color="primary" class="newButtonWidth" [matTooltip]="'Create New Customer'"
      (click)="goToCreate()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>

<!-- Table -->
<div fxLayout="column" [formGroup]="normalCustomerForm" class="content mat-elevation-z8">
  <div fxLayout="row" fxLayoutGap="20px">
    <div fxFlex="20">
      <mat-form-field class="full-width">
        <mat-icon matSuffix>search</mat-icon>
        <mat-label>Search</mat-label>
        <input matInput formControlName="keyword" (keyup)="pageIndexHandler()" #input>
        <button matSuffix mat-icon-button aria-label="clear" (click)="clearField('keyword');$event.stopPropagation();">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div fxFlex="20">
      <mat-form-field class="full-width">
        <mat-label>Filter By Agency</mat-label>
        <mat-select formControlName="agency" autocomplete="off">
          <mat-option *ngFor="let agency of agencyList" [value]="agency.value">
            {{agency.viewValue}}
          </mat-option>
        </mat-select>
        <button matSuffix mat-icon-button aria-label="clear" (click)="clearField('agency');$event.stopPropagation();">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div fxFlex="20">
      <mat-form-field class="full-width">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate formControlName="start_date" (focus)="picker.open()" (dateChange)="pageIndexHandler()"
            placeholder="Start date">
          <input matEndDate formControlName="end_date" (focus)="picker.open()" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <button matSuffix mat-icon-button aria-label="clear"
          (click)="clearField('start_date');clearField('end_date');$event.stopPropagation();">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div fxFlex>
      <mat-paginator [length]="paginationData?.total" [pageSize]="paginationData?.data?.per_page"
        [pageSizeOptions]="[10,25,100]" (page)="pageEvent = $event; paginationHandler($event)" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>

  <div class="table-container">
    <table mat-table [dataSource]="dataSource">

      <tr mat-header-row class="headerCustom" *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <ng-container matColumnDef="surname">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <!-- matTooltip="{{row.surname.length > 12 ? row.surname:'' }}" -->
        <td mat-cell *matCellDef="let row" matTooltip="{{row.surname.length > 12 ? row.surname:'' }}"
          class="wrapTextcls"> {{row.surname
          != null ? row.surname : '' |titlecase}}
          {{row.given_names
          != null ? row.given_names : '' |titlecase}}
        </td>
      </ng-container>

      <ng-container matColumnDef="email_address">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let row" matTooltip="{{row.email_address.length > 15 ? row.email_address:'' }}">
          {{row.email_address !=null ? row.email_address :'' |titlecase}} </td>
      </ng-container>

      <ng-container matColumnDef="telephone_cell">
        <th mat-header-cell *matHeaderCellDef> Phone </th>
        <td mat-cell *matCellDef="let row">
          {{transform(row.telephone_cell)}}
        </td>
      </ng-container>

      <ng-container matColumnDef="payment_follow_up">
        <th mat-header-cell *matHeaderCellDef class="borderRight"> Payment Followup </th>
        <td mat-cell *matCellDef="let row" class="borderRight"> {{row.payment_followup != null ? row.payment_followup:''
          |titlecase}} </td>
      </ng-container>

      <ng-container matColumnDef="SignUp_Date">
        <th mat-header-cell *matHeaderCellDef> SignUp Date </th>
        <td mat-cell *matCellDef="let row">
          <mat-form-field style="width: 90%;">
            <mat-label></mat-label>
            <input matInput [matDatepicker]="picker" [value]="dateFormat(row.signup_date)"
              (dateChange)="dateUpdate(row.customer_id, $event)" (click)="picker.open()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="Estate_Number">
        <th mat-header-cell *matHeaderCellDef class="borderRight">Estate Number</th>
        <td mat-cell *matCellDef="let row" class="borderRight">
          {{row.estate_number}}
          <mat-form-field style="padding: 5px;" *ngIf="!row.estate_number" style="width: 90%;">
            <input matInput placeholder="Estate Number" (blur)="addEstateNumber(row.customer_id, $event)">
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="schedule_a_meeting">
        <th mat-header-cell *matHeaderCellDef>Schedule a Meeting</th>
        <td mat-cell *matCellDef="let row">
          <button mat-fab color="primary" class="editIconButton">
            <mat-icon class="matEditIcon" aria-hidden="false" aria-label="Example home icon"
              (click)="openDialog(row.surname)">today</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="upload">
        <th mat-header-cell *matHeaderCellDef>Attachment</th>
        <td mat-cell *matCellDef="let row">
          <button mat-fab color="primary" (click)="fileUpload(row.customer_id)" class="editIconButton">
            <mat-icon class="matEditIcon" aria-hidden="false" aria-label="Example home icon">attachment</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="First_Counselling_Date">
        <th mat-header-cell *matHeaderCellDef> First Counselling Date </th>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="row.completed_date === null" style="vertical-align: middle;">{{row.first_counselling_date |
            date}}</span>
          <span *ngIf="row.completed_date != null" style="vertical-align: middle;">{{row.completed_date |
            date}}</span>&nbsp;&nbsp;
          <button *ngIf="row.completed_date === null && row.first_counselling_date"
            (click)="getConfirm(row.customer_id)" mat-icon-button>
            <mat-icon aria-hidden="false" style="color: red;" aria-label="Example home icon">check_circle</mat-icon>
          </button>
          <button [disabled]="row.completed_date != null" *ngIf="row.completed_date != null"
            (click)="getConfirm(row.customer_id)" mat-icon-button>
            <mat-icon aria-hidden="false" style="color: green;" aria-label="Example home icon">check_circle</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="Second_Counselling_Date">
        <th mat-header-cell *matHeaderCellDef> Second Counselling Date </th>
        <td mat-cell *matCellDef="let row">
          <span style="vertical-align: middle;" *ngIf="row.second_completed_date === null">{{row.second_counselling_date
            | date}}</span>
          <span *ngIf="row.second_completed_date != null" style="vertical-align: middle;">{{row.second_completed_date
            |
            date}}</span>&nbsp;&nbsp;
          <button *ngIf="row.second_counselling_date && row.second_completed_date === null" mat-icon-button>
            <mat-icon (click)="getSeconddateConfirm(row.customer_id)" aria-hidden="false" aria-label="Example home icon"
              style="color: red;">check_circle</mat-icon>
          </button>
          <button [disabled]="row.second_completed_date != null" *ngIf="row.second_completed_date != null"
            (click)="getSeconddateConfirm(row.customer_id)" mat-icon-button>
            <mat-icon aria-hidden="false" style="color: green;" aria-label="Example home icon">check_circle</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="Notes">
        <th mat-header-cell *matHeaderCellDef> Note </th>
        <td mat-cell *matCellDef="let row">
          <button mat-fab color="primary" (click)="openDialog1(row.note)" class="editIconButton" [disabled]="!row.note">
            <mat-icon class="matNoteIcon matEditIcon" matTooltip="{{row.note}}" matTooltipClass="tooltip"
              matTooltipPosition="below" aria-hidden="false" aria-label="Example home icon">insert_comment</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="Download_DOC">
        <th mat-header-cell *matHeaderCellDef>Download DOC</th>
        <td mat-cell *matCellDef="let row">
          <button mat-fab color="primary" [disabled]="!row.document" (click)="downloadFile(row.customer_id)"
            class="editIconButton">
            <mat-icon class="matEditIcon" aria-hidden="false" aria-label="Example home icon">cloud_download</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="PDF_Dowload">
        <th mat-header-cell *matHeaderCellDef>PDF Download</th>
        <td mat-cell *matCellDef="let row">
          <button mat-fab color="primary" class="editIconButton" (click)="downloadPdf(row.customer_id)">
            <mat-icon class="matEditIcon" aria-hidden="false" aria-label="Example home icon">picture_as_pdf</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="Closed_Customer">
        <th mat-header-cell *matHeaderCellDef>Closed Customer</th>
        <td mat-cell *matCellDef="let row">
          <button mat-fab color="primary" class="editIconButton" (click)="closedcustomer(row)">
            <mat-icon class="matEditIcon" aria-hidden="false" aria-label="Example home icon">close</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let row" matTooltip="{{row.email_address.length > 15 ? row.email_address:'' }}">
          {{row.status !=null ? row.status :'' |titlecase}} </td>
      </ng-container>

      <!-- <ng-container matColumnDef="star" stickyEnd>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <mat-icon>more_vert</mat-icon>
        </td>
      </ng-container> -->

      <ng-container matColumnDef="Action" stickyEnd>
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let row">
          <button mat-fab color="primary" class="editIconButton">
            <mat-icon class="matEditIcon" aria-hidden="false" aria-label="Example home icon"
              (click)="editCustomer(row.customer_id)">edit</mat-icon>
          </button>&nbsp;
          <button mat-fab color="primary" class="editIconButton">
            <mat-icon class="matEditIcon" aria-hidden="false" (click)="deleteCustomerById(row.customer_id)"
              aria-label="Example home icon">delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="14">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>
  </div>
</div>