import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MaterialModule } from './material.module';
import { MainNavComponent } from './main-nav/main-nav.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CreateLeadsComponent } from './create-leads/create-leads.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EventDialogComponent } from './event-dialog/event-dialog.component';
import { LoginService } from './services/login.service';
import { AuthGuardService } from './services/auth-guard.service';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { LoginComponent } from './Components/login/login.component';
import { SnackBarComponent } from './Components/snack-bar/snack-bar.component';
import { SnackBarService } from './services/snack-bar.service';
import { CreateCustomersComponent } from './Components/create-customers/create-customers.component';
import { LeadsComponent } from './leads/leads.component';
import { RegistrationComponent } from './Components/registration/registration.component';
import { ReportComponent } from './Components/report/report.component';
import { TokenInterceptor } from './services/token.interceptor';
import { LeadService } from './services/lead.service';
import { RealstateService } from './services/realstate.service';
import { ReportsService } from './services/reports.service';
import { FirstmeetingComponent } from './firstmeeting/firstmeeting.component';
import { SecondmeetingComponent } from './secondmeeting/secondmeeting.component';
import { FollowupComponent } from './followup/followup.component';
import { DeadleadComponent } from './deadlead/deadlead.component';
import { FirstmeetingpopupComponent } from './firstmeetingpopup/firstmeetingpopup.component';
import { SecondMeetingpopupComponent } from './second-meetingpopup/second-meetingpopup.component';
import { EventEmitterService } from './services/event-emitter.service';
import { FollowupPopupComponent } from './followup-popup/followup-popup.component';
import { DeadleadPopupComponent } from './deadlead-popup/deadlead-popup.component';
import { LeadPopupComponent } from './lead-popup/lead-popup.component';
import { DashboardService } from './services/dashboard.service';
import { NotSureComponent } from './not-sure/not-sure.component';
import { PartiallyConfirmedComponent } from './partially-confirmed/partially-confirmed.component';
import { YetToConfirmedComponent } from './yet-to-confirmed/yet-to-confirmed.component';
import { CustomerServiceService } from './services/customer-service.service';
import { CustomersListComponent } from './customers-list/customers-list.component';
import { ViewEditCustomerComponent } from './view-edit-customer/view-edit-customer.component';
import { CustomerAddeventModelComponent } from './customer-addevent-model/customer-addevent-model.component';
import { NormalCustomerListComponent } from './normal-customer-list/normal-customer-list.component';
import { EventsListComponent } from './events-list/events-list.component';
import { PartiallyConfirmedModelComponent } from './customer-status-model/partially-confirmed-model.component';
import { ViewEventModelComponent } from './view-event-model/view-event-model.component';
import { ConfirmationModalComponent } from './shared/confirmation-modal/confirmation-modal.component';
import { FileUploaderComponent } from './shared/file-uploader/file-uploader.component';
import { MortageComponent } from './mortage/mortage.component';
import { MortageListComponent } from './mortage-list/mortage-list.component';
import { MortageService } from './services/mortage.service';
import { MortageEditComponent } from './mortage-edit/mortage-edit.component';
import { ReportEditComponent } from './report-edit/report-edit.component';
import { DownloadModelComponent } from './download-model/download-model.component';
import { CreateRealestateComponent } from './create-realestate/create-realestate.component';
import { RealListComponent } from './real-list/real-list.component';
import { RealViewComponent } from './real-view/real-view.component';
import { ClosedCustomerComponent } from './closed-customer/closed-customer.component';
import { DatePipe } from '@angular/common';
import { MortageClosedListComponent } from './mortage-closed-list/mortage-closed-list.component';
import { FileclosedConfirmComponent } from './shared/fileclosed-confirm/fileclosed-confirm.component';
import { ClosedCustomerModelComponent } from './closed-customer-model/closed-customer-model.component';
import { RealEstateClosedListComponent } from './real-estate-closed-list/real-estate-closed-list.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TableSkeletonComponent } from './shared/table-skeleton/table-skeleton.component';
import { ViewDialogComponent } from './view-dialog/view-dialog.component';
FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin
]);


@NgModule({
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FullCalendarModule,
    NgxSkeletonLoaderModule,
    MaterialModule
  ],
  declarations: [
    AppComponent,
    MainNavComponent,
    CreateLeadsComponent,
    DashboardComponent,
    EventDialogComponent,
    LoginComponent,
    RegistrationComponent,
    SnackBarComponent,
    CreateCustomersComponent,
    LeadsComponent,
    ReportComponent,
    FirstmeetingComponent,
    SecondmeetingComponent,
    FollowupComponent,
    DeadleadComponent,
    FirstmeetingpopupComponent,
    SecondMeetingpopupComponent,
    FollowupPopupComponent,
    DeadleadPopupComponent,
    LeadPopupComponent,
    NotSureComponent,
    PartiallyConfirmedComponent,
    YetToConfirmedComponent,
    CustomersListComponent,
    ViewEditCustomerComponent,
    CustomerAddeventModelComponent,
    NormalCustomerListComponent,
    EventsListComponent,
    PartiallyConfirmedModelComponent,
    ViewEventModelComponent,
    ConfirmationModalComponent,
    FileUploaderComponent,
    MortageComponent,
    MortageListComponent,
    MortageEditComponent,
    ReportEditComponent,
    DownloadModelComponent,
    CreateRealestateComponent,
    RealListComponent,
    RealViewComponent,
    ClosedCustomerComponent,
    MortageClosedListComponent,
    FileclosedConfirmComponent,
    ClosedCustomerModelComponent,
    RealEstateClosedListComponent,
    TableSkeletonComponent,
    ViewDialogComponent,
  ],
  entryComponents: [
    EventDialogComponent,
    RegistrationComponent,
    SnackBarComponent,
    FirstmeetingpopupComponent,
    SecondMeetingpopupComponent,
    FollowupPopupComponent,
    DeadleadPopupComponent,
    LeadPopupComponent,
    CustomerAddeventModelComponent,
    PartiallyConfirmedModelComponent,
    ViewEventModelComponent,
    ConfirmationModalComponent,
    FileUploaderComponent,
    MortageEditComponent,
    ReportEditComponent,
    DownloadModelComponent,
    FileclosedConfirmComponent
  ],
  providers: [
    LoginService,
    AuthGuardService,
    SnackBarService,
    EventEmitterService,
    LeadService,
    DashboardService,
    CustomerServiceService,
    MortageService,
    ReportsService,
    RealstateService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
