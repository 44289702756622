import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RealstateService } from '../services/realstate.service';
import * as moment from 'moment';
import { SnackBarService } from '../services/snack-bar.service';

@Component({
  selector: 'app-real-view',
  templateUrl: './real-view.component.html',
  styleUrls: ['./real-view.component.css'],
})
export class RealViewComponent implements OnInit {
  createRealForm: FormGroup;
  additionalNotes: any[] = [];
  realId: number;
  statusColumn: any[] = [
    { value: 'in_progress', viewValue: 'In Progress' },
    { value: 'pending', viewValue: 'Pending' },
    { value: 'completed', viewValue: 'Completed' },
  ];

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private realstateService: RealstateService,
    private snackBarService: SnackBarService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const realId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.realId = realId;
    this.realStateFormBuild();
    this.read(realId);
  }

  realStateFormBuild() {
    this.createRealForm = this.fb.group({
      name: new FormControl(),
      phone: new FormControl(),
      referral_source: new FormControl(),
      area: new FormControl(),
      notes: new FormControl(),
      my_commission: new FormControl(),
      closing_date: new FormControl(),
      update: new FormControl(),
      id: '',
      notesArray: this.fb.array([this.addNotesForm()]),
      status: '',
    });
  }

  addNotesForm(): FormGroup {
    return this.fb.group({
      notes: '',
      estate_id: this.realId,
    });
  }

  addNotesRow(): void {
    (<FormArray>this.createRealForm.get('notesArray')).push(
      this.addNotesForm()
    );
  }

  read(realId) {
    this.realstateService.read(realId).subscribe((response) => {
      if (response) {
        this.createRealForm.patchValue(response.data);
        this.additionalNotes = response.data.additional_notes;
      }
    });
  }

  update() {
    let object = {
      name: this.createRealForm.value.name,
      phone: this.createRealForm.value.phone,
      referral_source: this.createRealForm.value.referral_source,
      area: this.createRealForm.value.area,
      status: this.createRealForm.value.status,
      my_commission: this.createRealForm.value.my_commission,
      closing_date:
        new Date(
          moment(this.createRealForm.value.closing_date).format(
            'YYYY-MM-DD[T]HH:mm:ss'
          )
        ).getFullYear() +
        '-' +
        (new Date(
          moment(this.createRealForm.value.closing_date).format(
            'YYYY-MM-DD[T]HH:mm:ss'
          )
        ).getMonth() +
          1) +
        '-' +
        new Date(
          moment(this.createRealForm.value.closing_date).format(
            'YYYY-MM-DD[T]HH:mm:ss'
          )
        ).getDate(),
      update: this.createRealForm.value.update,
      notes: this.createRealForm.value.notes,
      id: this.createRealForm.value.id,
      additional_notes: this.createRealForm.value.notesArray,
    };
    this.realstateService.update(object).subscribe((response) => {
      if (response) {
        this.snackBarService.openSnackBar(response.message, 'success-class');
        this.router.navigate(['/dashboard/real-list']);
      }
    });
  }
}
