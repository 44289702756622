import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data } from '@angular/router';
import { MortageService } from '../services/mortage.service';
import { SnackBarService } from '../services/snack-bar.service';
import * as moment from 'moment';
import { MortageGetData, StatusModel } from '../models/mortage';

@Component({
  selector: 'app-mortage-edit',
  templateUrl: './mortage-edit.component.html',
  styleUrls: ['./mortage-edit.component.css'],
})
export class MortageEditComponent implements OnInit {
  statusColumn: StatusModel[] = [
    { value: 'in_progress', viewValue: 'In Progress' },
    { value: 'pending', viewValue: 'Pending' },
    { value: 'completed', viewValue: 'Completed' },
  ];
  editMortageForm: FormGroup;
  mortgageData: MortageGetData;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: Data,
    private fb: FormBuilder,
    private snackBarService: SnackBarService,
    private mortageService: MortageService,
    private dialogRef: MatDialogRef<MortageEditComponent>
  ) { }

  ngOnInit(): void {
    this.mortageEditFormBuild();
    this.getMortageBuID(this.data.mortageId);
  }

  mortageEditFormBuild(): void {
    this.editMortageForm = this.fb.group({
      name: '',
      property_address: '',
      agent: '',
      vale: '',
      mtg_amount: '',
      mine: '',
      amount: '',
      notes: '',
      closed_date: null,
      status: '',
      notesArray: this.fb.array([this.addNotesForm()]),
    });
  }

  addNotesForm(): FormGroup {
    return this.fb.group({
      notes: '',
      mortgage_id: this.data.mortageId,
    });
  }

  addNotesRow(): void {
    (this.editMortageForm.get('notesArray') as FormArray).push(
      this.addNotesForm()
    );
  }

  getMortageBuID(id: number): void {
    this.mortageService.getMortageById(id).subscribe((response) => {
      if (response) {
        this.mortgageData = response.data.additional_notes;
        this.editMortageForm.patchValue(response.data);
      }
    });
  }

  submitData(): void {
    const object: any = {
      moragteId: this.data.mortageId,
      name: this.editMortageForm.get('name').value,
      property_address: this.editMortageForm.get('property_address').value,
      agent: this.editMortageForm.get('agent').value,
      vale: this.editMortageForm.get('vale').value,
      mtg_amount: this.editMortageForm.get('mtg_amount').value,
      mine: this.editMortageForm.get('mine').value,
      amount: this.editMortageForm.get('amount').value,
      status: this.editMortageForm.get('status').value,
      notes: this.editMortageForm.get('notes').value,
      closed_date:
        new Date(
          moment(this.editMortageForm.get('closed_date').value).format(
            'YYYY-MM-DD[T]HH:mm:ss'
          )
        ).getFullYear() +
        '-' +
        (new Date(
          moment(this.editMortageForm.get('closed_date').value).format(
            'YYYY-MM-DD[T]HH:mm:ss'
          )
        ).getMonth() +
          1) +
        '-' +
        new Date(
          moment(this.editMortageForm.get('closed_date').value).format(
            'YYYY-MM-DD[T]HH:mm:ss'
          )
        ).getDate(),
      additional_notes: this.editMortageForm.value.notesArray,
    };
    this.mortageService.editMortageData(object).subscribe((response) => {
      if (response) {
        this.snackBarService.openSnackBar(response.message, 'success-class');
        this.dialogRef.close(true);
      }
    });
  }

  dialogClose(): void {
    this.dialogRef.close(false);
  }
}
