import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MortageEditComponent } from '../mortage-edit/mortage-edit.component';
import { MortageService } from '../services/mortage.service';
import { SnackBarService } from '../services/snack-bar.service';
import { ConfirmationModalComponent } from '../shared/confirmation-modal/confirmation-modal.component';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FileclosedConfirmComponent } from '../shared/fileclosed-confirm/fileclosed-confirm.component';

@Component({
  selector: 'app-mortage-list',
  templateUrl: './mortage-list.component.html',
  styleUrls: ['./mortage-list.component.css'],
})
export class MortageListComponent implements OnInit {
  statusColumn: any[] = [
    { value: '', viewValue: 'All' },
    { value: 'in_progress', viewValue: 'In Progress' },
    { value: 'pending', viewValue: 'Pending' },
    { value: 'completed', viewValue: 'Completed' },
  ];
  mortageForm: FormGroup;
  isLoading: boolean;
  pageEvent: PageEvent;
  dataSource: any = null;
  displayedColumns: string[] = [
    'name',
    'PropertyAddress',
    'Agent',
    'MTG Amount',
    'Mine',
    'Amount',
    'Vale',
    'Notes',
    'closedData',
    'Status',
    'close File',
    'Action',
  ];
  searchKey: string;
  totalCount: number;
  page = 1;
  pageLimit = 10;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    private mortageService: MortageService,
    public dialog: MatDialog,
    private snackBarService: SnackBarService,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.mortageFormBuild();
    this.getMortageData();
    this.filters();
  }

  mortageFormBuild(): void {
    this.mortageForm = this.fb.group({
      page: 1,
      pageLimit: 10,
      keyword: '',
      status: '',
      start_date: '',
      end_date: ''
    });
  }

  getMortageData(): void {
    this.isLoading = true;
    this.mortageService.getMortageData(this.mortageForm.value).subscribe((response) => {
      this.dataSource = response.body.data.data;
      this.totalCount = response.body.data.total;
      this.isLoading = false;
    });
  }

  filters(): void {
    this.mortageForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe((response) => {
        this.getMortageData();
      });
  }

  deleteMortage(id): void {
    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          title: 'Confirm',
          message: 'Are you Sure You want to delete this Mortage',
        },
      })
      .afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.mortageService.deleteMortage(id).subscribe((response) => {
            this.snackBarService.openSnackBar(
              response.message,
              'success-class'
            );
            this.mortageFormBuild();
            this.mortageForm.get('page').setValue(1);
            this.mortageForm.get('keyword').setValue('');
            this.mortageForm.get('status').setValue('');
            this.getMortageData();
          });
        }
      });
  }

  paginationHandler(event: PageEvent): void {
    this.mortageForm.get('page').setValue(event.pageIndex + 1);
    this.mortageForm.get('pageLimit').setValue(event.pageSize);
  }

  pageIndexHandler(): void {
    this.paginator.pageIndex = 0;
    this.mortageForm.get('page').setValue(1);
  }

  goToCreate(): void {
    this.router.navigate(['mortage']);
  }

  editMortage(id): void {
    const dialogRef = this.dialog
      .open(MortageEditComponent, {
        width: '100%',
        data: {
          mortageId: id,
        },
      })
      .afterClosed()
      .subscribe((confirmation: boolean) => {
        if (confirmation) {
          this.mortageFormBuild();
          this.mortageForm.get('page').setValue(1);
          this.mortageForm.get('keyword').setValue('');
          this.mortageForm.get('status').setValue('all');
          this.getMortageData();
        }
      });
  }

  clearField(controller: string): void {
    this.mortageForm.get(controller).setValue('');
  }

  closedMortage(mortgageId: number): void {
    this.dialog
      .open(FileclosedConfirmComponent, {
        data: {
          title: 'Confirm',
          message: 'Are you Sure You want to Close This file',
          confirmStatus: true,
        },
      })
      .afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          const colsedPram = {
            id: mortgageId
          };
          this.mortageService.closedMortgage(colsedPram).subscribe((res) => {
            if (res) {
              this.getMortageData();
              this.snackBarService.openSnackBar(res.message, 'success-class');
            }
          });
        }
      });
  }

}
