<!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->
<div class="container" fxLayout fxLayoutAlign="center center" fxLayoutGap="25px" fxLayout.xs="column wrap"
    fxLayoutGap.xs="20px">
    <mat-card fxFlex="60" class="cardPadding">
        <mat-card-content>
            <h2 class="createLeadTitle">Mortgage</h2>
            <form [formGroup]="createMortageForm" fxLayoutAlign="stretch" fxLayout="column">
                <mat-form-field class="full-width">
                    <mat-label>Name</mat-label>
                    <input type="text" required formControlName="name" matInput autocomplete="off">
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>Property Address</mat-label>
                    <input formControlName="property_address" matInput>
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>Agent</mat-label>
                    <input formControlName="agent" matInput>
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>P.VALE</mat-label>
                    <input formControlName="vale" matInput>
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>MTG Amount</mat-label>
                    <input formControlName="mtg_amount" type="number" matInput>
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>Mine</mat-label>
                    <input formControlName="mine" type="number" matInput>
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>Amount</mat-label>
                    <input formControlName="amount" type="number" matInput>
                </mat-form-field>

                <mat-form-field fxFlex="25">
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status">
                        <mat-option *ngFor="let statusColumn of statusColumn" [value]="statusColumn.value">
                            {{statusColumn.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="full-width">
                    <mat-label>Notes</mat-label>
                    <input formControlName="notes" matInput>
                </mat-form-field>
                <div>
                    <button mat-flat-button type="submit" style="color: white;" (click)="moratgePostData()"
                        color="primary" [disabled]="!createMortageForm.valid">Submit</button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>