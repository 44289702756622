import { Component, Inject, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackBarService } from '../../services/snack-bar.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Data } from '@angular/router';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  forgotPasswordForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: Data,
    private loginService: LoginService,
    public dialogRef: MatDialogRef<RegistrationComponent>,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.forgotPasswordFormBuild();
  }

  forgotPasswordFormBuild(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: '',
      password: '',
      token: '',
      password_confirmation: ''
    });
  }

  requestPasswordChange(): void {
    const object = { email: this.forgotPasswordForm.get('email').value };
    this.loginService.requestPasswordChange(object).subscribe((response) => {
      if (response.token) {
        this.forgotPasswordForm.get('token').setValue(response.token);
      }
    }, (error) => {
      this.snackBarService.openSnackBar(error.error.message, 'error-class');
    });
  }

  resetPassword(): void {
    this.loginService.resetPassword(this.forgotPasswordForm.value).subscribe((response) => {
      this.snackBarService.openSnackBar(response.msg, 'success-class');
      this.onConfirm();
    });
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

}
