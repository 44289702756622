<h1 mat-dialog-title>Confirm <span>
    <button mat-mini-fab extended color="white" box class="mat-elevation-z0 popupClose" (click)="goToList()">
      <mat-icon>close</mat-icon>
    </button>
  </span></h1>
<div mat-dialog-content>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button color="primary" style="color: white;" (click)="downloadPdf()">Download PDF</button>
  <button mat-raised-button style="color: white;" (click)="sendViaEmail()" color="primary">Send Mail</button>
</div>