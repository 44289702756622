<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
  <mat-toolbar *ngIf="nav.visible" class="toolBar mat-elevation-z6">
    <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="sidenav.toggle()">
      <mat-icon aria-label="Side nav toggle icon" class="menuButton">menu</mat-icon>
    </button>
    <span class="example-spacer"></span>
    <button style="color: white;" mat-button (click)="logOut()">
      <mat-icon>exit_to_app</mat-icon> Logout
    </button>
  </mat-toolbar>
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav [mode]="mobileQuery.matches ? 'over' : 'over'" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56"
      #sidenav class="sidenav" [(opened)]="opened">
      <div class="logo">
        <a href=""><img [src]="'assets/images/logo.png'" alt="image" class=""></a>
      </div>
      <mat-nav-list class="p-0">
        <a mat-list-item routerLink="dashboard" (click)="sidenav.toggle()" routerLinkActive="active"
          class="saidNavContent"><img class="icon-style" src="assets/SVG/dashboard.svg">&nbsp;&nbsp;DASHBOARD</a>
        <mat-list-item (click)="customerShowSubmenu = !customerShowSubmenu" class="saidNavContent">
          <span class="full-width saidNavContent" *ngIf="customerisExpanded || customerisShowing"><img
              class="icon-style" src="assets/SVG/customer.svg">&nbsp;&nbsp;CUSTOMERS</span>
          <mat-icon class="menu-button" [ngClass]="{'rotated' : customerShowSubmenu}"
            *ngIf="customerisExpanded || customerisShowing">expand_more</mat-icon>
        </mat-list-item>
        <div class="submenu" [ngStyle]="customerSubmenuStyles()" [ngClass]="{'expanded' : customerShowSubmenu}"
          *ngIf="customerisShowing || customerisExpanded">
          <a mat-list-item routerLinkActive="active" (click)="sidenav.toggle()" routerLink="create-customer"
            class="saidNavContent">
            <mat-icon>group_add</mat-icon>&nbsp;Create Customer
          </a>
          <a mat-list-item routerLinkActive="active" (click)="sidenav.toggle()" routerLink="dashboard/customer-list"
            class="saidNavContent">
            <mat-icon>
              <mat-icon>group</mat-icon>
            </mat-icon>&nbsp;Customer List
          </a>
          <a mat-list-item routerLinkActive="active" (click)="sidenav.toggle()" routerLink="vip-customer-list"
            class="saidNavContent">
            <img class="icon-style" src="assets/SVG/vip.svg">&nbsp;&nbsp;VIP Customer
          </a>
          <a mat-list-item routerLinkActive="active" (click)="sidenav.toggle()" routerLink="dashboard/closed-customer"
            class="saidNavContent">
            <img class="icon-style" src="assets/SVG/closed.svg">&nbsp;&nbsp;Closed Customer
          </a>
        </div>

        <mat-list-item (click)="moratgeShowSUbmenu = !moratgeShowSUbmenu" class="saidNavContent">
          <span class="full-width saidNavContent" *ngIf="mortageIsExpanded || mortageIsShowing"><img class="icon-style"
              src="assets/SVG/mortgage.svg">&nbsp;&nbsp;MORTGAGE</span>
          <mat-icon class="menu-button" [ngClass]="{'rotated' : moratgeShowSUbmenu}"
            *ngIf="mortageIsExpanded || mortageIsShowing">expand_more</mat-icon>
        </mat-list-item>
        <div class="submenu" [ngStyle]="mortageSubmenuStyles()" [ngClass]="{'expanded' : moratgeShowSUbmenu}"
          *ngIf="mortageIsShowing || mortageIsExpanded">
          <a mat-list-item routerLink="mortage" routerLinkActive="active" (click)="sidenav.toggle()"
            class="saidNavContent"><img class="icon-style" src="assets/SVG/new-document.svg">&nbsp;&nbsp;Create
            Mortgage</a>
          <a mat-list-item routerLink="dashboard/mortage-list" routerLinkActive="active" (click)="sidenav.toggle()"
            class="saidNavContent"><img class="icon-style" src="assets/SVG/check-list.svg">&nbsp;&nbsp;Mortgage
            List</a>
          <a mat-list-item routerLink="closed-mortage" routerLinkActive="active" (click)="sidenav.toggle()"
            class="saidNavContent">
            <mat-icon>work_off</mat-icon>&nbsp;&nbsp;Closed
            Mortgage
          </a>
        </div>

        <mat-list-item (click)="realEstateShowSubmenu = !realEstateShowSubmenu" class="saidNavContent">
          <span class="full-width saidNavContent" *ngIf="realEstateIsExpanded || realEstateIsShowing">
            <img class="icon-style" src="assets/SVG/modern-house.svg">&nbsp;&nbsp;REAL ESTATE
          </span>
          <mat-icon class="menu-button" [ngClass]="{'rotated' : realEstateShowSubmenu}"
            *ngIf="realEstateIsExpanded || realEstateIsShowing">expand_more</mat-icon>
        </mat-list-item>
        <div class="submenu" [ngStyle]="realEstateSubmenuStyles()" [ngClass]="{'expanded' : realEstateShowSubmenu}"
          *ngIf="realEstateIsShowing || realEstateIsExpanded">
          <a mat-list-item routerLink="real-estate" routerLinkActive="active" (click)="sidenav.toggle()"
            class="saidNavContent">
            <img class="icon-style" src="assets/SVG/image.svg">&nbsp;&nbsp;Create
          </a>
          <a mat-list-item routerLink="dashboard/real-list" routerLinkActive="active" (click)="sidenav.toggle()"
            class="saidNavContent">
            <img class="icon-style" src="assets/SVG/land.svg">&nbsp;&nbsp;List
          </a>
          <a mat-list-item routerLink="closed-real-estate" routerLinkActive="active" (click)="sidenav.toggle()"
            class="saidNavContent">
            <mat-icon>work_off</mat-icon>&nbsp;&nbsp;Closed List
          </a>
        </div>

        <mat-list-item (click)="showSubmenu = !showSubmenu" class="saidNavContent">
          <span class="full-width saidNavContent" *ngIf="isExpanded || isShowing"><img class="icon-style"
              src="assets/SVG/bar-chart.svg">&nbsp;&nbsp;LEADS</span>
          <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenu}" *ngIf="isExpanded || isShowing">
            expand_more</mat-icon>
        </mat-list-item>
        <div class="submenu" [ngStyle]="styleObject()" [ngClass]="{'expanded' : showSubmenu}"
          *ngIf="isShowing || isExpanded">
          <a mat-list-item routerLinkActive="active" (click)="sidenav.toggle()" routerLink="createleads"
            class="saidNavContent"><img class="icon-style" src="assets/SVG/new-user.svg">&nbsp;&nbsp;Create Leads</a>
        </div>
        <div class="submenu" [ngStyle]="styleObject()" [ngClass]="{'expanded' : showSubmenu}"
          *ngIf="isShowing || isExpanded">
          <a mat-list-item routerLink="first-meting" (click)="sidenav.toggle()" routerLinkActive="active"
            class="saidNavContent"><img class="icon-style" src="assets/SVG/viber.svg">&nbsp;&nbsp;First Meeting</a>
        </div>
        <div class="submenu" [ngStyle]="styleObject()" [ngClass]="{'expanded' : showSubmenu}"
          *ngIf="isShowing || isExpanded">
          <a mat-list-item routerLink="dead-lead" (click)="sidenav.toggle()" routerLinkActive="active"
            class="saidNavContent"><img class="icon-style" src="assets/SVG/deadline.svg">&nbsp;&nbsp;Dead Lead</a>
        </div>
        <div class="submenu" [ngStyle]="styleObject()" [ngClass]="{'expanded' : showSubmenu}"
          *ngIf="isShowing || isExpanded">
          <a mat-list-item routerLinkActive="active" (click)="sidenav.toggle()" routerLink="leads"
            class="saidNavContent"><img class="icon-style" src="assets/SVG/user-list.svg">&nbsp;&nbsp;Leads</a>
        </div>
        <a mat-list-item routerLink="report" routerLinkActive="active" (click)="sidenav.toggle()"
          class="saidNavContent"><img class="icon-style" src="assets/SVG/profit-report.svg">&nbsp;&nbsp;REPORT</a>
        <a mat-list-item routerLink="event-list" routerLinkActive="active" (click)="sidenav.toggle()"
          class="saidNavContent"><img class="icon-style" src="assets/SVG/correct.svg">&nbsp;&nbsp;EVENTS</a>

        <!-- <a mat-list-item href="#" routerLinkActive="active" (click)="sidenav.toggle()" class="saidNavContent"><img
            class="icon-style" src="assets/SVG/settings.svg">&nbsp;&nbsp;SETTINGS</a> -->
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>