import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class RealstateService {
  constructor(
    private http: HttpClient,
    private datePipe: DatePipe
  ) { }

  create(object): Observable<any> {
    return this.http.post(`${environment.BASE_URL}/realestate/create`, object);
  }

  list(paginationData?): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}/realestate/list?&end_date=${this.datePipe.transform(paginationData.end_date, 'yyy-MM-dd')}&start_date=${this.datePipe.transform(paginationData.start_date, 'yyy-MM-dd')}&page=${paginationData.page}&per_page=${paginationData.pageLimit}&keyword=${paginationData.keyword}&status=${paginationData.status}`
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${environment.BASE_URL}/realestate/delete/${id}`);
  }

  read(realId): Observable<any> {
    return this.http.get(`${environment.BASE_URL}/realestate/view/${realId}`);
  }

  update(object): Observable<any> {
    return this.http.put(
      `${environment.BASE_URL}/realestate/update/${object.id}`,
      object
    );
  }

  closeRealEstate(object): Observable<any> {
    return this.http.post(`${environment.BASE_URL}/realestate/closerealestate`, object);
  }

  getClosedRealEstateList(object): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}/realestate/list?&start_date=${this.datePipe.transform(object.start_date, 'yyy-MM-dd')}&end_date=${this.datePipe.transform(object.end_date, 'yyy-MM-dd')}&page=${object.page}&per_page=${object.pageLimit}&status=${object.status}&description=${object.description}&record_status=99`
    );
  }
}
