import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ReportEditComponent } from 'src/app/report-edit/report-edit.component';
import { ReportsService } from 'src/app/services/reports.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
})
export class ReportComponent implements OnInit {
  reportForm: FormGroup;
  isLoading = false;
  displayedColumns: string[] = [
    'clientname',
    'emailId',
    'phoneNumber',
    'hm',
    'cpbk',
    'ESTATENUM',
    'REFSOURCE',
    'firstPaymentDate',
    'cpAmt',
    'signUpDate',
    'firstCounselling',
    'completed',
    'secondCounselling',
    'completedII',
    'Note',
    'star',
    'Action',
  ];
  dataSource = null;
  selectedValue: string;
  paginationData: any;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  startDate: string = '';
  endDate: string = '';
  constructor(
    private _httpClient: HttpClient,
    private fb: FormBuilder,
    private reportService: ReportsService,
    public dialog: MatDialog,
    private snackBarService: SnackBarService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.repostData();
  }

  reportFormBuild() {
    this.reportForm = this.fb.group({
      firstCounting: new FormControl(),
      secondCounting: new FormControl(),
    });
  }

  repostData() {
    this.reportService.getReportData().subscribe((response) => {
      if (response) {
        this.paginationData = response.data;
        this.isLoading = true;
        this.dataSource = response.data.data;
        this.isLoading = false;
      }
    });
  }

  onPaginateChange(event: PageEvent) {
    const paginationData = {
      page: event.pageIndex + 1,
      pageLimit: event.pageSize,
    };
    this.isLoading = true;
    this.reportService.getReportPaginationData(paginationData).subscribe((data) => {
      this.paginationData = data.data;
      this.dataSource = data.data.data;
      this.isLoading = false;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.reportService.getFilterdata(filterValue).subscribe((response) => {
      this.dataSource = response.data.data;
    });
  }

  downloadFile() {
    window.location.href = (`${environment.BASE_URL}/reportdownload?start_date=${this.startDate}&end_date=${this.endDate}`);
  }

  deleteEvent(id) {
    this.dialog.open(ConfirmationModalComponent, {
      data: {
        title: 'Confirm',
        message: 'Are you Sure You want to delete this Report'
      },
    }).afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.reportService.deleteReport(id).subscribe((response) => {
          if (response) {
            this.snackBarService.openSnackBar(response.message, 'success-class');
            this.repostData();
          }
        });
      }
    })
  }

  editReport(id) {
    const dialogRef = this.dialog.open(ReportEditComponent, {
      width: '100%',
      data: {
        id: id,
      },
    }).afterClosed().subscribe((confirmation: boolean) => {
      if (confirmation) {
        this.repostData();
      }
    });
  }

  reportFilterByDate() {
    let start_date = this.datePipe.transform(this.range.value.start, 'yyyy-MM-dd');
    let end_date = this.datePipe.transform(this.range.value.end, 'yyyy-MM-dd');
    this.startDate = start_date;
    this.endDate = end_date
    const reportFilterByDate = {
      start: start_date,
      end: end_date
    };
    this.reportService.getReportFilterByDate(reportFilterByDate).subscribe((response) => {
      this.dataSource = response.data.data;
    });
  }

  clearField(): void {
    this.range.get('start').setValue('')
    this.range.get('end').setValue('')
    this.repostData();
  }

}
