import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-skeleton',
  templateUrl: './table-skeleton.component.html',
  styleUrls: ['./table-skeleton.component.css']
})
export class TableSkeletonComponent implements OnInit {
  headerCount = [1, 2, 3, 4, 5];
  constructor() { }

  ngOnInit(): void {
  }

}
