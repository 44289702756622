import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { CustomerAddeventModelComponent } from '../customer-addevent-model/customer-addevent-model.component';
import { FollowupPopupComponent } from '../followup-popup/followup-popup.component';
import { EventEmitterService } from '../services/event-emitter.service';
import { LeadService } from '../services/lead.service';
import { SnackBarService } from '../services/snack-bar.service';
import { ConfirmationModalComponent } from '../shared/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-followup',
  templateUrl: './followup.component.html',
  styleUrls: ['./followup.component.css'],
})
export class FollowupComponent implements OnInit {
  dataSource: any = null;
  filterdata = null;
  displayedColumns: string[] = [
    'name',
    'email',
    'phone',
    'signup_date',
    'leads_status',
    'lead_summary',
    'schedule_a_meeting',
    'Action',
  ];
  isLoading = false;
  isShow = true;
  paginationData: any;
  pageEvent: PageEvent;

  constructor(
    private leadService: LeadService,
    public dialog: MatDialog,
    private eventEmitterService: EventEmitterService,
    private snackBarService: SnackBarService
  ) {
    this.eventEmitterService.listen().subscribe((item) => {
      this.initDataSource();
    });
  }

  ngOnInit(): void {
    this.initDataSource();
  }

  initDataSource() {
    this.isLoading = true;
    const leadStatusName = {
      meetingStatusname: 'follow_up',
    };
    this.leadService
      .getLeadlistByLeadstatus(leadStatusName)
      .subscribe((data: any) => {
        this.paginationData = data.data;
        this.dataSource = data.data.data;
        this.isLoading = false;
      });
  }

  onPaginateChange(event: PageEvent) {
    const paginationData = {
      page: event.pageIndex + 1,
      pageLimit: event.pageSize,
      meetingStaus: 'follow_up',
    };
    this.isLoading = true;
    this.leadService.paginationCall(paginationData).subscribe((data) => {
      this.paginationData = data.data;
      this.dataSource = data.data.data;
      this.isLoading = false;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (filterValue) {
      this.isLoading = true;
      let object = {
        filterValue: filterValue,
        leadStatus: 'follow_up'
      }
      this.leadService.firstmeetingFilter(object).subscribe((response) => {
        this.dataSource = response.data.data;
        this.isLoading = false;
      });
    }
    if (filterValue === '') { this.initDataSource(); }
  }

  editLeadStatus(id) {
    this.leadService.getLeadByID(id).subscribe((data) => {
      let editLeadData = {
        ishowFlag: this.isShow = true,
        leadData: data,
      };
      this.openDialog(editLeadData);
    });
  }

  deleteLead(id) {
    this.leadService.getLeadByID(id).subscribe((data) => {
      this.dialog.open(ConfirmationModalComponent, {
        data: {
          title: 'Confirm',
          message: 'Are you Sure You want to delete this Lead'
        },
      }).afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.leadService.deleteLeadById(id).subscribe((data) => {
            this.snackBarService.openSnackBar(data.message, 'success-class');
            if (data) {
              this.initDataSource();
            }
          });
        }
      })
    });
  }

  openDialog(data) {
    const dialogRef = this.dialog.open(FollowupPopupComponent, {
      width: '60%',
      height: '70%',
      data: {
        custoMmerData: data,
      },
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  openCreateEventDialog(data) {
    const dialogRef = this.dialog.open(CustomerAddeventModelComponent, {
      width: '34%',
      // height: '68%',
      data: {
        custoMmerData: data,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

}
