import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { LeadService } from '../services/lead.service';
import { MatDialog } from '@angular/material/dialog';
import { LeadPopupComponent } from '../lead-popup/lead-popup.component';
import { CustomerAddeventModelComponent } from '../customer-addevent-model/customer-addevent-model.component';
import { ConfirmationModalComponent } from '../shared/confirmation-modal/confirmation-modal.component';
import { SnackBarService } from '../services/snack-bar.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.css'],
})
export class LeadsComponent implements OnInit {
  leadList: any = null;
  filterdata = null;
  displayedColumns: string[] = [
    'name',
    'email',
    'phone',
    'signup_date',
    'leads_status',
    'lead_summary',
    'schedule_a_meeting',
    'Action',
  ];
  isLoading = false;
  isShow = true;
  paginationData: any;
  pageEvent: PageEvent;
  leadsForm: FormGroup;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    private route: Router,
    private leadService: LeadService,
    public dialog: MatDialog,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.leadsFormBuild();
    this.getLeadList();
    this.filters();
  }

  leadsFormBuild(): void {
    this.leadsForm = this.formBuilder.group({
      lead_status: '',
      per_page: 10,
      page: 1,
      start_date: '',
      end_date: '',
      keyword: ''
    });
  }

  getLeadList(): void {
    this.isLoading = true;
    this.leadService.getLeadsByStatus(this.leadsForm.value).subscribe((response) => {
      this.paginationData = response.body.data;
      this.leadList = response.body.data.data;
      this.isLoading = false;
    });
  }

  filters(): void {
    this.leadsForm.valueChanges.pipe(debounceTime(300)).subscribe((response) => {
      this.getLeadList();
    });
  }

  paginationHandler(event: PageEvent): void {
    this.leadsForm.get('page').setValue(event.pageIndex + 1);
    this.leadsForm.get('pageLimit').setValue(event.pageSize);
  }

  pageIndexHandler(): void {
    this.paginator.pageIndex = 0;
    this.leadsForm.get('page').setValue(1);
  }

  clearField(controller: string): void {
    this.leadsForm.controls[controller].setValue('');
  }

  editLeadStatus(id: number): void {
    this.leadService.getLeadByID(id).subscribe((data) => {
      const editLeadData = {
        ishowFlag: (this.isShow = true),
        leadData: data,
      };
      this.openDialog(editLeadData);
    });
  }

  deleteLead(id: number): void {
    this.leadService.getLeadByID(id).subscribe((data) => {
      this.dialog
        .open(ConfirmationModalComponent, {
          data: {
            title: 'Confirm',
            message: 'Are you Sure You want to delete this Lead',
          },
        })
        .afterClosed()
        .subscribe((confirmed: boolean) => {
          if (confirmed) {
            this.leadService.deleteLeadById(id).subscribe((response) => {
              this.snackBarService.openSnackBar(response.message, 'success-class');
              if (response) {
                this.getLeadList();
              }
            });
          }
        });
    });
  }

  openDialog(lead: { ishowFlag: boolean; leadData: any; }): void {
    const dialogRef = this.dialog.open(LeadPopupComponent, {
      width: '60%',
      height: '70%',
      data: {
        custoMmerData: lead,
      },
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  openCreateEventDialog(data: any): void {
    const dialogRef = this.dialog.open(CustomerAddeventModelComponent, {
      width: '35%',
      data: {
        custoMmerData: data,
      },
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  goToCreate(): void {
    this.route.navigate(['createleads']);
  }
}
