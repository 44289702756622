import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data } from '@angular/router';
import { ReportsService } from '../services/reports.service';
import { SnackBarService } from '../services/snack-bar.service';

@Component({
  selector: 'app-report-edit',
  templateUrl: './report-edit.component.html',
  styleUrls: ['./report-edit.component.css']
})
export class ReportEditComponent implements OnInit {
  editReportForm: FormGroup;

  public showSeconds = true;
  public disableMinute = false;
  public hideTime = true;
  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public touchUi = false;
  public enableMeridian = true;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: Data,
    private fb: FormBuilder,
    private snackBarService: SnackBarService,
    private dialogRef: MatDialogRef<ReportEditComponent>,
    private reportService: ReportsService
  ) { }

  ngOnInit(): void {
    this.reportEditFormBuild();
    this.reportById(this.data.id);
  }

  reportEditFormBuild() {
    this.editReportForm = this.fb.group({
      h_m: new FormControl(),
      cp_bk: new FormControl(),
      estate_num: new FormControl(),
      first_payment_date: new FormControl(),
      cp_amt: new FormControl(),
      ref_source: new FormControl(),
    });
  }

  reportById(reportId) {
    this.reportService.getReportById(reportId).subscribe((response)=>{
      this.editReportForm.patchValue(response.data);
    });
  }

  submitData() {
    let object = {
      id: this.data.id,
      editedValue: this.editReportForm.value
    }
    this.reportService.editReport(object).subscribe((response)=>{
      if(response) {
        this.dialogRef.close(true);
        this.snackBarService.openSnackBar(response.message, 'success-class');
      }
    });
  }

  dialogClose() {
    this.dialogRef.close(false);
  }

}
