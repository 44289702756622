<div style="height: 5px; width: 100%;">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
</div>
<form [formGroup]="deadLeadForm">
  <div class="container">
    <div fxLayout="row wrap" fxLayoutGap="10px">
      <h2 fxFlex class="pageTitle">Dead Lead</h2>
      <mat-form-field fxFlex="25">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate formControlName="start_date" (focus)="picker.open()" (dateChange)="pageIndexHandler()"
            placeholder="Start date">
          <input matEndDate formControlName="end_date" (focus)="picker.open()" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <button matSuffix mat-icon-button aria-label="clear"
          (click)="clearField('start_date');clearField('end_date');$event.stopPropagation();">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field fxFlex="25">
        <mat-icon matSuffix>search</mat-icon>
        <mat-label>Search</mat-label>
        <input matInput formControlName="keyword" (keyup)="pageIndexHandler()" placeholder="Ex. ium" #input>
        <button matSuffix mat-icon-button aria-label="clear" (click)="clearField('keyword');$event.stopPropagation();">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="deadLeadList">
        <tr mat-header-row class="headerCustom" *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" [hidden]="!row.leads_status"></tr>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let row"> {{row.name |titlecase}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let row"> {{row.email}} </td>
        </ng-container>

        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef> Phone </th>
          <td mat-cell *matCellDef="let row"> {{row.phone}} </td>
        </ng-container>

        <ng-container matColumnDef="signup_date">
          <th mat-header-cell *matHeaderCellDef> Signup Date </th>
          <td mat-cell *matCellDef="let row"> {{row.created_at | date}} </td>
        </ng-container>

        <ng-container matColumnDef="leads_status">
          <th mat-header-cell *matHeaderCellDef> Leads Status </th>
          <td mat-cell *matCellDef="let row"> {{row.leads_status == "dead_lead" ? 'Dead Lead': ''}} </td>
        </ng-container>

        <ng-container matColumnDef="lead_summary">
          <th mat-header-cell *matHeaderCellDef> Lead Summary </th>
          <td mat-cell *matCellDef="let row">
            <mat-icon class="matNoteIcon" matTooltip="{{row.lead_summary}}" matTooltipClass="tooltip"
              matTooltipPosition="below" aria-hidden="false" aria-label="Example home icon">insert_comment</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let row">
            <button mat-fab color="primary" class="editIconButton" (click)="editLeadStatus(row.id)">
              <mat-icon class="matEditIcon" aria-hidden="false" aria-label="Example home icon">edit</mat-icon>
            </button>&nbsp;
            <button mat-fab color="primary" class="editIconButton" (click)="deleteLead(row.id)">
              <mat-icon class="matEditIcon" aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="schedule_a_meeting">
          <th mat-header-cell *matHeaderCellDef>Schedule a Meeting</th>
          <td mat-cell *matCellDef="let row">
            <button mat-fab color="primary" class="editIconButton">
              <mat-icon class="matEditIcon" aria-hidden="false" aria-label="Example home icon"
                (click)="openCreateEventDialog(row.name)">today</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="8">No data matching the filter "{{input.value}}"</td>
        </tr>

      </table>
      <mat-paginator [length]="paginationData?.total" [pageSize]="deadLeadForm.value.per_page"
        [pageSizeOptions]="[10,25,100]" (page)="pageEvent = $event; paginationHandler($event)" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>

</form>