<div class="container">
  <div class="contact-form">
    <img src="/assets/images/logo.png" alt="image" class="avatar">
    <form>
      <mat-form-field class="emailFormField">
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]="loginDetails.email" class="loginStyle" name="email" autocomplete="off">
      </mat-form-field>
      <mat-form-field class="passwordField">
        <mat-label>Password</mat-label>
        <input matInput [(ngModel)]="loginDetails.password" type="password" name="password" autocomplete="off">
      </mat-form-field>
      <div class="forgetpassword"><span (click)="openDialog()">Forgot Password?</span></div>
      <div>
        <button class="loginButton" mat-raised-button (click)="loginApiCall()" color="primary"
          [disabled]="!loginDetails.email || !loginDetails.password">Login</button>
      </div>
    </form>
  </div>
</div>