import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data, Router } from '@angular/router';
import { EventEmitterService } from '../services/event-emitter.service';
import { LeadService } from '../services/lead.service';
import { SnackBarService } from '../services/snack-bar.service';

@Component({
  selector: 'app-second-meetingpopup',
  templateUrl: './second-meetingpopup.component.html',
  styleUrls: ['./second-meetingpopup.component.css'],
})
export class SecondMeetingpopupComponent implements OnInit {
  lead_status: string[] = ['follow_up', 'dead_lead'];
  editedForm: FormGroup;
  editFlag = false;
  makeCustomerClick: boolean = true;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: Data,
    private leadService: LeadService,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder,
    private eventEmitterService: EventEmitterService,
    private route: Router
  ) {
    this.editedForm = formBuilder.group({
      leadStatus: new FormControl(),
      customerStatus: new FormControl(),
      leadSummay: new FormControl(),
    });
  }

  ngOnInit(): void {}

  onClickSave(id) {
    const obj = {
      leads_status:
        this.editedForm.value.leadStatus === null
          ? this.data.custoMmerData.leadData.data.leads_status
          : this.editedForm.value.leadStatus,
      customer_status:
        this.editedForm.value.customerStatus === null
          ? this.data.custoMmerData.leadData.data.customer_status
          : this.editedForm.value.customerStatus,
      lead_summary: this.editedForm.value.leadSummay,
      id: id,
    };
    this.leadService.leadUpdate(obj).subscribe((data) => {
      this.snackBarService.openSnackBar(data.message, 'success-class');
      this.eventEmitterService.filter('Edit Clicked');
      if (data.data.leads_status == 'make_customer') {
        this.route.navigate(['create-customer']);
      }
    });
  }

  onCLickDelete(id) {
    this.leadService.deleteLeadById(id).subscribe((data) => {
      this.snackBarService.openSnackBar(data.message, 'success-class');
      this.eventEmitterService.filter('Edit Clicked');
    });
  }

  onClickEditShow() {
    this.editFlag = true;
  }

  makeCustomerRadio() {
    this.makeCustomerClick = false;
  }

  leadStatusShow() {
    this.makeCustomerClick = true;
  }
}
