import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { FirstmeetingpopupComponent } from '../firstmeetingpopup/firstmeetingpopup.component';
import { LeadService } from '../services/lead.service';
import { EventEmitterService } from '../services/event-emitter.service';
import { CustomerAddeventModelComponent } from '../customer-addevent-model/customer-addevent-model.component';
import { ConfirmationModalComponent } from '../shared/confirmation-modal/confirmation-modal.component';
import { SnackBarService } from '../services/snack-bar.service';
import { FormGroup, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'app-firstmeeting',
  templateUrl: './firstmeeting.component.html',
  styleUrls: ['./firstmeeting.component.css'],
})
export class FirstmeetingComponent implements OnInit {
  dateRange = new FormGroup({
    start_date: new FormControl(),
    end_date: new FormControl()
  });
  dataSource: any = null;
  filterdata = null;
  displayedColumns: string[] = [
    'name',
    'email',
    'phone',
    'signup_date',
    'leads_status',
    'lead_summary',
    'schedule_a_meeting',
    'Action',
  ];
  isLoading = false;
  isShow = true;
  paginationData: any;
  pageEvent: PageEvent;
  result;
  searchkey = new FormControl();

  constructor(
    private leadService: LeadService,
    public dialog: MatDialog,
    private eventEmitterService: EventEmitterService,
    private changeDetectorRefs: ChangeDetectorRef,
    private snackBarService: SnackBarService,
    private datePipe: DatePipe
  ) {
    this.eventEmitterService.listen().subscribe((item) => {
      this.initDataSource();
    });
  }
  ngOnInit(): void {
    this.initDataSource();
    this.applyFilter();
  }

  initDataSource(): void {
    this.isLoading = true;
    const leadStatusName = {
      meetingStatusname: 'first_meting',
      start_date: this.datePipe.transform(this.dateRange.get('start_date').value, 'yyy-MM-dd'),
      end_date: this.datePipe.transform(this.dateRange.get('end_date').value, 'yyy-MM-dd')
    };
    this.leadService
      .getLeadlistByLeadstatus(leadStatusName)
      .subscribe((data: any) => {
        this.paginationData = data.data;
        this.dataSource = data.data.data;
        this.isLoading = false;
        this.changeDetectorRefs.detectChanges();
      });
  }

  applyFilter(): void {
    this.searchkey.valueChanges.pipe(debounceTime(300)).subscribe((response) => {
      if (response) {
        this.isLoading = true;
        const object = {
          filterValue: response,
          leadStatus: 'first_meting'
        };
        this.leadService.firstmeetingFilter(object).subscribe((result) => {
          this.dataSource = result.data.data;
          this.isLoading = false;
        });
      }
      if (response === '') { this.initDataSource(); }
    });

  }

  onPaginateChange(event: PageEvent): void {
    const paginationData = {
      page: event.pageIndex + 1,
      pageLimit: event.pageSize,
      meetingStaus: 'first_meting',
    };
    this.isLoading = true;
    this.leadService.paginationCall(paginationData).subscribe((data) => {
      this.paginationData = data.data;
      this.dataSource = data.data.data;
      this.isLoading = false;
    });
  }

  editLeadStatus(id): void {
    this.leadService.getLeadByID(id).subscribe((data) => {
      const editLeadData = {
        ishowFlag: this.isShow = true,
        leadData: data,
      };
      this.openDialog(editLeadData);
    });
  }

  deleteLead(id): void {
    this.leadService.getLeadByID(id).subscribe((data) => {
      this.dialog.open(ConfirmationModalComponent, {
        data: {
          title: 'Confirm',
          message: 'Are you Sure You want to delete this Lead'
        },
      }).afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.leadService.deleteLeadById(id).subscribe((response): void => {
            this.snackBarService.openSnackBar(response.message, 'success-class');
            if (response) {
              this.initDataSource();
            }
          });
        }
      });
    });
  }

  openDialog(data): void {
    const dialogRef = this.dialog.open(FirstmeetingpopupComponent, {
      width: '60%',
      height: '70%',
      data: {
        custoMmerData: data,
      },
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  openCreateEventDialog(data): void {
    const dialogRef = this.dialog.open(CustomerAddeventModelComponent, {
      width: '34%',
      // height: '68%',
      data: {
        custoMmerData: data,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  clearField(controller): void {
    this.dateRange.reset();
    this.initDataSource();
  }

  searchClear(): void {
    this.searchkey.setValue('');
  }


}
