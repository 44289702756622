<mat-progress-bar *ngIf="isloading" mode="indeterminate"></mat-progress-bar>
<div class="container" fxLayout fxLayoutAlign="center center" fxLayoutGap="25px" fxLayout.xs="column wrap"
  fxLayoutGap.xs="20px">
  <mat-card fxFlex="60" class="cardPadding">
    <mat-card-content>
      <h2 class="createLeadTitle">CREATE LEADS</h2>
      <form [formGroup]="createLeadForm" fxLayoutAlign="stretch" fxLayout="column">
        <mat-form-field class="full-width">
          <mat-label>Name</mat-label>
          <input style="font-size: 14px;" required formControlName="customerName" type="text" matInput
            autocomplete="off">
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Phone</mat-label>
          <span matPrefix>+1&nbsp;</span>
          <input type="tel" required formControlName="phoneNumber" matInput placeholder="555-555-1234">
          <mat-icon matSuffix>phone</mat-icon>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Email</mat-label>
          <input formControlName="emailAddress" matInput autocomplete="off">
        </mat-form-field>
        <h4 class="fieldLabel">Leads Status *</h4>
        <div fxLayout.xs="column wrap" fxLayoutGap="40px" fxLayoutGap.xs="20px"
          style="margin-top: -13px;margin-left: -6px;">
          <mat-radio-group required color="primary" formControlName="leadStatus"
            aria-labelledby="example-radio-group-label" class="example-radio-group">
            <mat-radio-button class="example-radio-button" *ngFor="let season of seasons" [value]="season">
              <span>{{season == 'first_meting' ? 'First Meeting' : ''}}</span>
              <span>{{season == 'dead_lead' ? 'Dead Lead' : ''}}</span>
              <span>{{season == 'make_customer' ? 'Make Customer' : ''}}</span>
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <h4 class="fieldLabel" style="margin-top: 10px;">Meeting Type *</h4>
        <div fxLayout.xs="column wrap" fxLayoutGap="40px" fxLayoutGap.xs="20px" style="margin-top: -13px;">
          <mat-radio-group color="primary" fxLayoutGap="10px" aria-label="Select an option"
            formControlName="meetingType">
            <mat-radio-button value="skype">Skype</mat-radio-button>
            <mat-radio-button value="phone">Phone</mat-radio-button>
            <mat-radio-button value="zoom">Zoom</mat-radio-button>
            <mat-radio-button value="mail">Email</mat-radio-button>
          </mat-radio-group>
        </div>
        <h4 class="fieldLabel" style="margin-top: 10px;">Reference</h4>
        <div class="example-button-row" fxLayout.xs="column wrap" fxLayoutGap="20px">
          <div fxFlex>
            <mat-button-toggle-group color="primary" formControlName="refrence" [value]="refrence"
              #group="matButtonToggleGroup" appearance="legacy" name="fontStyle" aria-label="Font Style">

              <mat-button-toggle type="radio" value="google">Google +</mat-button-toggle>
              <mat-button-toggle type="radio" value="instagram">Instagram x</mat-button-toggle>
              <mat-button-toggle type="radio" value="website">Website +</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div fxFlex>
            <mat-form-field class="full-width">
              <mat-label>Existing Customer</mat-label>
              <mat-select formControlName="reference_customer_id">
                <mat-option *ngFor="let customer of existingCustomerName" [value]="customer.customer_id">
                  {{customer.surname}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex>
            <mat-form-field class="full-width">
              <mat-label>Reference</mat-label>
              <input style="font-size: 14px;" formControlName="reference_text" type="text" matInput autocomplete="off">
            </mat-form-field>
          </div>
        </div>

        <!-- Schedule a Meeting -->
        <h4 class="fieldLabel" style="margin-top: 10px;">Schedule a Meeting</h4>
        <div class="example-button-row" fxLayout.xs="column wrap" fxLayoutGap="20px">
          <div fxFlex>
            <mat-form-field class="full-width">
              <mat-label>Subject</mat-label>
              <input matInput formControlName="createEventSummary" autocomplete="off">
            </mat-form-field>
          </div>
          <div fxFlex>
            <mat-form-field class="full-width">
              <mat-label>Message</mat-label>
              <input matInput formControlName="description" autocomplete="off">
            </mat-form-field>
          </div>
        </div>

        <div class="example-button-row" fxLayout.xs="column wrap" fxLayoutGap="20px">
          <div fxFlex>
            <mat-form-field class="full-width">
              <input matInput [ngxMatDatetimePicker]="picker1" (focus)="picker1.open()" formControlName="start"
                placeholder="Start Date & Time">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #picker1 [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
                [color]="color" [enableMeridian]="enableMeridian" [disableMinute]="disableMinute" [hideTime]="hideTime">
              </ngx-mat-datetime-picker>
            </mat-form-field>
          </div>
          <div fxFlex>
            <mat-form-field class="full-width">
              <input matInput [ngxMatDatetimePicker]="picker" (focus)="picker.open()" formControlName="end"
                placeholder="End Date & Time">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
                [color]="color" [enableMeridian]="enableMeridian" [disableMinute]="disableMinute" [hideTime]="hideTime">
              </ngx-mat-datetime-picker>
            </mat-form-field>
          </div>
        </div>

        <!-- <div fxLayout.xs="column wrap" fxLayout  class="calanderField">
          <button fxFlex="30" style="color: white;" (click)="openDialog()" color="primary" mat-flat-button> Schedule a Meeting</button>
        </div> -->
        <h4 class="fieldLabel inputField-margin">Summary</h4>
        <mat-form-field appearance="outline" class="example-full-width">
          <textarea formControlName="summary" [matAutosizeMinRows]="7" matTextareaAutosize [matAutosizeMaxRows]="7"
            matInput>
          </textarea>
        </mat-form-field>
        <div><button mat-flat-button (click)="onClickSubmit()" style="color: white;" color="primary"
            [disabled]="!createLeadForm.valid">Submit</button></div>
      </form>
    </mat-card-content>
  </mat-card>
</div>