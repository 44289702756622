import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MortageService } from './../services/mortage.service';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ConfirmationModalComponent } from '../shared/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from '../services/snack-bar.service';

@Component({
  selector: 'app-mortage-closed-list',
  templateUrl: './mortage-closed-list.component.html',
  styleUrls: ['./mortage-closed-list.component.css']
})
export class MortageClosedListComponent implements OnInit {

  closedMortageForm: FormGroup;
  isLoading: boolean;
  pageEvent: PageEvent;
  dataSource: any = [];
  totalCount: number;
  displayedColumns: string[] = [
    'name',
    'PropertyAddress',
    'Agent',
    'MTG Amount',
    'Mine',
    'Amount',
    'Vale',
    'Notes',
    'closedData',
    'Action',
  ];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    private formBuilder: FormBuilder,
    private mortageService: MortageService,
    private router: Router,
    private dialog: MatDialog,
    private snackBarService: SnackBarService
  ) { }

  ngOnInit(): void {
    this.closedMortageFormBuild();
    this.getClosedMortage();
    this.filters();
  }

  closedMortageFormBuild(): void {
    this.closedMortageForm = this.formBuilder.group({
      page: 1,
      pageLimit: 10,
      keyword: '',
      status: '',
      start_date: '',
      end_date: ''
    });
  }

  getClosedMortage(): void {
    this.isLoading = true;
    this.mortageService.getClosedMortgageList(this.closedMortageForm.value).subscribe((response) => {
      this.dataSource = response.data.data;
      this.totalCount = response.data.total;
      this.isLoading = false;
    });
  }

  filters(): void {
    this.closedMortageForm.valueChanges.pipe(debounceTime(300)).subscribe((response) => {
      this.getClosedMortage();
    });
  }

  paginationHandler(event: PageEvent): void {
    this.closedMortageForm.get('page').setValue(event.pageIndex + 1);
    this.closedMortageForm.get('pageLimit').setValue(event.pageSize);
  }

  pageIndexHandler(): void {
    this.paginator.pageIndex = 0;
    this.closedMortageForm.get('page').setValue(1);
  }

  goToCreate(): void {
    this.router.navigate(['mortage']);
  }

  clearField(controller: string): void {
    this.closedMortageForm.get(controller).setValue('');
  }

  deleteMortage(id): void {
    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          title: 'Confirm',
          message: 'Are you Sure You want to delete this Mortage',
        },
      }).afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.mortageService.deleteMortage(id).subscribe((response) => {
            this.snackBarService.openSnackBar(
              response.message,
              'error-class'
            );
            this.closedMortageFormBuild();
            this.closedMortageForm.get('page').setValue(1);
            this.closedMortageForm.get('description').setValue('');
            this.getClosedMortage();
          });
        }
      });
  }

}
