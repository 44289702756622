import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data, Router } from '@angular/router';
import * as moment from 'moment';
import { DashboardService } from '../services/dashboard.service';
import { SnackBarService } from '../services/snack-bar.service';

@Component({
  selector: 'app-customer-addevent-model',
  templateUrl: './customer-addevent-model.component.html',
  styleUrls: ['./customer-addevent-model.component.css']
})
export class CustomerAddeventModelComponent implements OnInit {
  createEventForm: FormGroup;

  public showSeconds = true;
  public disableMinute = false;
  public hideTime = false;
  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public touchUi = true;
  public enableMeridian = true;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: Data,
    private fb: FormBuilder,
    private eventService: DashboardService,
    private snackBarService : SnackBarService,
    private route: Router) { }

  ngOnInit(): void {
    this.createEventForm = this.fb.group({
      summary: new FormControl(),
      description: new FormControl(),
      start: new FormControl(),
      end: new FormControl()
    });
  }

  createEvent() {
    const eventObject = {
      summary: this.createEventForm.value.summary,
      start: this.createEventForm.value.start ? new Date(moment(this.createEventForm.value.start).format('YYYY-MM-DD[T]HH:mm:ss')):"",
      end: this.createEventForm.value.end ? new Date(moment(this.createEventForm.value.end).format('YYYY-MM-DD[T]HH:mm:ss')):"",
      description: this.createEventForm.value.description
    }
    this.eventService.createCalanderEvent(eventObject).subscribe((response)=> {
      if(response) {
        this.snackBarService.openSnackBar(response.message, 'success-class');
        if(response.status && !this.data.createLead){
          this.route.navigate(['event-list']);
        }
      }
    });
  }

}
