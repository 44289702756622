import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from '../../services/login.service';
import { NavbarService } from '../../services/navbar.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { RegistrationComponent } from '../registration/registration.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @Input() loginDetails = {
    email: '',
    password: '',
  };

  constructor(
    public dialog: MatDialog,
    private ps: LoginService,
    public nav: NavbarService,
    private route: Router,
    private snackBarService: SnackBarService
  ) { }

  ngOnInit(): void {
    this.nav.hide();
  }

  loginApiCall(): void {
    if (this.loginDetails) {
      this.ps.loginCall(this.loginDetails).subscribe((res) => {
        sessionStorage.setItem('token', res.data.token);
        if (res.status === true) { this.route.navigate(['/dashboard']); }
        if (res.status === true) {
          this.snackBarService.openSnackBar((`${'Logged in as'}`) + ' ' + res.data.user.name + '!', 'success-class');
        };
        if (res.message) { this.snackBarService.openSnackBar(res.message, 'error-class') };
      }, (error => {
        return this.snackBarService.openSnackBar(error.error.error.email[0], 'error-class');
      }));
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(RegistrationComponent, {
      width: '30%'
    });
    dialogRef.afterClosed().subscribe(result => { });
  }

}
