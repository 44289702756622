<div style="height: 5px; width: 100%;">
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
</div>
<form [formGroup]="realEstateClosedForm">
    <div class="container">
        <div fxLayout="row wrap" fxLayoutGap="10px">
            <h2 fxFlex class="pageTitle">Real Estate Closed File ({{paginationCountData?.total}})</h2>
            <mat-form-field fxFlex>
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate formControlName="start_date" (focus)="picker.open()"
                        (dateChange)="pageIndexHandler()" placeholder="Start date">
                    <input matEndDate formControlName="end_date" (focus)="picker.open()" placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <button matSuffix mat-icon-button aria-label="clear"
                    (click)="clearField('start_date');clearField('end_date');$event.stopPropagation();">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field fxFlex>
                <mat-icon matSuffix>search</mat-icon>
                <mat-label>Search</mat-label>
                <input matInput formControlName="description" (keyup)="pageIndexHandler()" placeholder="Ex. ium" #input>
                <button matSuffix mat-icon-button aria-label="clear"
                    (click)="clearField('description');$event.stopPropagation();">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="mat-elevation-z8">
            <table mat-table id="realestate-table" [dataSource]="dataSource">

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                    <mat-cell *matCellDef="let row" [matTooltip]="row.name && row.name.length > 20 ?row.name: '' ">
                        <span class="truncate-text">{{row.name |titlecase}}</span>
                    </mat-cell>

                </ng-container>

                <ng-container matColumnDef="phone">
                    <mat-header-cell *matHeaderCellDef> Phone </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.phone |titlecase}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ReferralSource">
                    <mat-header-cell *matHeaderCellDef> Referral Source </mat-header-cell>
                    <mat-cell *matCellDef="let row"
                        [matTooltip]="row.referral_source && row.referral_source.length > 10 ? row.area:'' ">
                        <span class="truncate-text">{{row.referral_source |titlecase}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="area">
                    <mat-header-cell *matHeaderCellDef> Area </mat-header-cell>
                    <mat-cell *matCellDef="let row" [matTooltip]="row.area && row.area.length > 25 ? row.area:'' ">
                        <span class="truncate-text">{{row.area | titlecase}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="notes">
                    <mat-header-cell *matHeaderCellDef> Notes </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <mat-icon class="matNoteIcon" matTooltip="{{row.notes}}" matTooltipClass="tooltip"
                            matTooltipPosition="below" aria-hidden="false" aria-label="Example home icon">insert_comment
                        </mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="MyCommission">
                    <mat-header-cell *matHeaderCellDef> My Commission </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.my_commission}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="closingDate">
                    <mat-header-cell *matHeaderCellDef> Closing Date </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.closing_date | date}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Status">
                    <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <span *ngIf="row.status === 'in_progress' ">In progress</span>
                        <span *ngIf="row.status === 'pending' ">Pending</span>
                        <span *ngIf="row.status === 'completed' ">Completed</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="update">
                    <mat-header-cell *matHeaderCellDef> Update </mat-header-cell>
                    <mat-cell *matCellDef="let row"
                        [matTooltip]="row.update && row.update.length > 20 ? row.update: '' ">
                        <span class="truncate-text">{{row.update |titlecase}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Action">
                    <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <button mat-fab color="primary" class="editIconButton" (click)="delete(row.id)">
                            <mat-icon class="matEditIcon" aria-hidden="false" aria-label="Example home icon">delete
                            </mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row class="headerCustom" *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="9">No data matching the filter "{{realEstateForm.value.keyword}}"</td>
                </tr>

            </table>
            <mat-paginator [length]="paginationCountData?.total" [pageSize]="paginationCountData?.data.per_page"
                [pageSizeOptions]="[10,25,100]" (page)="pageEvent = $event; paginationHandler($event)"
                showFirstLastButtons>
            </mat-paginator>
        </div>
    </div>
</form>